import { Badge, Button, Menu } from '@mantine/core';
import { IconDotsVertical, IconEye } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { Ticket } from '../../../models/ticket';
import { formatLocale } from '../../../providers/dayjs-plugins';
import {
  ticketStatusColor,
  ticketStatusHumanized,
} from '../../../utils/constants';
import { PageMeta } from '../../../utils/types';

interface FinancialSacListProps {
  items: Ticket[];
  handleChangePageLimit(limit: number): void;
  handlePaginate(page: number): void;
  currentPage: React.MutableRefObject<number>;
  meta: PageMeta | null;
  handleSelectTicket: (ticket: Ticket) => void;
}

export function FinancialSacList({
  currentPage,
  handleChangePageLimit,
  handlePaginate,
  items,
  meta,
  handleSelectTicket,
}: FinancialSacListProps) {
  return (
    <DataTable
      minHeight={200}
      noRecordsText="Sem tickets"
      withBorder
      borderRadius="sm"
      striped
      highlightOnHover
      height={390}
      page={currentPage.current}
      totalRecords={meta?.totalItems}
      onPageChange={handlePaginate}
      recordsPerPageLabel="Itens por página"
      recordsPerPage={meta?.itemsPerPage ?? 10}
      recordsPerPageOptions={[10, 50, 100, 200]}
      onRecordsPerPageChange={(recordRange: number) =>
        handleChangePageLimit(recordRange)
      }
      records={items}
      columns={[
        {
          accessor: 'code',
          title: 'Código',
        },
        {
          accessor: 'orderOs',
          title: 'OS',
        },
        {
          accessor: 'companyName',
          title: 'Cliente',
        },
        {
          accessor: 'status',
          title: 'Status',
          render: ({ status }) => (
            <Badge color={ticketStatusColor[status]} mb={6}>
              {ticketStatusHumanized[status]}
            </Badge>
          ),
        },
        {
          accessor: 'needClean',
          title: 'Limpeza',
          render: ({ needClean }) =>
            needClean ? (
              <Badge color="green" mb={6}>
                Sim
              </Badge>
            ) : (
              <Badge color="red" mb={6}>
                Não
              </Badge>
            ),
        },
        {
          accessor: 'needBuy',
          title: 'Compra',
          render: ({ needBuy }) =>
            needBuy ? (
              <Badge color="green" mb={6}>
                Sim
              </Badge>
            ) : (
              <Badge color="red" mb={6}>
                Não
              </Badge>
            ),
        },

        {
          accessor: 'assignedUser',
          title: 'Responsável',
        },
        {
          accessor: 'createdBy',
          title: 'Criado por',
        },
        {
          accessor: 'createdAt',
          title: 'Criado em',
          render: ({ createdAt }) =>
            createdAt && formatLocale(createdAt, 'DD/MM/YY'),
          width: 110,
        },
        {
          accessor: 'finishedAt',
          title: 'Finalizado em',
          render: ({ finishedAt }) =>
            finishedAt && formatLocale(finishedAt, 'DD/MM/YY'),
          width: 110,
        },
        {
          accessor: 'obs',
          title: 'Descrição',
          width: 300,
          ellipsis: true,
        },
        {
          accessor: 'menu',
          title: '',
          render: (ticket) => (
            <Menu>
              <Menu.Target>
                <Button color="blue" variant="subtle" w={40} p={0}>
                  <IconDotsVertical />
                </Button>
              </Menu.Target>
              <Menu.Dropdown style={{ position: 'absolute' }}>
                <Menu.Item
                  onClick={() => handleSelectTicket(ticket)}
                  icon={<IconEye size={14} />}
                >
                  Ver
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          ),
        },
      ]}
    />
  );
}
