import {
  Alert,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  NumberInput,
  Paper,
  Radio,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconAlertCircle,
  IconCircleCheck,
  IconCirclePlus,
  IconSearch,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { Page } from '../../components/Page';
import { TicketDetails } from '../../components/TicketDetails';
import { useCloneOrder } from '../../data/hooks/orders';
import { useCreateTicket } from '../../data/hooks/tickets';
import { getFilterCompaniesListRequest } from '../../data/services/filters';
import { Order, OrderStatus } from '../../models/order';
import { Service } from '../../models/service';
import { Ticket, TicketContactType, TicketStatus } from '../../models/ticket';
import { UserRole, UserType } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { getOrdersPaginatedDispatcher } from '../../store/orders';
import {
  finishTicketDispatcher,
  getTicketsPaginatedDispatcher,
  updateTicketDispatcher,
  updateTicketStatusDispatcher,
} from '../../store/ticket';
import {
  orderStatusColor,
  orderStatusHumanized,
  statusTicket,
  statusTicketList,
  ticketContactTypeList,
} from '../../utils/constants';
import { clearMask, formatPhone } from '../../utils/formater';
import { GetFilterCompaniesListResponse } from '../../utils/types/data/services/filters';
import { ModalOrderDetails } from '../OrderList/sub-components/modal-order-details';
import { AdminSacList } from './admin';
import { CompanySacList } from './company';
import { FinancialSacList } from './financial';
import { VendorSacList } from './vendor';

export type FormFilterType = {
  page: number;
  limit: number;
  os?: string | null;
  createdBy?: string | null;
  createdAt?: string | null;
  finishedAt?: string | null;
  status?: string | null;
  assignedUser?: string | null;
  code?: string | null;
  company?: string | null;
};

export type TicketListPageModalVisible =
  | 'create-ticket'
  | 'change-status'
  | 'finish-ticket'
  | 'change-ticket'
  | 'show-ticket'
  | 'clone-order'
  | null;

export function SacList() {
  const { user } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch<any>();
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const currentPageOrder = useRef(1);
  const [pageModalVisible, setPageModalVisible] =
    useState<TicketListPageModalVisible>(null);
  const { items, loading, meta } = useSelector(
    (state: RootState) => state.ticket,
  );
  const {
    items: orders,
    loading: loadingOrders,
    meta: metaOrders,
  } = useSelector((state: RootState) => state.orders);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedTicket, setSelectedTicket] = useState<Ticket>();
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const { fetch: createTicketFetcher, loading: createTicketLoad } =
    useCreateTicket();
  const isCompany = [UserRole.COMPANY].includes(user?.role as UserRole);

  const { fetch: cloneOrderFecher, loading: cloneOrderLoader } =
    useCloneOrder();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      os: '',
      createdBy: '',
      createdAt: undefined,
      finishedAt: undefined,
      status: null,
      code: '',
      assignedUser: '',
      company: '',
    },
  });

  const newTicket = useForm({
    initialValues: {
      assignedUser: '',
      process: 'none',
      cleanProcess: '',
      buyDescription: '',
      buyQuantity: 0,
      obs: '',
      contactName: '',
      contactType: '',
      contact: '',
    },
  });

  const orderFilterForm = useForm({
    initialValues: {
      os: '',
      company: '',
    },
  });

  const formStatusChange = useForm({
    initialValues: {
      status: '',
    },
  });

  const formChangeTicket = useForm({
    initialValues: {
      assignedUser: '',
      obs: '',
      contact: '',
      contactType: '',
      contactName: '',
    },
  });

  const formCloneOrder = useForm({
    initialValues: {
      quantity: '',
    },
  });

  function handleSubmit() {
    if (selectedOrder) {
      if (newTicket.values.process === 'none' && !isCompany) {
        errorNotification({
          title: 'Selecione um processo.',
          message: 'campo de processo obrigatório',
        });

        return;
      }
      createTicketFetcher({
        data: {
          ...newTicket.values,
          needBuy: newTicket.values.process === 'buy',
          needClean: newTicket.values.process === 'clean',
          buyDescription:
            newTicket.values.process === 'clean'
              ? ''
              : newTicket.values.buyDescription,
          buyQuantity:
            newTicket.values.process === 'clean'
              ? 0
              : newTicket.values.buyQuantity,
          cleanProcess:
            newTicket.values.process === 'buy'
              ? ''
              : newTicket.values.cleanProcess,
          contact: [
            TicketContactType.PHONE,
            TicketContactType.WHATSAPP,
          ].includes(newTicket.values.contactType as TicketContactType)
            ? clearMask(newTicket.values.contact)
            : newTicket.values.contact,
        },
        orderId: selectedOrder.id,
        onSuccess: () => {
          successNotification({
            title: 'Novo ticket criado!',
            message: 'tudo certo.',
          });
          newTicket.reset();
          setSelectedOrder(undefined);
          currentPage.current = 1;
          getTicketsPaginated();
          setPageModalVisible(null);
        },
      });
    }
  }

  function handleListTickets() {
    currentPage.current = 1;
    getTicketsPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getTicketsPaginated();
  }

  function handlePaginateOrdersList(page: number) {
    currentPageOrder.current = page;
    getOrdersPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getTicketsPaginated();
  }

  function handleClearFilter() {
    formFilter.reset();
    getTicketsPaginated(true);
  }

  function getTicketsPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getTicketsPaginatedDispatcher({
          page: 1,
          limit: 10,
        }),
      );
    } else {
      dispatch(
        getTicketsPaginatedDispatcher({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
        }),
      );
    }
  }

  function handleFilterOrders() {
    getOrdersPaginated();
    setSelectedOrder(undefined);
  }

  async function getOrdersPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(orderFilterForm.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      dispatch(
        getOrdersPaginatedDispatcher({
          page: 1,
          limit: 10,
        }),
      );
    } else {
      dispatch(
        getOrdersPaginatedDispatcher({
          ...validatedFilter,
          page: currentPageOrder.current,
          limit: 10,
        }),
      );
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  function handleSelectTicket(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('show-ticket');
  }

  function handleChangeTicket(ticket: Ticket) {
    setSelectedTicket(ticket);
    formChangeTicket.setValues({
      assignedUser: ticket.assignedUser,
      obs: ticket.obs,
      contact: [TicketContactType.PHONE, TicketContactType.WHATSAPP].includes(
        ticket.contactType as TicketContactType,
      )
        ? formatPhone(ticket.contact ?? '')
        : ticket.contact,
      contactType: ticket.contactType,
      contactName: ticket.contactName,
    });
    setPageModalVisible('change-ticket');
  }

  function handleSubmitChangeStatus(values: typeof formStatusChange.values) {
    if (!selectedTicket) return;

    if (
      values.status &&
      statusTicket[values.status as TicketStatus] >
        statusTicket[selectedTicket.status]
    ) {
      dispatch(
        updateTicketStatusDispatcher(selectedTicket.id, {
          status: values.status as TicketStatus,
        }),
      );
      formStatusChange.reset();
      setPageModalVisible(null);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'você nao pode alterar para um status anterior ao atual',
      });
    }
  }

  function handleChangeTicketStatus(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('change-status');
  }

  function handleFinishTicket(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('finish-ticket');
  }

  async function finishTicket() {
    if (!selectedTicket) return;

    if (!selectedTicket.finishedAt) {
      dispatch(finishTicketDispatcher(selectedTicket.id));
      setPageModalVisible(null);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'este ticket já está finalizado!',
      });
    }
  }

  function abortFinishTicket() {
    setPageModalVisible(null);
    setSelectedTicket(undefined);
  }

  async function handleEditTicket(values: typeof formChangeTicket.values) {
    if (!selectedTicket) return;

    if (!selectedTicket.finishedAt) {
      dispatch(
        updateTicketDispatcher(selectedTicket.id, {
          ...values,
          contact: [
            TicketContactType.PHONE,
            TicketContactType.WHATSAPP,
          ].includes(formChangeTicket.values.contactType as TicketContactType)
            ? clearMask(formChangeTicket.values.contact)
            : formChangeTicket.values.contact,
        }),
      );
      setPageModalVisible(null);
    } else {
      errorNotification({
        title: 'Ops!',
        message: 'este ticket já está finalizado!',
      });
    }
  }

  async function handleCloneOrder(
    orderId: number,
    values: typeof formCloneOrder.values,
  ) {
    await cloneOrderFecher({
      id: orderId,
      data: { quantity: Number(values.quantity) },
      onSuccess: () => {
        formCloneOrder.reset();
        setPageModalVisible(null);
        successNotification({
          title: 'Repetição de pedido criado.',
          message: 'tudo certo',
        });
      },
    });
  }

  function selectOrderToClone(ticket: Ticket) {
    setSelectedTicket(ticket);
    setPageModalVisible('clone-order');
  }

  useEffect(() => {
    getTicketsPaginated();
    getCompanies();
  }, []);

  return (
    <Page title="SAC [Andamento]">
      <CustomLoader loading={loading || createTicketLoad} />
      <Flex direction="column" w="99%">
        {user?.role !== UserRole.FINANCIAL && (
          <Flex align="center" justify="end" mb={8}>
            <Button
              color="ltpBlue.9"
              leftIcon={<IconCirclePlus />}
              onClick={() => setPageModalVisible('create-ticket')}
            >
              Novo Ticket (SAC)
            </Button>
          </Flex>
        )}

        <form onSubmit={formFilter.onSubmit(() => handleListTickets())}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TextInput
                label="Nº Ticket"
                placeholder="número do Ticket"
                mb={16}
                mr={8}
                type="text"
                name="code_ticket"
                {...formFilter.getInputProps('code')}
              />
              <TextInput
                label="OS"
                placeholder="numero da OS"
                mb={16}
                mr={8}
                type="text"
                name="os"
                {...formFilter.getInputProps('os')}
              />
              <TextInput
                label="Responsável"
                placeholder="digite o nome do responsável"
                mb={16}
                mr={8}
                type="text"
                name="assignedUser"
                {...formFilter.getInputProps('assignedUser')}
              />
              <TextInput
                label="Criado por"
                placeholder="digite o nome"
                mb={16}
                mr={8}
                type="text"
                name="createdBy"
                {...formFilter.getInputProps('createdBy')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Criado em"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('createdAt')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Finalizado em"
                placeholder="selecione um intervalo"
                mr={8}
                {...formFilter.getInputProps('finishedAt')}
              />
              <Select
                name="status"
                label="Status"
                placeholder="selecione o status do pedido"
                data={statusTicketList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('status')}
              />
              {!isCompany && (
                <Select
                  clearable
                  searchable
                  name="company"
                  label="Cliente"
                  placeholder="selecione o cliente"
                  data={companyList.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  {...formFilter.getInputProps('company')}
                />
              )}
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClearFilter}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
            </Group>
          </Paper>
        </form>

        {user?.type === UserType.MASTER && (
          <AdminSacList
            items={items}
            currentPage={currentPage}
            handlePaginate={handlePaginate}
            handleChangePageLimit={handleChangePageLimit}
            meta={meta}
            handleSelectTicket={handleSelectTicket}
            handleChangeTicket={handleChangeTicket}
            handleChangeTicketStatus={handleChangeTicketStatus}
            handleFinishTicket={handleFinishTicket}
            selectOrderToClone={selectOrderToClone}
          />
        )}

        {user?.role === UserRole.VENDOR && (
          <VendorSacList
            items={items}
            currentPage={currentPage}
            handlePaginate={handlePaginate}
            handleChangePageLimit={handleChangePageLimit}
            meta={meta}
            handleSelectTicket={handleSelectTicket}
            handleChangeTicket={handleChangeTicket}
            handleChangeTicketStatus={handleChangeTicketStatus}
            handleFinishTicket={handleFinishTicket}
            selectOrderToClone={selectOrderToClone}
          />
        )}

        {user?.role === UserRole.COMPANY && (
          <CompanySacList
            items={items}
            currentPage={currentPage}
            handlePaginate={handlePaginate}
            handleChangePageLimit={handleChangePageLimit}
            meta={meta}
            handleSelectTicket={handleSelectTicket}
          />
        )}

        {user?.role === UserRole.FINANCIAL && (
          <FinancialSacList
            items={items}
            currentPage={currentPage}
            handlePaginate={handlePaginate}
            handleChangePageLimit={handleChangePageLimit}
            meta={meta}
            handleSelectTicket={handleSelectTicket}
          />
        )}
      </Flex>
      <Modal
        size={750}
        opened={pageModalVisible === 'create-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Criar Ticket (SAC)</Text>}
      >
        <form onSubmit={orderFilterForm.onSubmit(handleFilterOrders)}>
          <Grid gutter="xs" columns={5} mb={8}>
            <Grid.Col span={2}>
              <TextInput
                label="OS"
                placeholder="digite a OS"
                type="text"
                name="os"
                {...orderFilterForm.getInputProps('os')}
              />
            </Grid.Col>
            {!isCompany && (
              <Grid.Col span={2}>
                <Select
                  clearable
                  searchable
                  name="company"
                  label="Cliente"
                  placeholder="selecione o cliente"
                  data={companyList.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                  {...orderFilterForm.getInputProps('company')}
                />
              </Grid.Col>
            )}
            <Grid.Col span={1}>
              <Button color="blue" mt={24} type="submit">
                <IconSearch size={20} />
              </Button>
            </Grid.Col>
          </Grid>
        </form>
        <DataTable
          mb={16}
          height={320}
          noRecordsText="Sem tickets"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          onRowClick={(order) => setSelectedOrder(order)}
          page={currentPageOrder.current}
          onPageChange={handlePaginateOrdersList}
          totalRecords={metaOrders?.totalItems}
          recordsPerPage={10}
          records={orders}
          fetching={loadingOrders}
          columns={[
            {
              accessor: 'selected',
              title: '',
              render: ({ id }) =>
                selectedOrder?.id === id ? (
                  <IconCircleCheck color="green" size="28" />
                ) : null,
            },
            {
              accessor: 'os',
              title: 'OS',
              width: 110,
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
              width: 90,
            },
            {
              accessor: 'internalNumber',
              title: 'Produto',
              render: ({ product, productColor, productMaterial }) =>
                `${product}, ${productColor}, ${productMaterial}`,
            },
            {
              accessor: 'quantity',
              title: 'Quantidade',
              width: 70,
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY HH:mm'),
              width: 110,
            },
          ]}
        />
        {selectedOrder ? (
          <form onSubmit={newTicket.onSubmit(() => handleSubmit())}>
            <Grid columns={4}>
              {!isCompany && (
                <Grid.Col xl={4}>
                  <TextInput
                    description="quem será o responsável por esse ticket"
                    label="Responsável"
                    placeholder="digite o nome"
                    type="text"
                    name="assignedUser"
                    {...newTicket.getInputProps('assignedUser')}
                  />
                </Grid.Col>
              )}
              <Grid.Col xl={4}>
                <Divider my="sm" variant="dashed" />
              </Grid.Col>
              <Grid.Col xl={2}>
                <TextInput
                  description="Nome cliente"
                  label="Nome contato"
                  placeholder="digite o nome"
                  type="text"
                  name="assignedUser"
                  {...newTicket.getInputProps('contactName')}
                />
              </Grid.Col>
              <Grid.Col xl={2}>
                <Select
                  required
                  withAsterisk
                  name="contactType"
                  description="(ligação, e-mail, WhatsApp)"
                  label="Tipo de contato"
                  placeholder="selecione o tipo de contato"
                  data={ticketContactTypeList}
                  {...newTicket.getInputProps('contactType')}
                />
              </Grid.Col>
              {[TicketContactType.PHONE, TicketContactType.WHATSAPP].includes(
                newTicket.values.contactType as TicketContactType,
              ) ? (
                <Grid.Col xl={2}>
                  <TextInput
                    description="Contato"
                    label="Contato"
                    placeholder="digite o contato"
                    type="text"
                    name="contact"
                    onChange={(e) =>
                      newTicket.setFieldValue(
                        'contact',
                        formatPhone(e.target.value),
                      )
                    }
                    value={newTicket.values.contact}
                  />
                </Grid.Col>
              ) : (
                <Grid.Col xl={2}>
                  <TextInput
                    description="Contato"
                    label="Contato"
                    placeholder="digite o contato"
                    type="text"
                    name="contact"
                    {...newTicket.getInputProps('contact')}
                  />
                </Grid.Col>
              )}
              <Grid.Col xl={4}>
                <Textarea
                  required
                  description="nos conte oque houve com este pedido"
                  label="Descrição"
                  type="text"
                  name="obs"
                  maxLength={255}
                  {...newTicket.getInputProps('obs')}
                />
              </Grid.Col>
              {!isCompany && (
                <>
                  <Grid.Col>
                    <Radio.Group
                      name="process"
                      label="Processo"
                      description="nos diga oque será feito"
                      withAsterisk
                      required
                      {...newTicket.getInputProps('process')}
                    >
                      <Radio
                        value="clean"
                        label="conseguimos limpar as peças?"
                      />
                      <Radio value="buy" label="precisará comprar peças?" />
                    </Radio.Group>
                  </Grid.Col>
                  <Grid.Col>
                    {newTicket.values.process === 'clean' ? (
                      <>
                        <Textarea
                          withAsterisk
                          label="Processo de limpeza"
                          placeholder="Oque foi feito para limpar a peça?"
                          type="text"
                          name="cleanProcess"
                          maxLength={255}
                          required
                          {...newTicket.getInputProps('cleanProcess')}
                        />
                      </>
                    ) : newTicket.values.process === 'buy' ? (
                      <>
                        <TextInput
                          withAsterisk
                          label="Descrição de compra"
                          placeholder="valor da peça e total"
                          type="text"
                          name="buyDescription"
                          maxLength={255}
                          mb={5}
                          required
                          {...newTicket.getInputProps('buyDescription')}
                        />
                        <NumberInput
                          required
                          name="buyQuantity"
                          withAsterisk
                          label="Quantidade da compra"
                          placeholder="digite a quantidade"
                          min={0}
                          {...newTicket.getInputProps('buyQuantity')}
                        />
                      </>
                    ) : (
                      ''
                    )}
                  </Grid.Col>
                </>
              )}
            </Grid>
            <Group position="right" mt="xl">
              <Button color="ltpBlue.9" type="submit">
                Criar
              </Button>
            </Group>
          </form>
        ) : (
          ''
        )}
      </Modal>
      <Modal
        size={400}
        opened={pageModalVisible === 'change-status'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Alterar status</Text>}
      >
        <form
          onSubmit={formStatusChange.onSubmit((values) =>
            handleSubmitChangeStatus(values),
          )}
        >
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <Select
                required
                withAsterisk
                name="status"
                label="Status"
                placeholder="selecione o novo status do ticket"
                data={statusTicketList.slice(0, 3)}
                mb={16}
                {...formStatusChange.getInputProps('status')}
              />
            </Grid.Col>
          </Grid>
          <Flex justify="flex-end">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Flex>
        </form>
      </Modal>
      <Modal
        size={400}
        opened={pageModalVisible === 'finish-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Finalizar Ticket (SAC)</Text>}
      >
        <Text>Deseja finalizar este ticket "{selectedTicket?.code}" ?</Text>
        <Group mt={16}>
          <Button onClick={finishTicket} color="green" type="submit">
            Sim
          </Button>
          <Button onClick={abortFinishTicket} color="red" type="submit">
            Não
          </Button>
        </Group>
      </Modal>
      <Modal
        size={750}
        opened={pageModalVisible === 'change-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Alterar Ticket (SAC)</Text>}
      >
        <form
          onSubmit={formChangeTicket.onSubmit((values) =>
            handleEditTicket(values),
          )}
        >
          <Grid columns={4}>
            <Grid.Col xl={4}>
              <TextInput
                required
                description="quem será o responsável por esse ticket"
                label="Responsável"
                placeholder="digite o nome"
                type="text"
                name="assignedUser"
                defaultValue={selectedTicket?.assignedUser}
                {...formChangeTicket.getInputProps('assignedUser')}
              />
            </Grid.Col>
            <Grid.Col xl={4}>
              <Divider my="sm" variant="dashed" />
            </Grid.Col>
            <Grid.Col xl={2}>
              <TextInput
                description="Nome cliente"
                label="Nome contato"
                placeholder="digite o nome"
                type="text"
                name="contactName"
                defaultValue={selectedTicket?.contactName}
                {...formChangeTicket.getInputProps('contactName')}
              />
            </Grid.Col>
            <Grid.Col xl={2}>
              <Select
                required
                withAsterisk
                name="contactType"
                description="(ligação, e-mail, WhatsApp)"
                label="Tipo de contato"
                placeholder="selecione o tipo de contato"
                data={ticketContactTypeList}
                {...formChangeTicket.getInputProps('contactType')}
              />
            </Grid.Col>
            {[TicketContactType.PHONE, TicketContactType.WHATSAPP].includes(
              newTicket.values.contactType as TicketContactType,
            ) ? (
              <Grid.Col xl={2}>
                <TextInput
                  description="Contato"
                  label="Contato"
                  placeholder="digite o contato"
                  type="text"
                  name="contact"
                  onChange={(e) =>
                    formChangeTicket.setFieldValue(
                      'contact',
                      formatPhone(e.target.value),
                    )
                  }
                  value={formChangeTicket.values.contact}
                />
              </Grid.Col>
            ) : (
              <Grid.Col xl={2}>
                <TextInput
                  description="Contato"
                  label="Contato"
                  placeholder="digite o contato"
                  type="text"
                  name="contact"
                  onChange={(e) =>
                    formChangeTicket.setFieldValue(
                      'contact',
                      formatPhone(e.target.value),
                    )
                  }
                  value={formChangeTicket.values.contact}
                />
              </Grid.Col>
            )}
            <Grid.Col xl={4}>
              <Textarea
                required
                description="nos conte oque houve com este pedido"
                label="Descrição"
                name="obs"
                defaultValue={selectedTicket?.obs}
                {...formChangeTicket.getInputProps('obs')}
              />
            </Grid.Col>
          </Grid>

          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Alterar
            </Button>
          </Group>
        </form>
      </Modal>
      <Modal
        size={700}
        opened={pageModalVisible === 'show-ticket'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Ticket (SAC)</Text>}
      >
        <ScrollArea h={600}>
          {selectedTicket && (
            <TicketDetails
              ticketId={selectedTicket?.id}
              originOrder={selectedTicket.orderOrigin}
              reworkOriginOrder={selectedTicket.orderReworkOrigin}
            />
          )}
        </ScrollArea>
      </Modal>
      <Modal
        size={500}
        opened={pageModalVisible === 'clone-order'}
        onClose={() => setPageModalVisible(null)}
        title={<Text fw="bold">Criar repetição</Text>}
      >
        {selectedTicket?.orderStatus &&
          [OrderStatus.FINISHED, OrderStatus.DISPATCHED].includes(
            selectedTicket.orderStatus,
          ) && (
            <Alert
              icon={<IconAlertCircle size={16} />}
              title="Atenção"
              color="red"
              mb={16}
            >
              Este pedido já está finalizado ou retirado. Certifique-se de que
              deseja criar uma repetição antes de continuar.
            </Alert>
          )}

        <ModalOrderDetails
          order={
            {
              id: selectedTicket?.orderId,
              os: selectedTicket?.orderOs,
              internalNumber: selectedTicket?.orderInternalNumber,
              quantity: selectedTicket?.orderQuantity,
              product: selectedTicket?.orderProduct,
              productColor: selectedTicket?.orderProductColor,
              productMaterial: selectedTicket?.orderProductMaterial,
              service: {
                name: selectedTicket?.orderServiceName ?? '',
              } as Service,
            } as Order
          }
        />
        <form
          onSubmit={formCloneOrder.onSubmit((values) =>
            handleCloneOrder(Number(selectedTicket?.orderId), values),
          )}
        >
          <Group>
            <NumberInput
              width={100}
              label="Nova quantidade"
              placeholder="quantidade do pedido"
              mb={16}
              mt={16}
              min={1}
              {...formCloneOrder.getInputProps('quantity')}
            />
          </Group>
          <Group position="right">
            <Button color="dark" type="submit" loading={cloneOrderLoader}>
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
