export enum OptionTypes {
  ACTIVITY = 'activity',
  COMPANY_DATA = 'company-data',
  HANDLING = 'handling',
  ORDER = 'order',
  ART_PRODUCTION = 'art_production',
  PACKING = 'packing',
  PRODUCTION = 'production',
  USER_DATA = 'user-data',
  BANK_ACCOUNT = 'bank-account',
  ORDER_MATERIAL_VOLUME = 'order-material-volume',
}

export enum OptionSubTypes {
  STEPS = 'steps',
  STEPS_ADESIVO = 'steps_adesivo',
  STEPS_DIGITAL = 'steps_digital',
  STEPS_DECALQUE = 'steps_decalque',
  COMPANY_CATEGORY = 'company_category',
  COMPANY_PAYMENT_TYPE = 'company_payment_type',
  COMPANY_PAYMENT_TERM = 'company_payment_term',
  COMPANY_DOC_TYPE = 'company_doc_type',
  PRODUCTION_MACHINE = 'production_machine',
  ORDER_PAYMENT_TYPE = 'order_payment_type',
  ORDER_PAYMENT_STATUS = 'order_payment_status',
  ORDER_FINANCIAL_STATUS = 'order_financial_status',
  ORDER_TRANSACTION_TYPE = 'order_transaction_type',
  ORDER_DISPATCH_LOCATIONS = 'order_dispatch_locations',
  USER_TYPE = 'user_type',
  TEAM_TYPE = 'team_type',
  STATUS = 'status',
  BANK_ACCOUNT_PIX = 'bank_account_pix',
  BANK_ACCOUNT_DEPOSIT_NUMBER = 'bank_account_deposit_number',
  ORDER_MATERIAL_VOLUME_LOCATION = 'order_material_volume_location',
}

export type Option = {
  id: number;
  name: string;
  value: string;
  type: OptionTypes;
  subtype: OptionSubTypes;
  service: number;
};
