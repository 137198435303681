import { useEffect, useMemo, useRef, useState } from 'react';
import {
  GetFilterCompaniesListResponse,
  GetFilterUsersListResponse,
} from '../../../utils/types/data/services/filters';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../providers/store';
import { useListOptions } from '../../../data/hooks/options';
import { useListProductions } from '../../../data/hooks/order-production';
import { useForm } from '@mantine/form';
import {
  getFilterCompaniesListRequest,
  getFilterUsersListRequest,
} from '../../../data/services/filters';
import { UserRole } from '../../../models/user';
import { errorNotification } from '../../../providers/mantine-notifications';
import { OptionSubTypes, OptionTypes } from '../../../models/option';
import { getServicesDispatcher } from '../../../store/services';
import {
  Button,
  Flex,
  Group,
  Paper,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import { DataTable } from 'mantine-datatable';
import {
  formatBRL,
  formatDateTime,
  secondsToTime,
} from '../../../utils/helpers';

export function ProductionReportList() {
  const [loading, setLoading] = useState(false);

  const [technicalUsers, setTechnicalUsers] = useState<
    GetFilterUsersListResponse[]
  >([]);

  const dispatch = useDispatch<any>();
  const { items: serviceItems } = useSelector(
    (state: RootState) => state.services,
  );
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);

  const {
    fetch: listMachineOptionsFetcher,
    reponseData: listMachineOptionsData,
    loading: listMachineOptionsLoader,
  } = useListOptions();
  const {
    fetch: listProductionsFetcher,
    loading: listProductionsLoad,
    response: listProductionsResponse,
  } = useListProductions();

  const formFilter = useForm({
    initialValues: {
      startDate: new Date(),
      endDate: new Date(),
      machine: '',
      company: '',
      technical: '',
      service: '',
      supervisor: '',
      os: '',
      page: 1,
      limit: 10,
    },
  });

  function handleClear() {
    formFilter.reset();
    currentPage.current = 1;
    getPaginatedProductions(true);
    currentPageLimit.current = 10;
  }

  const services = useMemo(() => {
    const items = serviceItems.map((item) => ({
      label: item.name,
      value: String(item.id),
      disabled: item.alias === 'envelopamento',
    }));

    return items;
  }, [serviceItems]);

  async function handleSubmit() {
    getPaginatedProductions();
  }

  async function getPaginatedProductions(isReset = false) {
    let validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      validatedFilter = {};
      validatedFilter.startDate = new Date();
      validatedFilter.endDate = new Date();
    }

    await listProductionsFetcher({
      query: {
        ...validatedFilter,
        page: currentPage.current,
        limit: currentPageLimit.current,
      },
    });
  }

  async function getTechnicalUsers() {
    try {
      setLoading(true);
      const responseUsers = await getFilterUsersListRequest({
        role: UserRole.TECHNICAL,
      });
      setTechnicalUsers(responseUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscas usuários',
        message: 'tente novamente',
      });
    }
  }

  async function getMachineOptions() {
    await listMachineOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.PRODUCTION_MACHINE,
      },
    });
  }

  function handleSelectService(id: string) {
    formFilter.setValues({ service: id });
  }

  function handleChangePage(pageNumber: number) {
    currentPage.current = pageNumber;
    getPaginatedProductions();
  }

  function handleChangePageLimit(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getPaginatedProductions();
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  useEffect(() => {
    getCompanies();
    getTechnicalUsers();
    dispatch(getServicesDispatcher());
    getMachineOptions();
    getPaginatedProductions();
  }, []);
  return (
    <>
      <form onSubmit={formFilter.onSubmit(handleSubmit)}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex>
            <TextInput
              label="OS"
              placeholder="digite a OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <Select
              clearable
              name="company"
              searchable
              label="Cliente"
              placeholder="selecione um cliente"
              mb={16}
              mr={8}
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              {...formFilter.getInputProps('company')}
            />
            <Select
              clearable
              name="service"
              searchable
              label="Serviço"
              placeholder="selecione um serviço"
              data={services}
              mb={16}
              mr={8}
              onChange={handleSelectService}
            />
            <Select
              clearable
              searchable
              name="machine"
              label="Máquina"
              placeholder="selecione a máquina"
              data={
                listMachineOptionsData?.map((option) => ({
                  label: option.name,
                  value: option.value,
                })) ?? []
              }
              mb={16}
              mr={8}
              {...formFilter.getInputProps('machine')}
            />
            <Select
              clearable
              searchable
              name="supervisor"
              label="Técnico"
              placeholder="selecione o técnico"
              data={technicalUsers.map((item) => ({
                label: item.name,
                value: item.name,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('supervisor')}
            />
            <CustomDatePicker
              required
              withAsterisk
              placeholder="selecione uma data"
              label="Data inicial"
              lang="br"
              name="startDate"
              mb={16}
              mr={8}
              {...formFilter.getInputProps('startDate')}
            />
            <CustomDatePicker
              required
              withAsterisk
              placeholder="selecione uma data"
              label="Data final"
              lang="br"
              name="endDate"
              mb={16}
              mr={8}
              {...formFilter.getInputProps('endDate')}
            />
          </Flex>
          <Button
            mt={25}
            color="ltpBlue.9"
            type="button"
            variant="outline"
            onClick={handleClear}
          >
            Limpar
          </Button>
          <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
            Filtrar
          </Button>
        </Paper>
      </form>
      <DataTable
        fetching={listProductionsLoad || listMachineOptionsLoader || loading}
        minHeight={500}
        noRecordsText="Sem registros"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        records={listProductionsResponse?.items}
        page={currentPage.current}
        totalRecords={listProductionsResponse?.meta?.totalItems}
        recordsPerPage={currentPageLimit.current}
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 200]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        onPageChange={(page) => handleChangePage(page)}
        columns={[
          {
            accessor: 'company',
            title: 'Cliente',
          },
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'service',
            title: 'Serviço',
          },
          {
            accessor: 'supervisor',
            title: 'Técnico',
          },
          {
            accessor: 'technical',
            title: 'Operador',
          },
          {
            accessor: 'machine',
            title: 'Máquina',
          },
          {
            accessor: 'quantity',
            title: 'Quant. Pedido',
          },
          {
            accessor: 'amount',
            title: 'Quant. Prod.',
          },
          {
            accessor: 'clockIn',
            title: 'Iniciado em',
            render: ({ clockIn }) => formatDateTime(clockIn),
          },
          {
            accessor: 'clockOut',
            title: 'Finalizado em',
            render: ({ clockOut }) => formatDateTime(clockOut),
          },
          {
            accessor: 'workTime',
            title: 'Tempo',
            render: ({ timeAmount }) => {
              const { hours, minutes, seconds } = secondsToTime(timeAmount);

              return `${hours}h ${minutes}m ${seconds}s`;
            },
          },
          {
            accessor: 'price',
            title: 'Preço Total',
            render: ({ price, sample_price }) =>
              formatBRL(Number(price) > 0 ? price : sample_price),
          },
          {
            accessor: 'unitPrice',
            title: 'Preço Unitário',
            render: ({ price, sample_price, quantity }) =>
              formatBRL(
                Number(
                  Number(Number(price) > 0 ? price : sample_price) / quantity,
                ).toFixed(2),
              ),
          },
          {
            accessor: 'prodProd',
            title: 'Preço Prod',
            render: ({ prodPrice }) => {
              return formatBRL(prodPrice);
            },
          },
        ]}
      />
      <Group position="right">
        <Text>
          Total produzido:{' '}
          {listProductionsResponse?.meta?.custom?.totalProduced}
        </Text>
      </Group>
    </>
  );
}
