/* eslint-disable unused-imports/no-unused-vars */
import { useForm } from '@mantine/form';

import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Select,
  TextInput,
  Title,
} from '@mantine/core';
import { IconArrowLeft } from '@tabler/icons';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { useCreateOption } from '../../data/hooks/options';
import { CustomLoader } from '../../components/CustomLoader';
import { successNotification } from '../../providers/mantine-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../providers/store';
import { useEffect } from 'react';
import { getServicesDispatcher } from '../../store/services';

export type CreateOptionFormType = {
  name: string;
  value: string;
  type: OptionTypes;
  subtype: OptionSubTypes;
  service: string;
};

export function CreateOption() {
  const { fetch: createOptionFetcher, loading: createOptionLoad } =
    useCreateOption();
  const dispatch = useDispatch<any>();

  const { items: serviceItems, loading: serviceLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const form = useForm<CreateOptionFormType>({
    initialValues: {
      name: '',
      value: '',
      type: OptionTypes.PRODUCTION,
      subtype: OptionSubTypes.PRODUCTION_MACHINE,
      service: '',
    },
  });

  async function handleSubmit() {
    createOptionFetcher({
      data: {
        ...form.values,
        service: Number(form.values.service),
      },
      onSuccess: () => {
        rootNavigateGoBack();
        successNotification({
          title: 'Tudo certo!',
          message: 'máquina criada com sucesso ✅',
        });
      },
    });
  }
  useEffect(() => {
    dispatch(getServicesDispatcher());
  }, []);

  return (
    <Page>
      <CustomLoader loading={createOptionLoad || serviceLoading} />

      <Container w={400}>
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Nova Máquina
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit(() => handleSubmit())}>
          <TextInput
            withAsterisk
            required
            label="Nome"
            placeholder="nome da máquina"
            mb={16}
            type="text"
            name="name"
            maxLength={255}
            {...form.getInputProps('name')}
          />
          <TextInput
            withAsterisk
            required
            label="Descrição"
            placeholder="descrição da máquina"
            mb={16}
            type="text"
            name="value"
            maxLength={255}
            {...form.getInputProps('value')}
          />
          <Select
            data={serviceItems?.map((item) => ({
              label: item.name,
              value: String(item.id),
            }))}
            label="Serviço"
            placeholder="selecione os serviço"
            {...form.getInputProps('service')}
          />

          <Group position="right" mt="xl">
            <Button color="ltpBlue.9" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
