import { TeamConfig } from './team-config';

export enum TeamType {
  VENDOR = 'vendor',
  TECHNICAL = 'technical',
  ART = 'art',
  PACK = 'pack',
  RECEPTION = 'reception',
  FINANCIAL = 'financial',
  HANDLING = 'handling',
  SAC = 'sac',
}

export type Team = {
  id: number;
  name: string;
  email: string;
  type: TeamType;
  configs: TeamConfig[];
};

export type TeamServicePercent = {
  team: number;
  service: string;
  percentage: string;
  serviceId: number;
};

export type AddTeamServicePercent = {
  serviceName?: string;
  service: number;
  percentage: string;
};

export type AddTeamOptionPercent = {
  optionName?: string;
  option: number;
  percentage: string;
};

export type TeamOptionPercent = {
  team: number;
  option: string;
  percentage: string;
  optionId: number;
};
