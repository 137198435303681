import {
  Menu,
  Button,
  Flex,
  Grid,
  Modal,
  Text,
  Textarea,
  Switch,
  Group,
  Badge,
  HoverCard,
  TextInput,
  Select,
  Checkbox,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import {
  IconCertificate2,
  IconCheck,
  IconCirclePlus,
  IconDotsVertical,
  IconEdit,
  IconX,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';

import { CustomLoader } from '../../components/CustomLoader';
import { CustomMultiSelect } from '../../components/CustomMultiSelect';
import { Page } from '../../components/Page';
import PageFilter from '../../components/PageFilter';
import {
  useGetCompanyTagList,
  useGetViewCompanyDocTag,
  useSetCompanyProtested,
} from '../../data/hooks/companies';
import { useGetSegmentationTagsFilter } from '../../data/hooks/filters';
import { getFilterTeamsListRequest } from '../../data/services/filters';
import { TeamType } from '../../models/team';
import { UserRole, UserType } from '../../models/user';
import { formatLocale } from '../../providers/dayjs-plugins';
import { RootState } from '../../providers/store';
import { AppRoutePaths } from '../../utils/enums';
import { clearMask, formatCNPJ, formatPhone } from '../../utils/formater';
import { GetViewCompanyDocTagListResponse } from '../../utils/types/data/services/companies';
import { GetFilterTeamsListResponse } from '../../utils/types/data/services/filters';

type FormFilterType = {
  team: string;
  createdAt: undefined;
  razaoSocial: string;
  nomeFantasia: string;
  document: string;
  isProtested: boolean;
  whatsapp: string;
  tags: never[];
  companyName: string;
};

const formFilterInitialState = {
  team: '',
  createdAt: undefined,
  razaoSocial: '',
  nomeFantasia: '',
  document: '',
  isProtested: false,
  whatsapp: '',
  companyName: '',
  tags: [],
};

export function CompanyList() {
  const navigation = useNavigate();
  const { user } = useSelector((state: RootState) => state.auth);
  const [pageModalVisible, setPageModalVisible] = useState(false);
  const [pageFilterVisible, setPageFilterVisible] = useState(false);
  const [selectedCompany, setSelectedCompany] =
    useState<GetViewCompanyDocTagListResponse>();
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const {
    fetch: setCompanyProtestedFetcher,
    loading: setCompanyProtestedLoading,
  } = useSetCompanyProtested();
  const {
    fetch: getViewCompanyDocTagFetcher,
    response: getViewCompanyDocTagData,
    loading: getViewCompanyDocTagLoader,
  } = useGetViewCompanyDocTag();
  const {
    fetch: getTagsFetcher,
    response: getTagsData,
    loading: getTagsLoader,
  } = useGetSegmentationTagsFilter();

  const { fetch: getCompanyTagListFetcher, response: getCompanyTagListData } =
    useGetCompanyTagList();

  const formFilter = useForm<FormFilterType>({
    initialValues: formFilterInitialState,
  });

  const setProtestedCompanyForm = useForm({
    initialValues: {
      protested: false,
      description: '',
    },
  });

  function handleClearFilter() {
    currentPage.current = 1;
    formFilter.reset();
    getCompaniesPaginated(true);
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getCompaniesPaginated();
  }

  function setCompanyToProtest(company: GetViewCompanyDocTagListResponse) {
    setProtestedCompanyForm.setFieldValue('protested', company.isProtested);
    setProtestedCompanyForm.setFieldValue(
      'description',
      company.protestDescription ?? '',
    );
    setSelectedCompany(company);
    setPageModalVisible(true);
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getCompaniesPaginated();
  }

  async function getVendorTeams() {
    const response = await getFilterTeamsListRequest({ type: TeamType.VENDOR });
    setTeamList(response.data);
  }

  async function handleSubmit() {
    currentPage.current = 1;
    getCompaniesPaginated();
  }

  async function getCompaniesPaginated(isReset = false) {
    const validatedValues: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedValues[key] = val;
      }
    });

    if (isReset) {
      getViewCompanyDocTagFetcher({
        query: {
          page: 1,
          limit: 10,
        },
      });
    } else {
      getViewCompanyDocTagFetcher({
        query: {
          page: currentPage.current,
          limit: currentPageLimit.current,
          ...validatedValues,
          createdAt: validatedValues?.createdAt
            ? validatedValues.createdAt.join(',')
            : undefined,
          document: validatedValues?.document
            ? clearMask(validatedValues.document)
            : undefined,
          whatsapp: validatedValues?.whatsapp
            ? clearMask(validatedValues.whatsapp)
            : undefined,
          tags: validatedValues?.tags.length
            ? validatedValues.tags.join(',')
            : undefined,
        },
      });
    }
  }

  async function handleProtestCompany(id: number) {
    await setCompanyProtestedFetcher({
      id,
      data: {
        protested: setProtestedCompanyForm.values.protested,
        description: setProtestedCompanyForm.values.description,
      },
      onSuccess: () => {
        setSelectedCompany(undefined);
        setProtestedCompanyForm.reset();
        setPageModalVisible(false);
        handleSubmit();
      },
    });
  }

  async function getTags() {
    await getTagsFetcher();
  }

  async function getCompanyTags(companyId: number) {
    await getCompanyTagListFetcher({
      companyId,
    });
  }

  useEffect(() => {
    getCompaniesPaginated();
    getVendorTeams();
    getTags();
  }, []);

  return (
    <Page title="Empresas">
      <CustomLoader loading={getTagsLoader || getViewCompanyDocTagLoader} />
      <Flex direction="column" w="99%">
        <Flex align="center" justify="end" mb={8}>
          <PageFilter.FilterButton
            isCollapsed={pageFilterVisible}
            mr={8}
            onClick={() => setPageFilterVisible(!pageFilterVisible)}
          />
          <Button
            color="ltpBlue.9"
            leftIcon={<IconCirclePlus />}
            onClick={() => navigation(AppRoutePaths.COMPANY_CREATE)}
          >
            Nova
          </Button>
        </Flex>
        <PageFilter.Filter<FormFilterType>
          formFilter={formFilter}
          handleClear={handleClearFilter}
          handleSubmit={handleSubmit}
          isCollapsed={pageFilterVisible}
        >
          <Flex wrap="wrap">
            <TextInput
              label="Nome"
              placeholder="digite..."
              mb={16}
              mr={8}
              type="text"
              name="companyName"
              {...formFilter.getInputProps('companyName')}
            />
            <TextInput
              label="Nome fantasia"
              placeholder="digite..."
              mb={16}
              mr={8}
              type="text"
              {...formFilter.getInputProps('nomeFantasia')}
            />
            <TextInput
              label="Razão social"
              placeholder="digite..."
              mb={16}
              mr={8}
              type="text"
              name="razaoSocial"
              {...formFilter.getInputProps('razaoSocial')}
            />
            <TextInput
              label="CNPJ"
              placeholder="digite..."
              mb={16}
              mr={8}
              type="text"
              name="document"
              {...formFilter.getInputProps('document')}
              onChange={(event) =>
                formFilter.setFieldValue(
                  'document',
                  formatCNPJ(event.target.value),
                )
              }
            />
            <TextInput
              label="Whatsapp"
              placeholder="digite..."
              mb={16}
              mr={8}
              type="text"
              {...formFilter.getInputProps('whatsapp')}
              onChange={(e) =>
                formFilter.setFieldValue(
                  'whatsapp',
                  formatPhone(e.target.value),
                )
              }
            />
            {user?.role !== UserRole.VENDOR && (
              <Select
                clearable
                searchable
                name="team"
                label="Equipe"
                placeholder="selecione uma equipe"
                data={teamList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('team')}
              />
            )}
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Criado em"
              placeholder="selecione um intervalo"
              mr={8}
              {...formFilter.getInputProps('createdAt')}
            />
            <CustomMultiSelect
              data={
                getTagsData?.map((tagItem) => ({
                  value: String(tagItem.id),
                  label: tagItem.name,
                  color: tagItem.color,
                  bg: tagItem.background,
                })) ?? []
              }
              w={240}
              label="Tags"
              placeholder="selecione as tags"
              clearable
              searchable
              {...formFilter.getInputProps('tags')}
            />
          </Flex>
          <Flex>
            <Checkbox
              mt={30}
              label="Mostrar protestados"
              checked={formFilter.values.isProtested}
              {...formFilter.getInputProps('isProtested')}
            />
          </Flex>
        </PageFilter.Filter>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 300]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          recordsPerPage={getViewCompanyDocTagData?.meta?.itemsPerPage ?? 10}
          totalRecords={getViewCompanyDocTagData?.meta?.totalItems}
          page={currentPage.current}
          onPageChange={handlePaginate}
          fetching={setCompanyProtestedLoading}
          height="75vh"
          noRecordsText="Sem empresas"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          records={getViewCompanyDocTagData?.items}
          columns={[
            {
              accessor: 'companyName',
              title: 'Nome',
            },
            {
              accessor: 'nomeFantasia',
              title: 'Nome fantasia',
            },
            {
              accessor: 'razaoSocial',
              title: 'Razão social',
            },
            {
              accessor: 'document',
              title: 'CNPJ',
              width: 150,
              render: ({ document }) => formatCNPJ(document),
            },
            {
              accessor: 'whatsapp',
              title: 'Whatsapp',
              width: 120,
              render: ({ whatsapp }) => formatPhone(whatsapp),
            },
            {
              accessor: 'teamName',
              title: 'Equipe',
            },
            {
              accessor: 'email',
              title: 'Email',
            },
            {
              accessor: 'tagName',
              title: 'Tag',
              render: ({ tagName, tagBackground, tagColor, id }) =>
                tagName && (
                  <HoverCard
                    openDelay={1000}
                    shadow="md"
                    withArrow
                    onOpen={() => getCompanyTags(id)}
                  >
                    <HoverCard.Target>
                      <Badge
                        bg={tagBackground}
                        maw={150}
                        style={{
                          color: tagColor,
                          cursor: 'pointer',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          display: 'inline-block',
                        }}
                      >
                        {tagName}
                      </Badge>
                    </HoverCard.Target>
                    <HoverCard.Dropdown>
                      <Flex direction="column">
                        {getCompanyTagListData?.map((tagItem) => (
                          <Badge
                            mb={4}
                            bg={tagItem.background}
                            maw={200}
                            style={{
                              color: tagItem.color,
                              cursor: 'pointer',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                              display: 'inline-block',
                            }}
                          >
                            {tagItem.name}
                          </Badge>
                        ))}
                      </Flex>
                    </HoverCard.Dropdown>
                  </HoverCard>
                ),
            },
            {
              accessor: 'createdAt',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
            },
            {
              accessor: 'createdBy',
              title: 'Criado por',
            },
            {
              accessor: 'company-table-menu',
              title: '',
              render: (companyDoc) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => navigation(`edit/${companyDoc.id}`)}
                      icon={<IconEdit size={14} />}
                      disabled={
                        user?.team !== companyDoc.teamId &&
                        user?.type !== UserType.MASTER &&
                        user?.role !== UserRole.FINANCIAL
                      }
                    >
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => setCompanyToProtest(companyDoc as any)}
                      icon={<IconCertificate2 size={14} />}
                    >
                      Protestar
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        size={550}
        opened={pageModalVisible}
        onClose={() => setPageModalVisible(false)}
        title="Protestar empresa"
      >
        <Grid gutter="xs" columns={4} mb={8}>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Nome Fantasia
              </Text>
              <Text>{selectedCompany?.nomeFantasia}</Text>
            </Flex>
          </Grid.Col>
          <Grid.Col span={2}>
            <Flex direction="column">
              <Text fw="bold" color="gray" size={12}>
                Equipe
              </Text>
              <Text>{selectedCompany?.teamName}</Text>
            </Flex>
          </Grid.Col>
        </Grid>
        <form
          onSubmit={setProtestedCompanyForm.onSubmit(() =>
            handleProtestCompany(Number(selectedCompany?.id)),
          )}
        >
          <Grid gutter="xs" columns={4} mb={8}>
            <Grid.Col span={2}>
              <Switch
                description="ative esta opção para protestar a empresa"
                checked={setProtestedCompanyForm.values.protested}
                color="teal"
                size="sm"
                label="protestado"
                thumbIcon={
                  setProtestedCompanyForm.values.protested ? (
                    <IconCheck size="0.8rem" color="teal" stroke={3} />
                  ) : (
                    <IconX size="0.8rem" color="red" stroke={3} />
                  )
                }
                {...setProtestedCompanyForm.getInputProps('protested')}
              />
            </Grid.Col>
            <Grid.Col span={4}>
              <Textarea
                maxLength={255}
                withAsterisk
                required
                label="Motivo"
                placeholder="escreva aqui qualquer observação sobre..."
                name="description"
                {...setProtestedCompanyForm.getInputProps('description')}
              />
            </Grid.Col>
          </Grid>
          <Group position="right">
            <Button color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Modal>
    </Page>
  );
}
