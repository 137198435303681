import { Button, Flex, Paper, Select, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef, useState } from 'react';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import { useGetOrderPackingsReport } from '../../../data/hooks/order-packings';
import { getFilterUsersListRequest } from '../../../data/services/filters';
import { UserRole } from '../../../models/user';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  orderPackingTypeHumanized,
  typePackingList,
} from '../../../utils/constants';
import { formatDateTime } from '../../../utils/helpers';
import { GetFilterUsersListResponse } from '../../../utils/types/data/services/filters';

export function PackingReportList() {
  const [loading, setLoading] = useState(false);

  const [packerUsers, setPackerUsers] = useState<GetFilterUsersListResponse[]>(
    [],
  );
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);

  const {
    fetch: listPackingReportFetcher,
    loading: listPackingReportLoader,
    reponseData: listPackingReportResponse,
  } = useGetOrderPackingsReport();

  const formPackingReport = useForm({
    initialValues: {
      date: undefined,
      os: '',
      page: 1,
      limit: 10,
      type: '',
    },
  });

  async function getPaginatedPackingReport(isReset = false) {
    let validatedFilter: any = {};

    Object.entries(formPackingReport.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    if (isReset) {
      validatedFilter = {};
      validatedFilter.startDate = new Date();
      validatedFilter.endDate = new Date();
    }

    await listPackingReportFetcher({
      query: {
        ...validatedFilter,
        page: currentPage.current,
        limit: currentPageLimit.current,
      },
    });
  }

  async function handleSubmitPackingReport() {
    getPaginatedPackingReport();
  }

  async function getPackerUsers() {
    try {
      setLoading(true);
      const responseUsers = await getFilterUsersListRequest({
        role: UserRole.PACKER,
      });
      setPackerUsers(responseUsers);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorNotification({
        title: 'Erro ao buscas usuários da embalagem',
        message: 'tente novamente',
      });
    }
  }

  function handleChangePagePackingReport(pageNumber: number) {
    currentPage.current = pageNumber;
    getPaginatedPackingReport();
  }

  function handleChangePageLimitPackingReport(pageLimitNumber: number) {
    currentPageLimit.current = pageLimitNumber;
    getPaginatedPackingReport();
  }

  function handleClearPackingReport() {
    formPackingReport.reset();
    currentPage.current = 1;
    getPaginatedPackingReport(true);
    currentPageLimit.current = 10;
  }

  useEffect(() => {
    getPaginatedPackingReport();
    getPackerUsers();
  }, []);

  return (
    <>
      <form onSubmit={formPackingReport.onSubmit(handleSubmitPackingReport)}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex>
            <TextInput
              label="OS"
              placeholder="digite a OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formPackingReport.getInputProps('os')}
            />

            <Select
              clearable
              searchable
              name="type"
              label="Tipo"
              placeholder="selecione o tipo"
              data={
                typePackingList?.map((type) => ({
                  label: type.label,
                  value: type.value,
                })) ?? []
              }
              mb={16}
              mr={8}
              {...formPackingReport.getInputProps('type')}
            />

            <Select
              clearable
              searchable
              name="packer"
              label="Embalador"
              placeholder="selecione o usuário"
              data={
                packerUsers?.map((user) => ({
                  label: user.name,
                  value: user.id,
                })) ?? []
              }
              mb={16}
              mr={8}
              {...formPackingReport.getInputProps('packer')}
            />

            <CustomDateRangePicker
              allowSingleDateInRange
              label="Iniciado em"
              placeholder="selecione um intervalo"
              mr={8}
              {...formPackingReport.getInputProps('date')}
            />
          </Flex>
          <Button
            mt={25}
            color="ltpBlue.9"
            type="button"
            variant="outline"
            onClick={handleClearPackingReport}
          >
            Limpar
          </Button>
          <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
            Filtrar
          </Button>
        </Paper>
      </form>
      <DataTable
        fetching={loading || listPackingReportLoader}
        minHeight={500}
        noRecordsText="Sem registros"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        records={listPackingReportResponse?.items}
        page={currentPage.current}
        totalRecords={listPackingReportResponse?.meta?.totalItems}
        recordsPerPage={currentPageLimit.current}
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 200]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimitPackingReport(recordRange)
        }
        onPageChange={(page) => handleChangePagePackingReport(page)}
        columns={[
          {
            accessor: 'os',
            title: 'OS',
          },
          {
            accessor: 'packerName',
            title: 'Embalador',
          },
          {
            accessor: 'type',
            title: 'Tipo',
            render: ({ type }) => orderPackingTypeHumanized[type],
          },
          {
            accessor: 'amount',
            title: 'Quantidade',
          },
          {
            accessor: 'defectAmount',
            title: 'Quant. Defeito.',
          },
          {
            accessor: 'timeAmount',
            title: 'Tempo',
          },
          {
            accessor: 'clockIn',
            title: 'Iniciado em',
            render: ({ clockIn }) => formatDateTime(clockIn),
          },
          {
            accessor: 'clockOut',
            title: 'Finalizado em',
            render: ({ clockOut }) => formatDateTime(clockOut ?? ''),
          },
          {
            accessor: 'description',
            title: 'Descrição',
          },
        ]}
      />
    </>
  );
}
