export enum CompanyBalanceAppoval {
  APPROVED = 'approved',
  REPROVED = 'reproved',
  PENDING = 'pending',
}

export enum CompanyBalanceType {
  CREDIT = 'credit',
  DEBIT = 'debit',
  REFUND = 'refund',
}

export type CompanyBalance = {
  id: number;
  value: string;
  description: string;
  team: string;
  createdBy: string;
  approval: CompanyBalanceAppoval;
  approvalBy: string;
  type: CompanyBalanceType;
  createdAt: string;
  company: string;
  companyId: number;
  os: string;
  orderId: number;
};

export type CompanyBalanceCredit = {
  creditAmount: string;
};
