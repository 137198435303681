import { useFetch } from '../../../hooks/use-fetch';
import { Order, OrderToSelect } from '../../../models/order';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseChangeOrderPricesProps,
  UseChangeOrderStatusProps,
  UseCloneOrderProps,
  UseGetByCloneOrderProps,
  UseGetOrderPcpProps,
  UseGetOrderProps,
  UseGetOrdersGoalMetricsProps,
  UseGetOrdersToDispatchProps,
  UseGetOrdersToSelectProps,
  UseReleaseToProductionProps,
  UseRequestSampleProps,
  UseSetOrderFinancialNoteProps,
  UseSetOrderProductionFinishedProps,
  UseSetOrdersToDispatchProps,
} from '../../../utils/types/data/hooks/orders';
import {
  GetByCloneOrderResponse,
  GetByReworkOrderResponse,
  GetOrderPcpResponse,
  GetOrdersToDispatchResponse,
  OrderMetricGoals,
} from '../../../utils/types/data/services/orders';
import {
  changeOrderPricesRequest,
  changeOrderStatusRequest,
  cloneOrderRequest,
  getByCloneOrderRequest,
  getByReworkOrderRequest,
  getOrderPCPRequest,
  getOrdersGoalMetrics,
  getOrdersToDispatchRequest,
  getOrdersToSelect,
  releaseProductionRequest,
  requestSampleRequest,
  setOrderFinancialNote,
  setOrderProductionFinishedRequest,
  setOrdersToDispatchRequest,
} from '../../services/orders';

export function useSetOrderFinancialNote() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseSetOrderFinancialNoteProps) =>
    fetchData({
      fetcher: () => setOrderFinancialNote(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao finalizar etapa.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useChangeOrderPrices() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseChangeOrderPricesProps) =>
    fetchData({
      fetcher: () => changeOrderPricesRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: translateServerHttpErrors(error, 'Erro ao alterar preço.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useChangeOrderStatus() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseChangeOrderStatusProps) =>
    fetchData({
      fetcher: () => changeOrderStatusRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao alterar status.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}

export function useGetOrderPcp() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetOrderPcpResponse>();

  const fetch = ({ query, onSuccess }: UseGetOrderPcpProps) =>
    fetchData({
      fetcher: () => getOrderPCPRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar dados do PCP.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrdersToDispatch() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetOrdersToDispatchResponse[]>();

  const fetch = ({ hash, onSuccess }: UseGetOrdersToDispatchProps) =>
    fetchData({
      fetcher: () => getOrdersToDispatchRequest(hash),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar pedidos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetOrdersToDispatch() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, onSuccess }: UseSetOrdersToDispatchProps) =>
    fetchData({
      fetcher: () => setOrdersToDispatchRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao setar retirada de pedidos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useSetOrderProductionFinished() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Order>();

  const fetch = ({ orderId, onSuccess }: UseSetOrderProductionFinishedProps) =>
    fetchData({
      fetcher: () => setOrderProductionFinishedRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao finalizar produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrdersGoalMetrics() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<OrderMetricGoals>();

  const fetch = ({ query, onSuccess }: UseGetOrdersGoalMetricsProps) =>
    fetchData({
      fetcher: () => getOrdersGoalMetrics(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar métricas de metas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useReleaseToProduction() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseReleaseToProductionProps) =>
    fetchData({
      fetcher: () => releaseProductionRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao liberar pedido para produção.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRequestSample() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseRequestSampleProps) =>
    fetchData({
      fetcher: () => requestSampleRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar amostra de pedido.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCloneOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseCloneOrderProps) =>
    fetchData({
      fetcher: () => cloneOrderRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar repetição de pedido.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetByCloneOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetByCloneOrderResponse>();

  const fetch = ({ id, onSuccess }: UseGetByCloneOrderProps) =>
    fetchData({
      fetcher: () => getByCloneOrderRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar dados do pedido.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetByReworkOrder() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<GetByReworkOrderResponse>();

  const fetch = ({ id, onSuccess }: UseGetOrderProps) =>
    fetchData({
      fetcher: () => getByReworkOrderRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar dados do pedido.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetOrdersToSelect() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<PaginatedResponse<OrderToSelect>>();

  const fetch = ({ query }: UseGetOrdersToSelectProps) =>
    fetchData({
      fetcher: () => getOrdersToSelect(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar pedidos.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
