/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Radio,
  Select,
  Stepper,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft } from '@tabler/icons';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomLoader } from '../../components/CustomLoader';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';

import { DataTable } from 'mantine-datatable';
import { AddTeamConfigPercentageItem } from '../../components/ConfigServicePercentage';
import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import {
  useAddTeamOptionPercent,
  useAddTeamServicePercent,
  useCreateTeam,
} from '../../data/hooks/teams';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import {
  AddTeamOptionPercent,
  AddTeamServicePercent,
  TeamType,
} from '../../models/team';
import { TeamConfigAlias, TeamConfigType } from '../../models/team-config';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { getServicesDispatcher } from '../../store/services';
import { teamTypeList } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';

type FormType = {
  name: string;
  email: string;
  type: string;
  services: number[];
  hasMultipleServices?: boolean;
  multipleServiceLastStep?: number[];
};

export type FormAddPercentage = {
  item: string;
  percentage: string;
};

export function TeamCreate() {
  const [teamServices, setTeamServices] = useState<AddTeamServicePercent[]>([]);
  const [teamOptions, setTeamOptions] = useState<AddTeamOptionPercent[]>([]);

  const dispatch = useDispatch<any>();
  const { items: serviceItems, loading: serviceLoading } = useSelector(
    (state: RootState) => state.services,
  );
  const { fetch: createTeamFetcher, loading: createTeamLoader } =
    useCreateTeam();
  const {
    fetch: addTeamServicePercentFetcher,
    loading: addTeamServicePercentLoading,
  } = useAddTeamServicePercent();
  const {
    fetch: addTeamOptionPercentFetcher,
    loading: addTeamOptionPercentLoading,
  } = useAddTeamOptionPercent();
  const {
    fetch: listMachineOptionsFetcher,
    reponseData: listMachineOptionsData,
    loading: listMachineOptionsLoader,
  } = useListOptions();

  const form = useForm<FormType>({
    initialValues: {
      name: '',
      email: '',
      type: '',
      services: [],
      hasMultipleServices: false,
      multipleServiceLastStep: [],
    },
  });

  const formAddPercentage = useForm<FormAddPercentage>({
    initialValues: {
      item: '',
      percentage: '',
    },
  });

  const formSelectPercentType = useForm({
    initialValues: {
      percentType: '',
    },
  });

  async function handleSubmit(values: typeof form.values) {
    const configs = [];

    if (values?.services?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_FILTER,
        type: TeamConfigType.LIST,
        value: values.services.join(','),
      });
    }

    if (values.hasMultipleServices) {
      configs.push({
        alias: TeamConfigAlias.HAS_MULTIPLE_SERVICES,
        type: TeamConfigType.BOOLEAN,
        value: String(values.hasMultipleServices),
      });
    }

    if (values.hasMultipleServices && values.multipleServiceLastStep?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
        type: TeamConfigType.LIST,
        value: values.multipleServiceLastStep.join(','),
      });
    }

    await createTeamFetcher({
      data: {
        email: values.email,
        type: values.type as TeamType,
        name: values.name,
        configs,
      },
      onSuccess: async (team) => {
        await handleAddPercentages(team.id);
        successNotification({
          title: 'Nova equipe criado!',
          message: 'tudo certo.',
        });
        form.reset();
        rootNavigate(AppRoutePaths.TEAMS);
      },
    });
  }

  function handleAddService(values: typeof formAddPercentage.values) {
    if (!values.item || !values.percentage) {
      errorNotification({
        title: 'Serviço ou Porcentagem inválidos',
        message: 'verifique os campos',
      });
      return;
    }
    setTeamServices([
      ...teamServices,
      {
        service: Number(values.item.split(',')[1]),
        serviceName: values.item.split(',')[0],
        percentage: values.percentage,
      },
    ]);
    formAddPercentage.reset();
  }

  function handleAddOption(values: typeof formAddPercentage.values) {
    if (!values.item || !values.percentage) {
      errorNotification({
        title: 'Máquina ou Porcentagem inválidos',
        message: 'verifique os campos',
      });
      return;
    }
    setTeamOptions([
      ...teamOptions,
      {
        option: Number(values.item.split(',')[1]),
        optionName: values.item.split(',')[0],
        percentage: values.percentage,
      },
    ]);
    formAddPercentage.reset();
  }

  async function getMachineOptions() {
    await listMachineOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.PRODUCTION_MACHINE,
      },
    });
  }

  async function handleAddPercentages(teamId: number) {
    if (teamServices.length) {
      await addTeamServicePercentFetcher({
        id: Number(teamId),
        data: teamServices,
        onSuccess: async () => {
          formAddPercentage.reset();
          successNotification({
            title: 'Serviço adicionado!',
            message: 'tudo certo.',
          });
        },
      });
    }
    if (teamOptions.length) {
      await addTeamOptionPercentFetcher({
        id: Number(teamId),
        data: teamOptions,
        onSuccess: async () => {
          formAddPercentage.reset();
          successNotification({
            title: 'Máquina adicionada!',
            message: 'tudo certo.',
          });
        },
      });
    }
  }

  useEffect(() => {
    dispatch(getServicesDispatcher());
    getMachineOptions();
  }, []);

  return (
    <Page>
      <Container miw={550}>
        <CustomLoader
          loading={
            serviceLoading || createTeamLoader || listMachineOptionsLoader
          }
        />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Criar Equipe
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Equipe" description="dados da equipe">
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome da equipe"
                mb={16}
                type="text"
                name="name"
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email para notificações"
                mb={16}
                type="text"
                name="email"
                {...form.getInputProps('email')}
              />
              <Select
                required
                withAsterisk
                name="type"
                label="Tipo de equipe"
                placeholder="selecione o tipo de equipe"
                data={teamTypeList}
                mb={16}
                {...form.getInputProps('type')}
              />
              {form.values.type === TeamType.TECHNICAL && (
                <>
                  <Divider mt={0} mb={14} />
                  <Text size={16} color="dark.4">
                    Configurações
                  </Text>
                  <Radio.Group
                    name="percentType"
                    label="Porcentagens de produção"
                    description="qual será a porcentagem de produção (serviço ou máquina)?"
                    withAsterisk
                    required
                    mt={16}
                    {...formSelectPercentType.getInputProps('percentType')}
                  >
                    <Radio value="service" label="Serviço" />
                    <Radio value="machine" label="Máquina" />
                  </Radio.Group>
                  {formSelectPercentType.values.percentType === 'service' && (
                    <Flex direction={'column'} mb={16} mt={16}>
                      <AddTeamConfigPercentageItem
                        title="Serviços relacionados"
                        itemList={serviceItems}
                        form={formAddPercentage}
                        handleSubmit={handleAddService}
                        loading={addTeamServicePercentLoading}
                        canAdd
                      />
                      <DataTable
                        mt={'sm'}
                        records={teamServices}
                        loaderVariant="oval"
                        loaderBackgroundBlur={2}
                        noRecordsText="Sem serviços"
                        minHeight={150}
                        columns={[
                          {
                            accessor: 'serviceName',
                            title: 'Serviço',
                            width: '50%',
                          },
                          {
                            accessor: 'percentage',
                            title: '% porcentagem',
                            width: '50%',
                          },
                        ]}
                      />
                    </Flex>
                  )}
                  {formSelectPercentType.values.percentType === 'machine' && (
                    <Flex direction={'column'} mb={16} mt={16}>
                      <AddTeamConfigPercentageItem
                        title="Máquinas relacionadas"
                        itemList={listMachineOptionsData ?? []}
                        form={formAddPercentage}
                        handleSubmit={handleAddOption}
                        loading={addTeamOptionPercentLoading}
                        canAdd
                      />
                      <DataTable
                        mt={'sm'}
                        records={teamOptions}
                        loaderVariant="oval"
                        loaderBackgroundBlur={2}
                        noRecordsText="Sem máquinas"
                        minHeight={150}
                        columns={[
                          {
                            accessor: 'optionName',
                            title: 'Máquina',
                            width: '50%',
                          },
                          {
                            accessor: 'percentage',
                            title: '% porcentagem',
                            width: '50%',
                          },
                        ]}
                      />
                    </Flex>
                  )}
                </>
              )}
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button
              loading={
                createTeamLoader ||
                addTeamServicePercentLoading ||
                addTeamOptionPercentLoading
              }
              color="dark"
              type="submit"
            >
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
