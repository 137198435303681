import {
  CompanyBalanceAppoval,
  CompanyBalanceType,
} from '../models/company-balance';
import { OrderStatus } from '../models/order';
import { PaymentSlipApprovalStatus } from '../models/payment-slip';
import { TeamType } from '../models/team';
import { TicketContactType } from '../models/ticket';
import { UserRole } from '../models/user';

export const serviceList = [
  { value: 'adesivo-resinado', label: 'Adesivo' },
  { value: 'corte-laser', label: 'Corte a laser' },
  { value: 'decalque', label: 'Decalque' },
  { value: 'digital-uv', label: 'Digital UV' },
  { value: 'envelopamento', label: 'Envelopamento' },
  { value: 'gravacao-laser', label: 'Gravação a laser' },
  { value: 'hot-stamping', label: 'Hot stamping' },
  { value: 'silkscreen', label: 'Silkscreen' },
  { value: 'sublimacao', label: 'Sublimação' },
  { value: 'tampografia', label: 'Tampografia' },
  { value: 'transfer', label: 'Transfer DTF' },
  { value: 'transfer-cilindrico', label: 'Transfer Cilindrico' },
  { value: 'digital-cilindrico', label: 'Digital Cilindrico' },
];

export const statusList = [
  {
    label: 'em aberto',
    value: OrderStatus.CREATED,
  },
  {
    label: 'aguardando material',
    value: OrderStatus.WAITING_MATERIAL,
  },
  {
    label: 'material recebido',
    value: OrderStatus.MATERIAL_RECEIVED,
  },
  {
    label: 'liberado produção',
    value: OrderStatus.RELEASED_PRODUCTION,
  },
  {
    label: 'cancelado',
    value: OrderStatus.CANCELLED,
  },
  {
    label: 'aguardando troca de peças',
    value: OrderStatus.CHANGE_MATERIAL,
  },
  {
    label: 'falta de peças',
    value: OrderStatus.LOWER_QTDE,
  },
  {
    label: 'em produção',
    value: OrderStatus.ON_PRODUCTION,
  },
  {
    label: 'produzido',
    value: OrderStatus.PRODUCED,
  },
  {
    label: 'aguardando embalagem',
    value: OrderStatus.WAITING_PACKAGING,
  },
  {
    label: 'finalizado',
    value: OrderStatus.FINISHED,
  },
  {
    label: 'retirado',
    value: OrderStatus.DISPATCHED,
  },
  {
    label: 'pausado',
    value: OrderStatus.PAUSED,
  },
  {
    label: 'layout pendente',
    value: OrderStatus.LAYOUT_PENDING,
  },
  {
    label: 'aguard. aprovação de layout',
    value: OrderStatus.LAYOUT_APPROVAL,
  },
];

export const statusOrder = {
  [OrderStatus.CREATED]: 1,
  [OrderStatus.WAITING_MATERIAL]: 2,
  [OrderStatus.MATERIAL_RECEIVED]: 3,
  [OrderStatus.RELEASED_PRODUCTION]: 4,
  [OrderStatus.ON_PRODUCTION]: 5,
  [OrderStatus.WAITING_PACKAGING]: 6,
  [OrderStatus.FINISHED]: 7,
  [OrderStatus.DISPATCHED]: 8,
  [OrderStatus.CANCELLED]: 9,
  [OrderStatus.LOWER_QTDE]: 0,
  [OrderStatus.CHANGE_MATERIAL]: 0,
  [OrderStatus.PRODUCED]: 0,
  [OrderStatus.PAUSED]: 0,
  [OrderStatus.LAYOUT_PENDING]: 0,
  [OrderStatus.LAYOUT_APPROVAL]: 0,
};

export const orderStatusHumanized = {
  [OrderStatus.CREATED]: 'em aberto',
  [OrderStatus.WAITING_MATERIAL]: 'aguardando material',
  [OrderStatus.MATERIAL_RECEIVED]: 'material recebido',
  [OrderStatus.RELEASED_PRODUCTION]: 'liberado produção',
  [OrderStatus.CHANGE_MATERIAL]: 'aguardando troca de peças',
  [OrderStatus.LOWER_QTDE]: 'falta de peças',
  [OrderStatus.ON_PRODUCTION]: 'em produção',
  [OrderStatus.PRODUCED]: 'produzido',
  [OrderStatus.WAITING_PACKAGING]: 'aguardando embalagem',
  [OrderStatus.FINISHED]: 'finalizado',
  [OrderStatus.DISPATCHED]: 'retirado',
  [OrderStatus.CANCELLED]: 'cancelado',
  [OrderStatus.PAUSED]: 'pausado',
  [OrderStatus.LAYOUT_PENDING]: 'layout pendente',
  [OrderStatus.LAYOUT_APPROVAL]: 'aguard. aprovação de layout',
};

export const orderLayoutStatusHumanized = {
  on_progress: 'em andamento',
  pending_vendor: 'aguar. vendedor',
  pending_client: 'aguar. cliente',
  approved: 'aprovado',
  reproved: 'reprovado',
};

export const orderLayoutStatusColor = {
  on_progress: 'blue',
  pending_vendor: 'orange',
  pending_client: 'orange',
  approved: 'green',
  reproved: 'red',
};

export const userRoleHumanized = {
  [UserRole.VENDOR]: 'vendedor',
  [UserRole.COMPANY]: 'cliente',
  [UserRole.RECEPTIONIST]: 'recepção',
  [UserRole.MANAGER]: 'gerente',
  [UserRole.OTHER]: 'outro',
  [UserRole.FINAL_ART]: 'arte finalista',
  [UserRole.TECHNICAL]: 'técnico',
  [UserRole.PACKER]: 'embalagem',
  [UserRole.FINANCIAL]: 'financeiro',
  [UserRole.HANDLER]: 'manuseio',
};

export const teamTypeHumanized = {
  [TeamType.VENDOR]: 'vendedores',
  [TeamType.ART]: 'arte finalistas',
  [TeamType.PACK]: 'embaladores',
  [TeamType.TECHNICAL]: 'técnicos',
  [TeamType.RECEPTION]: 'recepcionistas',
  [TeamType.FINANCIAL]: 'financeiro',
  [TeamType.HANDLING]: 'manuseio',
  [TeamType.SAC]: 'sac',
};

export const orderProductionTypeHumanized = {
  setup: 'em setup',
  production: 'em produção',
  pause: 'em pausa',
  partial_finished: 'finalizado',
};

export const orderPackingTypeHumanized = {
  pack: 'embalagem',
  unpack: 'desembalagem',
  pause: 'em pausa',
};

export const orderStatusColor = {
  [OrderStatus.CREATED]: 'red',
  [OrderStatus.WAITING_MATERIAL]: 'dark.6',
  [OrderStatus.MATERIAL_RECEIVED]: 'violet',
  [OrderStatus.RELEASED_PRODUCTION]: 'yellow',
  [OrderStatus.CHANGE_MATERIAL]: 'yellow.4',
  [OrderStatus.LOWER_QTDE]: 'yellow.6',
  [OrderStatus.ON_PRODUCTION]: 'orange.6',
  [OrderStatus.PRODUCED]: 'orange',
  [OrderStatus.WAITING_PACKAGING]: 'orange.3',
  [OrderStatus.FINISHED]: 'green',
  [OrderStatus.DISPATCHED]: 'blue',
  [OrderStatus.CANCELLED]: 'gray',
  [OrderStatus.PAUSED]: 'pink.4',
  [OrderStatus.LAYOUT_PENDING]: 'grape',
  [OrderStatus.LAYOUT_APPROVAL]: 'grape.6',
};

export const dispatchUnitList = [
  {
    label: 'LTP1 (Matriz)',
    value: 'Rua João Teodoro 458, Luz',
  },
  {
    label: 'LTP2 (Djalma)',
    value: 'Rua Djalma Dutra 192A, Luz',
  },
];

export const userTypeList = [
  {
    label: 'Vendedor',
    value: 'vendor',
  },
  {
    label: 'Cliente',
    value: 'company',
  },
  {
    label: 'Recepção',
    value: 'receptionist',
  },
  {
    label: 'Técnico',
    value: 'technical',
  },
  {
    label: 'Arte Finalista',
    value: 'final_art',
  },
  {
    label: 'Embalador',
    value: 'packer',
  },
  {
    label: 'Manuseio',
    value: 'handler',
  },
  {
    label: 'Financeiro',
    value: 'financial',
  },
];

export const teamTypeList = [
  {
    label: 'Vendedores',
    value: TeamType.VENDOR,
  },
  {
    label: 'Técnicos',
    value: TeamType.TECHNICAL,
  },
  {
    label: 'Recepcionistas',
    value: TeamType.RECEPTION,
  },
  {
    label: 'Arte Finalistas',
    value: TeamType.ART,
  },
  {
    label: 'Embaladores',
    value: TeamType.PACK,
  },
  {
    label: 'Financeiro',
    value: TeamType.FINANCIAL,
  },
  {
    label: 'Manuseio',
    value: TeamType.HANDLING,
  },
  {
    label: 'Sac',
    value: TeamType.SAC,
  },
];

export const errorsTranslate: Record<string, string> = {
  'Orders already have payments.': 'Pedidos ja faturados',
  'Activity is already in progress by other.':
    'Atividade em andamento por outro usuário.',
  'Invalid reset code.': 'Código inválido',
  'Reset code expired request other.': 'Código expirado',
  'Password dont match.': 'As senhas não são iguais',
  "Can't generate NFSe to non finished order.":
    'Impossivel emitir notas para pedidos não finalizados',
  "Can't invoice non finished orders.":
    'Pedidos não finalizados não podem ser faturados',
  'Amount over max accepted.': 'Quantidade produzida acima da permitida.',
  'Has unfinished production.':
    'Não é possivel finalizar, existe produção em aberto.',
  "Can't do this, company with restrictions.":
    'Empresa com restrições, ação bloqueada.',
  'This username is already in use.': 'Ja existe usuário com este nome.',
  "Can't use this e-mail.": 'Já existe usuário com este e-mail.',
  "Can't find this user.": 'Usuário não encontrado.',
  'Passwords did not match.': 'As senhas não são iguais.',
  'You already have another activity in progress.':
    'Você já possui uma atividade em andamento.',
  "Can't export data without interval.":
    'Não é possivel exportar sem uma data.',
  "Can't export data over than 30 days.":
    'Não é possivel exportar dados acima de 30 dias.',
  'Some order already has an receipt.':
    'Algum dos pedidos ja possui um recibo.',
  "Can't cancel invoiced receipts.": 'Este recibo ja foi faturado.',
  'Some order is already invoiced.': 'Algum dos pedidos ja foi faturado.',
  "Can't release production without price.":
    'Não é possível liberar produção sem preço.',
  'Order already has a payment.': 'Um dos pedidos já possui um pagamento.',
  "Can't generate postal to invoiced order.":
    'Não é possivel criar correio para pedidos faturados.',
  'Already has company with this name.': 'Esta empresa já foi cadastrada.',
  'Already has company with this document.': 'CNPJ já esta em uso.',
  "You can't do this.": 'Você não pode fazer isso.',
  "Can't change price to invoiced orders.":
    'Não é possivel alterar preço de pedido faturado.',
  'Material is already in another company.':
    'Material já associado a outro cliente',
  'Only master can change company of material.':
    'Somente o master pode alterar o cliente do material',
  'Some order already has slips associated.':
    'Um dos pedidos já estão relacionados a outro comprovante',
  'This production has already done.': 'Esta produção já está finalizada.',
  'This order material is already in transfer.':
    'Material já em transferência.',
  'This order material is already transfered.': 'Material já transferido.',
  'Not allowed to access this resource':
    'Não autorizado a acessar este recurso',
  'Failed to write file': 'Falha ao imprimir arquivo',
  'Please select an Order to invoice': 'Selecione ao menos 1 pedido',
  'Another activity is already in progress':
    'Outra atividade está em andamento.',
  "Order released to production can't request layout.":
    'Não é possivel solicitar layout para pedidos em produção.',
  "Can't find this balance.": 'Movimentação não encontrada.',
  "Can't do approval on this balance.":
    'Aprovação não permitida para esta movimentação.',
  "Credit can't be grather than order price.":
    'Não é possivel dar um crédito maior que o valor do pedido.',
  'This Company has no credit.': 'Empresa não possui crédito.',
  'Company credit not enough to this operation.': 'Crédito insuficiente.',
  "Discount can't be greater order price.":
    'Não é possivel dar um desconto maior que o valor do pedido.',
  'Ticket not found.': 'Ticket não encontrado',
  'Ticket already rated.': 'Este ticket ja foi avaliado',
  'Max length exceded': 'Limite de palavras excedido',
};

export const statusTicket = {
  open: 1,
  waiting_info: 2,
  on_progress: 3,
  finished: 4,
};

export const ticketStatusHumanized = {
  open: 'em aberto',
  waiting_info: 'aguardando informações',
  on_progress: 'em progresso',
  finished: 'finalizado',
};

export const statusTicketList = [
  {
    label: 'em aberto',
    value: 'open',
  },
  {
    label: 'aguardando informações',
    value: 'waiting_info',
  },
  {
    label: 'em progresso',
    value: 'on_progress',
  },
  {
    label: 'finalizado',
    value: 'finished',
  },
];

export const ticketStatusColor = {
  open: 'red',
  waiting_info: 'dark.6',
  on_progress: 'violet',
  finished: 'green',
};

export const optionsTypeHumanized = {
  order: 'Pedido',
  activity: 'Atividade',
  packing: 'Embalagem',
  art_production: 'Arte Finalista',
  production: 'Produção',
  'bank-account': 'Conta Bancária',
  'user-data': 'Dados do Usuário',
  handling: 'Manuseio',
  'company-data': 'Dados da Empresa',
  'order-material-volume': 'Volume de Material',
};

export const optionsSubTypesHumanized = {
  steps: 'Etapas',
  steps_adesivo: 'Etapas de Adesivos',
  steps_digital: 'Etapas Digital',
  steps_decalque: 'Etapas Decalque',
  company_category: 'Categorias',
  company_payment_type: 'Tipos de Pagamento',
  company_payment_term: 'Termos de Pagamento',
  company_doc_type: 'Tipos de Documentos',
  production_machine: 'Máquinas de Produção',
  order_payment_type: 'Tipos de Pagamento',
  order_payment_status: 'Status de Pagamento',
  order_financial_status: 'Status Financeiro',
  order_transaction_type: 'Tipos de Transação',
  order_dispatch_locations: 'Local de Entrega',
  user_type: 'Tipos de Usuário',
  team_type: 'Tipos de Equipe',
  status: 'Status',
  bank_account_pix: 'Conta Pix',
  bank_account_deposit_number: 'Conta Depósito',
  order_material_volume_location: 'Local de Volume',
};

export const orderProbemHumanized = {
  product_defect: 'Produto com Defeito',
  incorrect_quantity: 'Quantidade Incorreta',
};

export const orderProblemApprovalTypeHumanized = {
  send: 'Sim',
  not_send: 'Não',
};

export const paymentSlipStatusList = [
  {
    label: 'pendente',
    value: PaymentSlipApprovalStatus.PENDING,
  },
  {
    label: 'aprovado',
    value: PaymentSlipApprovalStatus.APPROVED,
  },
  {
    label: 'reprovado',
    value: PaymentSlipApprovalStatus.REPROVED,
  },
  {
    label: 'cancelado',
    value: PaymentSlipApprovalStatus.CANCELLED,
  },
];

export const paymentSlipStatusHumanized = {
  [PaymentSlipApprovalStatus.PENDING]: 'pendente',
  [PaymentSlipApprovalStatus.APPROVED]: 'aprovado',
  [PaymentSlipApprovalStatus.REPROVED]: 'reprovado',
  [PaymentSlipApprovalStatus.CANCELLED]: 'cancelado',
};

export const paymentSlipStatusColor = {
  [PaymentSlipApprovalStatus.PENDING]: 'orange.6',
  [PaymentSlipApprovalStatus.APPROVED]: 'green',
  [PaymentSlipApprovalStatus.REPROVED]: 'red',
  [PaymentSlipApprovalStatus.CANCELLED]: 'gray',
};

export const tagTargetHumanized = {
  team: 'Equipe',
  volumeName: 'Nome do Volume',
  type: 'Tipo',
  receivedBy: 'Quem recebeu',
  status: 'Andamento',
};

export const ticketContactTypeList = [
  {
    label: 'Telefone',
    value: 'phone',
  },
  {
    label: 'WhatsApp',
    value: 'whatsapp',
  },
  {
    label: 'E-mail',
    value: 'email',
  },
];

export const ticketContactTypeHumanized = {
  [TicketContactType.EMAIL]: 'Email',
  [TicketContactType.PHONE]: 'Telefone',
  [TicketContactType.WHATSAPP]: 'WhatsApp',
};

export const typePackingList = [
  {
    label: 'embalagem',
    value: 'pack',
  },
  {
    label: 'desembalagem',
    value: 'unpack',
  },
  {
    label: 'pausa',
    value: 'pause',
  },
];

export const companyBalanceApprovalHumanized = {
  [CompanyBalanceAppoval.PENDING]: 'pendente',
  [CompanyBalanceAppoval.APPROVED]: 'aprovado',
  [CompanyBalanceAppoval.REPROVED]: 'reprovado',
};

export const companyBalanceTypeHumanized = {
  [CompanyBalanceType.CREDIT]: 'credito',
  [CompanyBalanceType.DEBIT]: 'débito',
  [CompanyBalanceType.REFUND]: 'reembolso',
};

export const companyBalanceApprovalColor = {
  [CompanyBalanceAppoval.PENDING]: 'orange.6',
  [CompanyBalanceAppoval.APPROVED]: 'green',
  [CompanyBalanceAppoval.REPROVED]: 'red',
};
