/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Group,
  Select,
  Stepper,
  Switch,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { IconArrowLeft, IconCheck, IconX } from '@tabler/icons';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { CustomLoader } from '../../components/CustomLoader';
import {
  rootNavigate,
  rootNavigateGoBack,
} from '../../components/CustomRouter';

import {
  AddTeamConfigPercentageItem,
  TeamOptionPercentageList,
  TeamServicePercentageList,
} from '../../components/ConfigServicePercentage';
import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import {
  useAddTeamOptionPercent,
  useAddTeamServicePercent,
  useGetTeamOptionPercent,
  useGetTeamServicePercent,
  useShowTeam,
  useUpdateTeam,
} from '../../data/hooks/teams';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import {
  AddTeamOptionPercent,
  AddTeamServicePercent,
  TeamType,
} from '../../models/team';
import { TeamConfigAlias, TeamConfigType } from '../../models/team-config';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { RootState } from '../../providers/store';
import { getServicesDispatcher } from '../../store/services';
import { teamTypeList } from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';

type FormType = {
  name: string;
  email: string;
  type: string;
  services: number[];
  hasMultipleServices?: boolean;
  multipleServiceLastStep?: number[];
  editOrderPostProduction?: boolean;
};

export type FormAddPercentage = {
  item: string;
  percentage: string;
};

export function TeamEdit() {
  const { id } = useParams();
  const dispatch = useDispatch<any>();
  const { items: serviceItems, loading: serviceLoading } = useSelector(
    (state: RootState) => state.services,
  );

  const { fetch: updateTeamFetcher, loading: updateTeamLoader } =
    useUpdateTeam();
  const { fetch: showTeamFetcher, loading: showTeamLoader } = useShowTeam();
  const {
    fetch: getTeamServicePercentFetcher,
    response: getTeamServicePercentData,
    loading: getTeamServicePercentLoading,
  } = useGetTeamServicePercent();
  const {
    fetch: addTeamServicePercentFetcher,
    loading: addTeamServicePercentLoading,
  } = useAddTeamServicePercent();
  const {
    fetch: getTeamOptionPercentFetcher,
    response: getTeamOptionPercentData,
    loading: getTeamOptionPercentLoading,
  } = useGetTeamOptionPercent();
  const {
    fetch: addTeamOptionPercentFetcher,
    loading: addTeamOptionPercentLoading,
  } = useAddTeamOptionPercent();
  const {
    fetch: listMachineOptionsFetcher,
    reponseData: listMachineOptionsData,
    loading: listMachineOptionsLoader,
  } = useListOptions();

  const form = useForm<FormType>({
    initialValues: {
      name: '',
      email: '',
      type: '',
      services: [],
      hasMultipleServices: false,
      editOrderPostProduction: false,
      multipleServiceLastStep: [],
    },
  });

  const formAddPercentage = useForm<FormAddPercentage>({
    initialValues: {
      item: '',
      percentage: '',
    },
  });

  async function handleSubmit(values: typeof form.values) {
    const configs = [];

    if (values?.services?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_FILTER,
        type: TeamConfigType.LIST,
        value: values.services.join(','),
      });
    }

    if (values.hasMultipleServices) {
      configs.push({
        alias: TeamConfigAlias.HAS_MULTIPLE_SERVICES,
        type: TeamConfigType.BOOLEAN,
        value: String(values.hasMultipleServices),
      });
    }

    if (values.hasMultipleServices && values.multipleServiceLastStep?.length) {
      configs.push({
        alias: TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
        type: TeamConfigType.LIST,
        value: values.multipleServiceLastStep.join(','),
      });
    }

    if (values.editOrderPostProduction) {
      configs.push({
        alias: TeamConfigAlias.EDIT_ORDER_POST_PRODUCTION,
        type: TeamConfigType.BOOLEAN,
        value: String(values.editOrderPostProduction),
      });
    }

    await updateTeamFetcher({
      id: Number(id),
      data: {
        email: values.email,
        type: values.type as TeamType,
        name: values.name,
        configs,
      },
      onSuccess: () => {
        rootNavigate(AppRoutePaths.TEAMS);
        successNotification({
          title: 'Dados da equipe atualizado!',
          message: 'tudo certo.',
        });
      },
    });
  }

  async function getTeamDetail() {
    await showTeamFetcher({
      id: Number(id),
      onSuccess: (team) => {
        const techConfig = team.configs.find(
          (item) => item.alias === TeamConfigAlias.SERVICE_FILTER,
        );
        const hasMultipleServices = team.configs.find(
          (item) => item.alias === TeamConfigAlias.HAS_MULTIPLE_SERVICES,
        );
        const servicesLastStepList = team.configs.find(
          (item) => item.alias === TeamConfigAlias.SERVICE_PRODUCTION_LAST_STEP,
        );
        const editOrderPostProduction = team.configs.find(
          (item) => item.alias === TeamConfigAlias.EDIT_ORDER_POST_PRODUCTION,
        );

        form.setValues({
          email: team.email,
          name: team.name,
          type: team.type,
          services: techConfig?.value.split(',')?.map(Number),
          hasMultipleServices: hasMultipleServices?.value === 'true',
          editOrderPostProduction: editOrderPostProduction?.value === 'true',
          multipleServiceLastStep: servicesLastStepList?.value
            .split(',')
            ?.map(Number),
        });
      },
    });
  }

  async function handleAddService(values: typeof formAddPercentage.values) {
    if (!values.item || !values.percentage) {
      errorNotification({
        title: 'Serviço ou Porcentagem inválidos',
        message: 'verifique os campos',
      });
      return;
    }

    let servicePercentList: AddTeamServicePercent[] = [];

    if (getTeamServicePercentData) {
      servicePercentList = [
        ...getTeamServicePercentData.map((item) => ({
          service: item.serviceId,
          percentage: item.percentage,
        })),
      ];
    }

    await addTeamServicePercentFetcher({
      id: Number(id),
      data: [
        ...servicePercentList,
        {
          service: Number(values.item.split(',')[1]),
          percentage: values.percentage,
        },
      ],
      onSuccess: async () => {
        formAddPercentage.reset();
        await handleGetTeamServicePercents();
        successNotification({
          title: 'Porcentagem adicionada!',
          message: 'tudo certo.',
        });
      },
    });
  }

  async function handleAddOption(values: typeof formAddPercentage.values) {
    if (!values.item || !values.percentage) {
      errorNotification({
        title: 'Máquina ou Porcentagem inválidos',
        message: 'verifique os campos',
      });
      return;
    }

    let optionPercentList: AddTeamOptionPercent[] = [];

    if (getTeamOptionPercentData) {
      optionPercentList = [
        ...getTeamOptionPercentData.map((item) => ({
          option: item.optionId,
          percentage: item.percentage,
        })),
      ];
    }

    await addTeamOptionPercentFetcher({
      id: Number(id),
      data: [
        ...optionPercentList,
        {
          option: Number(values.item.split(',')[1]),
          percentage: values.percentage,
        },
      ],
      onSuccess: async () => {
        formAddPercentage.reset();
        await handleGetTeamOptionPercents();
        successNotification({
          title: 'Porcentagem adicionada!',
          message: 'tudo certo.',
        });
      },
    });
  }

  async function handleGetTeamServicePercents() {
    await getTeamServicePercentFetcher({
      params: {
        team: Number(id),
      },
    });
  }

  async function handleGetTeamOptionPercents() {
    await getTeamOptionPercentFetcher({
      params: {
        team: Number(id),
      },
    });
  }

  async function handleSubmitChangeServicePercentage(
    values: typeof formAddPercentage.values,
  ) {
    if (!getTeamServicePercentData) {
      return;
    }

    await addTeamServicePercentFetcher({
      id: Number(id),
      data: handleCreateUpdatePercentList(
        values,
        getTeamServicePercentData,
        'service',
      ),
      onSuccess: async () => {
        formAddPercentage.reset();
        await handleGetTeamServicePercents();
        successNotification({
          title: 'Porcentagem atualizada!',
          message: 'tudo certo.',
        });
      },
    });

    formAddPercentage.reset();
  }

  async function handleSubmitChangeOptionPercentage(
    values: typeof formAddPercentage.values,
  ) {
    if (!getTeamOptionPercentData) {
      return;
    }

    await addTeamOptionPercentFetcher({
      id: Number(id),
      data: handleCreateUpdatePercentList(
        values,
        getTeamOptionPercentData,
        'option',
      ),
      onSuccess: async () => {
        formAddPercentage.reset();
        await handleGetTeamOptionPercents();
        successNotification({
          title: 'Porcentagem adicionada!',
          message: 'tudo certo.',
        });
      },
    });

    formAddPercentage.reset();
  }

  async function getMachineOptions() {
    await listMachineOptionsFetcher({
      query: {
        type: OptionTypes.PRODUCTION,
        subtype: OptionSubTypes.PRODUCTION_MACHINE,
      },
    });
  }

  function handleCreateUpdatePercentList(
    values: FormAddPercentage,
    percentList: any[],
    type: string,
  ) {
    const updateList: any[] = percentList.map((item) => {
      if (values.item === String(item[`${type}Id`])) {
        item.percentage = values.percentage;
      }
      return {
        [`${type}`]: item[`${type}Id`],
        percentage: item.percentage,
      };
    });

    return updateList;
  }

  useEffect(() => {
    getTeamDetail();
    handleGetTeamServicePercents();
    handleGetTeamOptionPercents();
    getMachineOptions();
  }, [id]);

  useEffect(() => {
    if (form.values.type === TeamType.TECHNICAL) {
      dispatch(getServicesDispatcher());
    }
  }, [form.values.type]);

  return (
    <Page>
      <Container miw={550}>
        <CustomLoader
          loading={
            updateTeamLoader ||
            showTeamLoader ||
            serviceLoading ||
            listMachineOptionsLoader
          }
        />
        <Grid gutter="xs" columns={5}>
          <Grid.Col span={1}>
            <Flex align="center" justify="start">
              <Button
                onClick={() => rootNavigateGoBack()}
                color="dark.1"
                variant="subtle"
                w={40}
                p={0}
              >
                <IconArrowLeft />
              </Button>
            </Flex>
          </Grid.Col>
          <Grid.Col span={3}>
            <Flex align="center" justify="center"></Flex>
          </Grid.Col>
          <Grid.Col span={1}>
            <Flex align="center" justify="end"></Flex>
          </Grid.Col>
        </Grid>
        <Title size={40} fw="bolder" color="orange" order={1} align="center">
          Editar Equipe
        </Title>
        <Divider m={16} />
        <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
          <Stepper
            color="orange"
            orientation="horizontal"
            iconSize={37}
            active={0}
          >
            <Stepper.Step label="Equipe" description="dados da equipe">
              <TextInput
                required
                withAsterisk
                label="Nome"
                placeholder="nome da equipe"
                mb={16}
                type="text"
                name="name"
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                withAsterisk
                label="Email"
                placeholder="email para notificações"
                mb={16}
                type="text"
                name="email"
                {...form.getInputProps('email')}
              />
              <Select
                required
                withAsterisk
                name="type"
                label="Tipo de equipe"
                placeholder="selecione o tipo de equipe"
                data={teamTypeList}
                mb={16}
                {...form.getInputProps('type')}
              />
              {form.values.type === TeamType.TECHNICAL && (
                <>
                  <Divider mt={0} mb={14} />
                  <Text size={16} color="dark.4">
                    Configurações
                  </Text>

                  {getTeamServicePercentData?.length ? (
                    <Flex direction={'column'} mb={16} mt={16}>
                      <AddTeamConfigPercentageItem
                        title="Serviços relacionados"
                        itemList={serviceItems}
                        form={formAddPercentage}
                        handleSubmit={handleAddService}
                        loading={addTeamServicePercentLoading}
                        canAdd
                      />
                      <TeamServicePercentageList
                        form={formAddPercentage}
                        itemList={getTeamServicePercentData ?? []}
                        loading={getTeamServicePercentLoading}
                        handleSubmitChangePercentage={
                          handleSubmitChangeServicePercentage
                        }
                      />
                    </Flex>
                  ) : (
                    ''
                  )}

                  {getTeamOptionPercentData?.length ? (
                    <Flex direction={'column'}>
                      <AddTeamConfigPercentageItem
                        title="Máquinas relacionadas"
                        itemList={listMachineOptionsData ?? []}
                        form={formAddPercentage}
                        handleSubmit={handleAddOption}
                        loading={addTeamOptionPercentLoading}
                        canAdd
                      />
                      <TeamOptionPercentageList
                        form={formAddPercentage}
                        itemList={getTeamOptionPercentData ?? []}
                        loading={getTeamOptionPercentLoading}
                        handleSubmitChangePercentage={
                          handleSubmitChangeOptionPercentage
                        }
                      />
                    </Flex>
                  ) : (
                    ''
                  )}
                </>
              )}
              {form.values.type === TeamType.VENDOR && (
                <>
                  <Divider mt={0} mb={14} />
                  <Text size={16} color="dark.4">
                    Configurações
                  </Text>
                  <Switch
                    checked={form.values.editOrderPostProduction}
                    color="teal"
                    size="sm"
                    label="editar pós ˜lib. produção˜?"
                    mb={8}
                    thumbIcon={
                      form.values.editOrderPostProduction ? (
                        <IconCheck size="0.8rem" color="teal" stroke={3} />
                      ) : (
                        <IconX size="0.8rem" color="red" stroke={3} />
                      )
                    }
                    {...form.getInputProps('editOrderPostProduction')}
                  />
                </>
              )}
            </Stepper.Step>
          </Stepper>
          <Group position="right" mt="xl">
            <Button loading={updateTeamLoader} color="dark" type="submit">
              Salvar
            </Button>
          </Group>
        </form>
      </Container>
    </Page>
  );
}
