import { useFetch } from '../../../hooks/use-fetch';
import {
  CompanyBalance,
  CompanyBalanceCredit,
} from '../../../models/company-balance';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseApprovalCompanyBalanceProps,
  UseCreateCompanyBalanceProps,
  UseGetCompanyBalancePaginatedProps,
  UseGetCompanyCreditProps,
} from '../../../utils/types/data/hooks/company-balance';
import {
  approvalCompanyBalanceRequest,
  createCompanyBalanceRequest,
  getCompanyBalancePaginatedRequest,
  getCompanyCreditRequest,
} from '../../services/company-balance';

export function useCreateCompanyBalance() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({
    companyId,
    data,
    onSuccess,
  }: UseCreateCompanyBalanceProps) =>
    fetchData({
      fetcher: () => createCompanyBalanceRequest(companyId, data),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(err, 'Erro ao criar movimentação.'),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useApprovalCompanyBalance() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseApprovalCompanyBalanceProps) =>
    fetchData({
      fetcher: () => approvalCompanyBalanceRequest(id, data),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(
            err,
            'Erro ao avaliar movimentação.',
          ),
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetCompanyCredit() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<CompanyBalanceCredit>();

  const fetch = ({ companyId }: UseGetCompanyCreditProps) =>
    fetchData({
      fetcher: () => getCompanyCreditRequest(companyId),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(
            err,
            'Erro ao buscar crédito de empresa.',
          ),
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetCompanyBalancePaginated() {
  const {
    fetchData,
    state: { data: reponse, error, loading },
  } = useFetch<PaginatedResponse<CompanyBalance>>();

  const fetch = ({ query }: UseGetCompanyBalancePaginatedProps) =>
    fetchData({
      fetcher: () => getCompanyBalancePaginatedRequest(query),
      onError: (err: any) => {
        errorNotification({
          title: translateServerHttpErrors(
            err,
            'Erro ao listar movimentações.',
          ),
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponse,
    error,
    loading,
  };
}
