import { useFetch } from '../../../hooks/use-fetch';
import { Ticket } from '../../../models/ticket';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import {
  UseCreateTicketProps,
  UseGetTicketByHashCodeProps,
  UseGetTicketProps,
  UseRateTicketProps,
} from '../../../utils/types/data/hooks/tickets';
import {
  createTicketResquest,
  getTicketByHashCode,
  getTicketResquest,
  rateTicketRequest,
} from '../../services/ticket';

export function useCreateTicket() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, orderId, onSuccess }: UseCreateTicketProps) =>
    fetchData({
      fetcher: () => createTicketResquest(orderId, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar ticket.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetTicket() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Ticket>();

  const fetch = ({ ticketId }: UseGetTicketProps) =>
    fetchData({
      fetcher: () => getTicketResquest(ticketId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar ticket.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetTicketByHashCode() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Ticket>();

  const fetch = ({ hachCode }: UseGetTicketByHashCodeProps) =>
    fetchData({
      fetcher: () => getTicketByHashCode(hachCode),
      onError: (error: any) => {
        errorNotification({
          title: 'Falha ao buscar ticket.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useRateTicket() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ data, ticketId, onSuccess }: UseRateTicketProps) =>
    fetchData({
      fetcher: () => rateTicketRequest(ticketId, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Falha ao avaliar ticket.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
