import { useForm } from '@mantine/form';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { CustomLoader } from '../../components/CustomLoader';
import { rootNavigateGoBack } from '../../components/CustomRouter';
import { Page } from '../../components/Page';
import { useListOptions } from '../../data/hooks/options';
import { useRemoveOrderFile } from '../../data/hooks/order-files';
import { OptionSubTypes, OptionTypes } from '../../models/option';
import { OrderFile } from '../../models/order-file';
import { UserRole, UserType } from '../../models/user';
import { dayjsPlugins } from '../../providers/dayjs-plugins';
import { RootState } from '../../providers/store';
import {
  getOrderDispatcher,
  ordersActions,
  updateOrderDispatcher,
} from '../../store/orders';
import { getServicesDispatcher } from '../../store/services';
import { formatBRL, realToUSCash } from '../../utils/helpers';
import { AdesivoResinadoEditItem } from './adesivo-resinado';
import { AdminEditOrderMobile } from './admin/mobile';
import { CompanyEditOrderMobile } from './company/mobile';
import { CorteLaserEditItem } from './corte-laser';
import { DecalqueEditItem } from './decalque';
import { DigitalUVEditItem } from './digital-uv';
import { DTFUVFormItem } from './dtf-uv';
import { EnvelopamentoEditItem } from './envelopamento';
import { EtiquetaResinadaEditItem } from './etiqueta-resinada';
import { GravacaoLaserEditItem } from './gravacao-laser';
import { HotStampingEditItem } from './hot-stamping';
import { SilkscreenEditItem } from './silkscreen';
import { SublimacaoEditItem } from './sublimacao';
import { TampoEditItem } from './tampo';
import { TransferEditItem } from './transfer';
import { TransferCilindricoEditItem } from './transfer-cilindrico';
import { VendorEditOrderMobile } from './vendor/mobile';
import { DefectType } from '../../utils/enums';
import { errorNotification } from '../../providers/mantine-notifications';
import { DigitalCilindricoEditItem } from './digital-cilindrico';
import { useUploadMultipleFiles } from '../../data/hooks/files';

type FormValues = {
  internalNumber: string;
  os: string;
  company: string;
  service: string;
  product: string;
  productColor: string;
  productMaterial: string;
  pickUpLocation: string;
  pickUpName: string;
  pickUpNumber: string;
  carrierName: string;
  engravingText: string;
  orderAccess: string;
  needsPostal: 'none' | 'true' | 'false';
  pickUpForecast: Date;
  dispatchForecast: Date;
  receiptForecast: Date;
  finishForecast: Date;
  finishVendorForecast: Date;
  dispatchLocation?: string;
  hasDefect: DefectType;
  quantity: number;
  quantityVolume: number;
  price: string;
  samplePrice: string;
  clichePrice: string;
  pholitoPrice: string;
  manipulationPrice: string;
  vectorizationPrice: string;
  files: { id: number }[];
  note?: string;
  newFiles: File[];
  newFileReceipts: File[];
  financialNote?: string;
  paymentType?: string;
  job?: string;
  supplier?: string;
  productCodeSupplier?: string;
  changeColorPrice: string;
  isSample?: boolean;
  clichePriceSelector: string;
  colorChangeAmount?: number;
  details: {
    colorsAmount?: number;
    personalizationArea?: string;
    personalizationSides?: string[];
    sidesAmount?: number;
    thickness?: number;
    width?: number;
    height?: number;
    has360Engraving?: boolean;
    hasCliche?: boolean;
    hasCut?: boolean;
    hasDepilate?: boolean;
    hasManipulation?: boolean;
    hasManyData?: boolean;
    hasPhotolito?: boolean;
    hasResin?: boolean;
    hasSample?: boolean;
    hasVarnish?: boolean;
    isRotated?: boolean;
    isSandblasted?: boolean;
    isTransparent?: boolean;
    needWhiteColor?: boolean;
    needChangeColor?: boolean;
    isChromia?: boolean;
  };
};

export function EditOrder() {
  const { id } = useParams();
  const {
    fetch: getListOptionsFetcher,
    loading: getListOptionsLoader,
    reponseData: getListOptionsData,
  } = useListOptions();
  const navigation = useNavigate();
  const dispatch = useDispatch<any>();
  const [selectedService, setSelectedService] = useState('');
  const { user } = useSelector((state: RootState) => state.auth);
  const { loading, selected } = useSelector((state: RootState) => state.orders);
  const { loading: servicesLoading, items: servicesItems } = useSelector(
    (state: RootState) => state.services,
  );
  const [orderFiles, setOrderFiles] = useState<OrderFile[]>();
  const { fetch: removeOrderFileFetcher, loading: removeOrderFileLoader } =
    useRemoveOrderFile();
  const { fetch: uploadFilesFetcher, loading: uploadFilesLoader } =
    useUploadMultipleFiles();

  const [currentStep, setCurrentStep] = useState(0);
  const nextStep = () =>
    setCurrentStep((current) => (current < 1 ? current + 1 : current));
  const prevStep = () =>
    setCurrentStep((current) => (current > 0 ? current - 1 : current));
  const form = useForm<FormValues>();

  const canGoToNextStep =
    form.values.product !== '' &&
    form.values.productColor !== '' &&
    form.values.productMaterial !== '' &&
    form.values.orderAccess !== '';

  const servicerFormRender: any = {
    adesivo: () => <AdesivoResinadoEditItem form={form} />,
    corte_a_laser: () => <CorteLaserEditItem form={form} />,
    decalque: () => <DecalqueEditItem form={form} />,
    digital_uv: () => <DigitalUVEditItem form={form} />,
    envelopamento: () => <EnvelopamentoEditItem form={form} />,
    etiqueta_resinada: () => <EtiquetaResinadaEditItem form={form} />,
    gravacao_a_laser: () => <GravacaoLaserEditItem form={form} />,
    hot_stamping: () => <HotStampingEditItem form={form} />,
    silkscreen: () => (
      <SilkscreenEditItem form={form} orderStatus={order?.status} />
    ),
    sublimacao: () => <SublimacaoEditItem form={form} />,
    tampografia: () => (
      <TampoEditItem form={form} orderStatus={order?.status} />
    ),
    transfer_dtf: () => <TransferEditItem form={form} />,
    transfer_cilindrico: () => <TransferCilindricoEditItem form={form} />,
    dtf_uv: () => <DTFUVFormItem form={form} />,
    digital_cilindrico: () => <DigitalCilindricoEditItem form={form} />,
  };

  const { details, order } = selected;

  const services = useMemo(() => {
    const items = servicesItems.map((item) => ({
      label: item.name,
      value: String(item.id),
      disabled: item.alias === 'envelopamento',
    }));

    return items;
  }, [servicesItems]);

  function handleSelectService(id: string) {
    form.setValues({
      service: id,
      details: {
        colorsAmount: 0,
        personalizationArea: 'string',
        personalizationSides: [],
        sidesAmount: 0,
        thickness: 0,
        width: 0,
        height: 0,
        has360Engraving: false,
        hasCliche: false,
        hasCut: false,
        hasDepilate: false,
        hasManipulation: false,
        hasManyData: false,
        hasPhotolito: false,
        hasResin: false,
        hasVarnish: false,
        isRotated: false,
        isSandblasted: false,
        isTransparent: false,
        needWhiteColor: false,
        isChromia: false,
        needChangeColor: false,
      },
    });
    setSelectedService(
      servicesItems.find((item) => item.id === Number(id))?.alias || '',
    );
  }

  async function handleUpdateFiles(files: File[]): Promise<number[]> {
    let fileIds: number[] = [];
    await uploadFilesFetcher({
      data: {
        files: files,
      },
      onSuccess: (res) => {
        fileIds = res;
      },
    });

    return fileIds;
  }

  async function handleSubmit(values: typeof form.values) {
    if (!order) return;
    const {
      details,
      orderAccess,
      needsPostal,
      newFiles,
      newFileReceipts,
      ...restValues
    } = values;

    if (
      user?.role === UserRole.VENDOR &&
      details.hasManipulation &&
      restValues.manipulationPrice === ''
    ) {
      errorNotification({
        title: 'Preencha o campo de Preço Manuseio.',
        message: 'campo obrigatório',
      });

      return;
    }

    let fileIds: number[] = [];
    let fileReceiptsIds: number[] = [];

    if (newFiles.length) {
      try {
        dispatch(ordersActions.initLoading());
        fileIds = await handleUpdateFiles(newFiles);
        dispatch(ordersActions.endLoading());
      } catch (error) {
        dispatch(ordersActions.endLoading());
      }
    }

    if (newFileReceipts.length) {
      try {
        dispatch(ordersActions.initLoading());
        fileReceiptsIds = await handleUpdateFiles(newFileReceipts);
        dispatch(ordersActions.endLoading());
      } catch (error) {
        dispatch(ordersActions.endLoading());
      }
    }

    const formatedFiles = fileIds.map((fileId) => ({ id: fileId }));
    const formatedFileReceipts = fileReceiptsIds.map((fileId) => ({
      id: fileId,
    }));

    dispatch(
      updateOrderDispatcher(
        order?.id,
        {
          ...restValues,
          service: { id: Number(restValues.service) },
          needsPickUp: orderAccess === 'collected',
          needsPostal: needsPostal === 'true' ? true : false,
          hasDefect: false,
          price: realToUSCash(restValues.price),
          samplePrice: realToUSCash(restValues.samplePrice),
          pholitoPrice: realToUSCash(restValues.pholitoPrice),
          vectorizationPrice: realToUSCash(restValues.vectorizationPrice),
          clichePrice: realToUSCash(restValues.clichePrice),
          manipulationPrice: realToUSCash(restValues.manipulationPrice),
          files: [...values.files, ...formatedFiles],
          fileReceipts: formatedFileReceipts ?? [],
          quantity: Number(restValues.quantity),
          company: restValues?.company
            ? { id: Number(restValues.company) }
            : undefined,
          changeColorPrice: details.needChangeColor
            ? realToUSCash(restValues.changeColorPrice)
            : '0.0',
          changeColorTotalPrice: details.needChangeColor
            ? (
                Number(realToUSCash(restValues.changeColorPrice)) *
                Number(details.colorsAmount)
              ).toFixed(2)
            : '0.0',
        },
        {
          ...details,
          personalizationSides: details.personalizationSides?.length
            ? details.personalizationSides.join(',')
            : '',
          height: Number(details.height),
          colorsAmount: Number(details.colorsAmount),
          sidesAmount: Number(details.sidesAmount),
          width: Number(details.width),
          thickness: Number(details.thickness),
        },
      ),
    );
    rootNavigateGoBack();
  }

  async function getLocationOptions() {
    await getListOptionsFetcher({
      query: {
        type: OptionTypes.ORDER,
        subtype: OptionSubTypes.ORDER_DISPATCH_LOCATIONS,
      },
    });
  }

  async function handleRemoveOrderFile(fileId: number) {
    await removeOrderFileFetcher({
      fileId,
      onSuccess: async () => {
        location.reload();
      },
    });
  }

  useEffect(() => {
    dispatch(getOrderDispatcher(Number(id)));
    return () => {
      dispatch(ordersActions.removeSelected());
    };
  }, [id]);

  useEffect(() => {
    dispatch(getServicesDispatcher());
    getLocationOptions();
  }, []);

  useEffect(() => {
    if (order && details) {
      form.setValues({
        internalNumber: order.internalNumber,
        os: order?.os,
        company: String(order.company.id),
        service: String(order.service.id),
        product: order.product.toLocaleLowerCase(),
        productColor: order.productColor,
        productMaterial: order.productMaterial,
        pickUpLocation: order.pickUpLocation,
        pickUpName: order.pickUpName,
        pickUpNumber: order.pickUpNumber,
        carrierName: order.carrierName,
        engravingText: order.engravingText,
        orderAccess: order.needsPickUp ? 'collected' : 'delivered',
        needsPostal: order.needsPostal ? 'true' : 'false',
        pickUpForecast: dayjsPlugins(order.pickUpForecast).toDate(),
        finishForecast: dayjsPlugins(order.finishForecast).toDate(),
        finishVendorForecast: dayjsPlugins(
          order.finishVendorForecast ?? new Date(),
        ).toDate(),
        dispatchForecast: dayjsPlugins(order.dispatchForecast).toDate(),
        receiptForecast: dayjsPlugins(order.receiptForecast).toDate(),
        dispatchLocation: order.dispatchLocation,
        quantity: order.quantity,
        quantityVolume: order.quantityVolume,
        price: formatBRL(order.price ?? '0'),
        samplePrice: formatBRL(order.samplePrice ?? '0'),
        clichePrice: formatBRL(order.clichePrice ?? '0'),
        pholitoPrice: formatBRL(order.pholitoPrice ?? '0'),
        manipulationPrice: formatBRL(order.manipulationPrice ?? '0'),
        vectorizationPrice: formatBRL(order.vectorizationPrice ?? '0'),
        clichePriceSelector: '',
        hasDefect: order.hasDefect,
        files: order.files.map((item) => ({ id: item.file.id })),
        note: order.note,
        newFiles: [],
        newFileReceipts: [],
        financialNote: order.financialNote,
        paymentType: order.paymentType,
        job: order.job,
        supplier: order.supplier,
        productCodeSupplier: order.productCodeSupplier,
        changeColorPrice: formatBRL(order.changeColorPrice ?? '0'),
        isSample: order.isSample,
        colorChangeAmount: order.colorChangeAmount,
        details: {
          colorsAmount: details?.colorsAmount ?? undefined,
          personalizationArea: details?.personalizationArea ?? undefined,
          personalizationSides:
            details?.personalizationSides?.split(',') ?? undefined,
          sidesAmount: details?.sidesAmount ?? undefined,
          thickness: details?.thickness ?? undefined,
          width: details?.width ?? undefined,
          height: details?.height ?? undefined,
          has360Engraving: details?.has360Engraving ?? undefined,
          hasCliche: details?.hasCliche ?? undefined,
          hasCut: details?.hasCut ?? undefined,
          hasDepilate: details?.hasDepilate ?? undefined,
          hasManipulation: details?.hasManipulation ?? undefined,
          hasManyData: details?.hasManyData ?? undefined,
          hasPhotolito: details?.hasPhotolito ?? undefined,
          hasResin: details?.hasResin ?? undefined,
          hasSample: details?.hasSample ?? undefined,
          hasVarnish: details?.hasVarnish ?? undefined,
          isRotated: details?.isRotated ?? undefined,
          isSandblasted: details?.isSandblasted ?? undefined,
          isTransparent: details?.isTransparent ?? undefined,
          needWhiteColor: details?.needWhiteColor ?? undefined,
          needChangeColor: details?.needChangeColor ?? undefined,
          isChromia: details.isChromia ?? undefined,
        },
      });
      setOrderFiles(order.files);
      setSelectedService(order.service.alias || '');
    }
  }, [order?.id, details?.createdAt]);

  return (
    <Page>
      <CustomLoader
        loading={
          servicesLoading ||
          loading ||
          getListOptionsLoader ||
          removeOrderFileLoader ||
          uploadFilesLoader
        }
      />
      {order && user?.role === UserRole.VENDOR && (
        <VendorEditOrderMobile
          locationOptions={getListOptionsData}
          orderStatus={order?.status}
          canGoToNextStep={canGoToNextStep}
          currentStep={currentStep}
          handleSelectService={handleSelectService}
          handleSubmit={handleSubmit}
          navigation={navigation}
          nextStep={nextStep}
          prevStep={prevStep}
          selectedService={selectedService}
          servicerFormRender={servicerFormRender}
          services={services}
          form={form}
          files={orderFiles || []}
          handleRemoveFile={handleRemoveOrderFile}
          isRework={order.rework}
          isSample={order.isSample}
          order={order}
        />
      )}
      {order && user?.role === UserRole.COMPANY && (
        <CompanyEditOrderMobile
          orderStatus={order?.status}
          canGoToNextStep={canGoToNextStep}
          currentStep={currentStep}
          handleSelectService={handleSelectService}
          handleSubmit={handleSubmit}
          navigation={navigation}
          nextStep={nextStep}
          prevStep={prevStep}
          selectedService={selectedService}
          servicerFormRender={servicerFormRender}
          services={services}
          form={form}
          files={orderFiles || []}
          handleRemoveFile={handleRemoveOrderFile}
          isSample={order.isSample}
        />
      )}
      {order && user?.type === UserType.MASTER && (
        <AdminEditOrderMobile
          locationOptions={getListOptionsData}
          orderStatus={order?.status}
          canGoToNextStep={canGoToNextStep}
          currentStep={currentStep}
          handleSelectService={handleSelectService}
          handleSubmit={handleSubmit}
          navigation={navigation}
          nextStep={nextStep}
          prevStep={prevStep}
          selectedService={selectedService}
          servicerFormRender={servicerFormRender}
          services={services}
          form={form}
          files={orderFiles || []}
          handleRemoveFile={handleRemoveOrderFile}
          isRework={order.rework}
          isSample={order.isSample}
        />
      )}
    </Page>
  );
}
