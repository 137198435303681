import { useFetch } from '../../../hooks/use-fetch';
import {
  Team,
  TeamOptionPercent,
  TeamServicePercent,
} from '../../../models/team';
import { errorNotification } from '../../../providers/mantine-notifications';
import {
  UseAddTeamOptionPercentage,
  UseAddTeamServicePercentage,
  UseCreateTeam,
  UseGetTeamOptionPercentage,
  UseGetTeamServicePercentage,
  UseShowTeam,
  UseUpdateTeam,
} from '../../../utils/types/data/hooks/teams';
import {
  addTeamOptionPercentage,
  addTeamServicePercentage,
  createTeamsRequest,
  getTeamOptionPercents,
  getTeamServicePercents,
  showTeamRequest,
  updateTeamsRequest,
} from '../../services/teams';

export function useShowTeam() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Team>();

  const fetch = ({ id, onSuccess }: UseShowTeam) =>
    fetchData({
      fetcher: () => showTeamRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar equipe.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useUpdateTeam() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Team>();

  const fetch = ({ data, id, onSuccess }: UseUpdateTeam) =>
    fetchData({
      fetcher: () => updateTeamsRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao atualizar equipe.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useCreateTeam() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<Team>();

  const fetch = ({ data, onSuccess }: UseCreateTeam) =>
    fetchData({
      fetcher: () => createTeamsRequest(data),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar equipe.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useAddTeamServicePercent() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseAddTeamServicePercentage) =>
    fetchData({
      fetcher: () => addTeamServicePercentage(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar porcentagem para serviço.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetTeamServicePercent() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<TeamServicePercent[]>();

  const fetch = ({ params, onSuccess }: UseGetTeamServicePercentage) =>
    fetchData({
      fetcher: () => getTeamServicePercents(params),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar porcentagens de serviços.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useAddTeamOptionPercent() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseAddTeamOptionPercentage) =>
    fetchData({
      fetcher: () => addTeamOptionPercentage(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar porcentagem para máquina.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}

export function useGetTeamOptionPercent() {
  const {
    fetchData,
    state: { data: response, error, loading },
  } = useFetch<TeamOptionPercent[]>();

  const fetch = ({ params, onSuccess }: UseGetTeamOptionPercentage) =>
    fetchData({
      fetcher: () => getTeamOptionPercents(params),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar porcentagens de maquinas.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    response,
    error,
    loading,
  };
}
