import {
  Badge,
  Box,
  Button,
  Card,
  Divider,
  Flex,
  Grid,
  Image,
  MediaQuery,
  Modal,
  Rating,
  Skeleton,
  Text,
  Textarea,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Page } from '../../components/Page';
import { appConfig } from '../../config';
import {
  useGetTicketByHashCode,
  useRateTicket,
} from '../../data/hooks/tickets';
import {
  ticketStatusColor,
  ticketStatusHumanized,
} from '../../utils/constants';
import { AppRoutePaths } from '../../utils/enums';
import { formatDateTime } from '../../utils/helpers';
import { RateTicketData } from '../../utils/types/data/services/tickets';
import { PageNotFound } from '../PageNotFound';
import { CustomLoader } from '../../components/CustomLoader';

export type RateTicketPageModalVisible = 'rate-ticket' | null;

export function SacRate() {
  const { hash } = useParams();
  const [pageModalVisible, setPageModalVisible] =
    useState<RateTicketPageModalVisible>(null);
  const ratingForm = useForm<RateTicketData>({
    initialValues: {
      sacRating: 0,
      vendorRating: 0,
      ratingNote: '',
    },
  });

  const {
    fetch: getTicketByHashCodeFetcher,
    response: getTicketByHashCodeResponse,
    loading: getTicketByHashCodeLoader,
  } = useGetTicketByHashCode();

  const { fetch: rateTicketFetcher, loading: rateTicketLoader } =
    useRateTicket();

  const navigation = useNavigate();

  function handleNavigate() {
    navigation({
      pathname: AppRoutePaths.LOGIN,
    });
  }

  function handleRateSac(values: typeof ratingForm.values) {
    if (!getTicketByHashCodeResponse) {
      return;
    }
    rateTicketFetcher({
      data: values,
      ticketId: getTicketByHashCodeResponse.id,
      onSuccess: () => {
        ratingForm.reset();
        setPageModalVisible('rate-ticket');
      },
    });
  }

  function handleGetTicketByHash() {
    if (!hash) {
      return;
    }
    getTicketByHashCodeFetcher({
      hachCode: hash,
    });
  }

  useEffect(() => {
    handleGetTicketByHash();
  }, []);

  return (
    <Page>
      <CustomLoader loading={rateTicketLoader} />
      <Image src={appConfig.sidebarImage} width={200} mb={26} />
      {!getTicketByHashCodeResponse ? (
        <PageNotFound />
      ) : (
        <MediaQuery
          smallerThan="sm"
          styles={{ flexDirection: 'column', gap: '20px' }}
        >
          <Flex
            justify={'center'}
            align={'center'}
            direction={'row'}
            gap={'80px'}
          >
            <form onSubmit={ratingForm.onSubmit(handleRateSac)}>
              <Flex
                justify={'center'}
                align={'center'}
                direction={'column'}
                gap={'xl'}
              >
                <h1>Sua Opinião é Importante!</h1>
                <Flex direction={'column'} align={'center'} gap={'xs'}>
                  <Text>
                    Como você avalia a atuação do time de SAC na resolução do
                    seu problema?
                  </Text>
                  <Rating
                    size="lg"
                    {...ratingForm.getInputProps('sacRating')}
                  />
                </Flex>

                <Flex direction={'column'} align={'center'} gap={'xs'}>
                  <Text>
                    Como você avalia o atendimento geral do time de vendas?
                  </Text>
                  <Rating
                    size="lg"
                    {...ratingForm.getInputProps('vendorRating')}
                  />
                </Flex>

                <Flex direction={'column'} align={'center'} gap={'xs'}>
                  <Text>
                    Há algum ponto de melhoria que você gostaria de pontuar ou
                    alguma insatisfação?
                  </Text>
                  <Textarea
                    w={'70%'}
                    autosize
                    placeholder="digite algo..."
                    {...ratingForm.getInputProps('ratingNote')}
                    maxLength={254}
                  />
                </Flex>
                <Button
                  type="submit"
                  loading={rateTicketLoader}
                  disabled={
                    ratingForm.values.sacRating === 0 ||
                    ratingForm.values.vendorRating === 0
                  }
                >
                  Enviar
                </Button>
              </Flex>
            </form>

            {getTicketByHashCodeLoader ? (
              <Skeleton height={410} width={480} radius="md" />
            ) : (
              <Box
                sx={(theme) => ({
                  padding: theme.spacing.xl,
                  borderRadius: theme.radius.lg,
                  backgroundColor: '#f7f7f7',
                })}
              >
                <Flex
                  direction={'column'}
                  align={'center'}
                  maw={'450px'}
                  w={'100%'}
                >
                  <Flex align="center" justify="center">
                    <Badge
                      color={
                        getTicketByHashCodeResponse &&
                        ticketStatusColor[getTicketByHashCodeResponse?.status]
                      }
                      mb={6}
                    >
                      {getTicketByHashCodeResponse &&
                        ticketStatusHumanized[
                          getTicketByHashCodeResponse?.status
                        ]}
                    </Badge>
                  </Flex>
                  <Grid gutter="xs" columns={3}>
                    <Grid.Col mt={8} mb={8} span={3}>
                      <Text color="gray">Info do Ticket</Text>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Flex direction="column">
                        <Text fw="bold">Código</Text>
                        <Text color="gray">
                          {getTicketByHashCodeResponse?.code}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Flex direction="column">
                        <Text fw="bold">Criado em</Text>
                        <Text color="gray">
                          {getTicketByHashCodeResponse?.createdAt
                            ? formatDateTime(
                                getTicketByHashCodeResponse?.createdAt,
                              )
                            : '--'}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={1}>
                      <Flex direction="column">
                        <Text fw="bold">Finalizado em</Text>
                        <Text color="gray">
                          {getTicketByHashCodeResponse?.finishedAt
                            ? formatDateTime(
                                getTicketByHashCodeResponse?.finishedAt,
                              )
                            : '--'}
                        </Text>
                      </Flex>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Flex direction="column">
                        <Text fw="bold">Observação</Text>
                        <Text color="gray">
                          {getTicketByHashCodeResponse?.obs}
                        </Text>
                      </Flex>
                    </Grid.Col>

                    <Grid.Col span={3}>
                      <Divider my="sm" variant="dashed" />
                    </Grid.Col>
                    <Grid.Col mt={8} mb={8} span={2}>
                      <Text color="gray">Info do Pedido</Text>
                    </Grid.Col>
                    <Grid.Col span={3}>
                      <Card shadow="xs" mb={8}>
                        <Flex justify="space-between">
                          <Flex direction="column">
                            <Text fw="bold" color="gray" size={12}>
                              OS
                            </Text>
                            <Text>{getTicketByHashCodeResponse?.orderOs}</Text>
                          </Flex>
                          <Flex direction="column">
                            <Text fw="bold" color="gray" size={12}>
                              Produto
                            </Text>
                            <Text>
                              {`${getTicketByHashCodeResponse?.orderProduct}, ${getTicketByHashCodeResponse?.orderProductColor}, ${getTicketByHashCodeResponse?.orderProductMaterial}`}
                            </Text>
                          </Flex>
                          <Flex direction="column">
                            <Text fw="bold" color="gray" size={12}>
                              Serviço
                            </Text>
                            <Text>
                              {getTicketByHashCodeResponse?.orderServiceName}
                            </Text>
                          </Flex>
                        </Flex>
                      </Card>
                    </Grid.Col>
                  </Grid>
                </Flex>
              </Box>
            )}
          </Flex>
        </MediaQuery>
      )}

      <Modal
        opened={pageModalVisible === 'rate-ticket'}
        onClose={() => setPageModalVisible(null)}
        closeOnEscape={false}
        withCloseButton={false}
        title={<Text fw="bold">Obrigado pelo seu feedback! 🎉</Text>}
      >
        <Flex direction={'column'} gap={'lg'}>
          <Text>Sua opinião nos ajuda a melhorar. Até a próxima!</Text>
          <Button onClick={() => handleNavigate()}>Feito</Button>
        </Flex>
      </Modal>
    </Page>
  );
}
