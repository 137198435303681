import {
  Badge,
  Button,
  Card,
  Checkbox,
  Flex,
  Grid,
  Group,
  Modal,
  ScrollArea,
  Select,
  Table,
  Text,
  Textarea,
  TextInput,
} from '@mantine/core';
import { useForm, UseFormReturnType } from '@mantine/form';
import { IconSearch } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useRef } from 'react';
import { CustomDateTimePicker } from '../../../components/CustoDateTimePicker';
import { OrderDetails } from '../../../components/OrderDetails';
import {
  useGetPaginatedOrderMaterial,
  useOrderMaterialAssociate,
} from '../../../data/hooks/order-materials';
import { Order, OrderStatus } from '../../../models/order';
import { MaterialStatus } from '../../../models/order-material';
import { User, UserRole } from '../../../models/user';
import { statusList } from '../../../utils/constants';
import {
  orderMaterialStatusColor,
  orderMaterialStatusHumanized,
} from '../../../utils/constants/order-material';

import { ListOrderMaterialAssociatedsResponse } from '../../../utils/types/data/services/order-material';

export type ModalChangeOrderStatusProps = {
  selectedOrder?: Order;
  changeStatusModalVisible: boolean;
  setChangeStatusModalVisible(state: boolean): void;
  formStatusChange: UseFormReturnType<any>;
  handleSubmitChangeStatus(values: any): void;
  user: User | null;
  formAssociate: UseFormReturnType<any>;
  associatedOrderMaterialList?: ListOrderMaterialAssociatedsResponse[];
  listMaterialAssociated(): void;
};

function MaterialTable({
  items,
}: {
  items: ListOrderMaterialAssociatedsResponse[];
}) {
  return (
    <Flex direction="column">
      <Text fw="bold">ARs associados</Text>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Código</th>
            <th>Produto</th>
            <th>Qtde</th>
            <th>Volume</th>
          </tr>
        </thead>
        <tbody>
          {items &&
            items.map((item) => (
              <tr>
                <td>{item.code}</td>
                <td>{`${item?.product}, ${item?.productColor}, ${item?.productMaterial}`}</td>
                <td>{item.quantity}</td>
                <td>{item.quantityVolume}</td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Flex>
  );
}

export function ModalChangeOrderStatus({
  changeStatusModalVisible,
  selectedOrder,
  setChangeStatusModalVisible,
  formStatusChange,
  handleSubmitChangeStatus,
  user,
  associatedOrderMaterialList,
  formAssociate,
  listMaterialAssociated,
}: ModalChangeOrderStatusProps) {
  const page = useRef(1);
  const limit = useRef(10);
  const formMaterialFilter = useForm({
    initialValues: {
      product: '',
    },
  });

  const {
    fetch: listOrderMaterialFecher,
    loading: listOrderMaterialLoader,
    response: listOrderMaterialResponse,
  } = useGetPaginatedOrderMaterial();

  const { fetch: materialAssociateFecher, loading: materialAssociateLoader } =
    useOrderMaterialAssociate();

  function closeModal() {
    formAssociate.reset();
    formStatusChange.reset();
    setChangeStatusModalVisible(false);
  }

  async function handleOrderMaterialPaginate(pageSize: number) {
    if (selectedOrder) {
      page.current = pageSize;
      await getOrderMaterial();
    }
  }

  async function handleOrderMaterialPageLimit(limitSize: number) {
    if (selectedOrder) {
      limit.current = limitSize;
      await getOrderMaterial();
    }
  }

  async function getOrderMaterial() {
    if (selectedOrder) {
      await listOrderMaterialFecher({
        query: {
          company: selectedOrder?.company.id,
          limit: limit.current,
          page: page.current,
          showAssociates: true,
          product:
            formMaterialFilter.values.product !== ''
              ? formMaterialFilter.values.product
              : undefined,
        },
      });
    }
  }

  async function handleMaterialAssociate(values: typeof formAssociate.values) {
    await materialAssociateFecher({
      id: Number(values.materialId),
      orderId: Number(values.orderId),
      onSuccess: () => {
        formAssociate.reset();
        getOrderMaterial();
        listMaterialAssociated();
      },
    });
  }

  useEffect(() => {
    getOrderMaterial();
  }, [selectedOrder]);

  return (
    <Modal
      opened={changeStatusModalVisible}
      onClose={closeModal}
      title="Alterar status do pedido"
      size={
        formStatusChange.values.status === OrderStatus.RELEASED_PRODUCTION
          ? 'lg'
          : 'md'
      }
    >
      <form
        onSubmit={formStatusChange.onSubmit((values) =>
          handleSubmitChangeStatus(values),
        )}
      >
        <Grid gutter="xs" columns={2}>
          <Grid.Col span={2}>
            <Select
              required
              withAsterisk
              name="status"
              label="Status"
              placeholder="selecione o novo status do pedido"
              data={
                user?.role === UserRole.VENDOR
                  ? statusList.filter(
                      (obj) =>
                        obj.value !== OrderStatus.FINISHED &&
                        obj.value !== OrderStatus.PRODUCED &&
                        obj.value !== OrderStatus.DISPATCHED &&
                        obj.value !== OrderStatus.LOWER_QTDE &&
                        obj.value !== OrderStatus.CHANGE_MATERIAL &&
                        obj.value !== OrderStatus.ON_PRODUCTION &&
                        obj.value !== OrderStatus.WAITING_PACKAGING &&
                        obj.value !== OrderStatus.LAYOUT_PENDING &&
                        obj.value !== OrderStatus.LAYOUT_APPROVAL,
                    )
                  : statusList
              }
              mb={16}
              {...formStatusChange.getInputProps('status')}
            />
          </Grid.Col>
          {formStatusChange.values.status === OrderStatus.FINISHED && (
            <Grid.Col span={1}>
              <TextInput
                withAsterisk
                required
                label="Volumes"
                placeholder="digite os volumes"
                mb={16}
                type="number"
                name="quantityVolume"
                {...formStatusChange.getInputProps('quantityVolume')}
              />
            </Grid.Col>
          )}
          {formStatusChange.values.status === OrderStatus.PAUSED && (
            <Grid.Col span={2} mb={16}>
              <Textarea
                maxLength={255}
                withAsterisk
                required
                label="Observações"
                placeholder="escreva aqui qualquer observação sobre o status pausado"
                name="description"
                {...formStatusChange.getInputProps('description')}
              />
            </Grid.Col>
          )}
        </Grid>
        {formStatusChange.values.status === OrderStatus.MATERIAL_RECEIVED && (
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <TextInput
                withAsterisk
                required
                label="Portador"
                placeholder="nome do portador"
                mb={16}
                type="text"
                name="carrierName"
                {...formStatusChange.getInputProps('carrierName')}
              />
            </Grid.Col>
            <Grid.Col span={2}>
              <Checkbox
                label="Quantidade esta incorreta"
                color="green"
                name="isInconrrect"
                mb={16}
                {...formStatusChange.getInputProps('isInconrrect')}
              />
            </Grid.Col>
            {formStatusChange.values.isInconrrect && (
              <>
                <Grid.Col span={1}>
                  <TextInput
                    required
                    withAsterisk
                    label="Quantidade"
                    placeholder="digite a quantidade"
                    mb={16}
                    type="number"
                    name="incorrectQuantity"
                    {...formStatusChange.getInputProps('incorrectQuantity')}
                  />
                </Grid.Col>
                <Grid.Col span={1}>
                  <TextInput
                    required
                    withAsterisk
                    label="Volumes"
                    placeholder="digite os volumes"
                    mb={16}
                    type="number"
                    name="quantityVolume"
                    {...formStatusChange.getInputProps('quantityVolume')}
                  />
                </Grid.Col>
              </>
            )}
          </Grid>
        )}
        {formStatusChange.values.status === OrderStatus.DISPATCHED && (
          <Grid gutter="xs" columns={2}>
            <Grid.Col span={2}>
              <TextInput
                required
                withAsterisk
                label="Portador"
                placeholder="nome do portador"
                mb={16}
                type="text"
                name="carrierName"
                {...formStatusChange.getInputProps('carrierName')}
              />
            </Grid.Col>
            <Grid.Col span={1}>
              <TextInput
                withAsterisk
                required
                label="Volumes"
                placeholder="digite os volumes"
                mb={16}
                type="number"
                name="quantityVolume"
                {...formStatusChange.getInputProps('quantityVolume')}
              />
            </Grid.Col>
          </Grid>
        )}
        {formStatusChange.values.status === OrderStatus.RELEASED_PRODUCTION && (
          <>
            <Grid gutter="xs" columns={2}>
              <Grid.Col span={1}>
                <CustomDateTimePicker
                  label="Prazo de finalização"
                  onChange={(date) =>
                    formStatusChange.setValues({ finishVendorForecast: date })
                  }
                />
              </Grid.Col>
            </Grid>
            <Text fw="bold" mb={16}>
              Confirme as informações abaixo antes de liberar para produção:{' '}
            </Text>
            <Card withBorder mb={16}>
              <ScrollArea h={400}>
                {selectedOrder && (
                  <OrderDetails
                    sections={['basic', 'prices', 'service-details']}
                    orderId={selectedOrder?.id}
                  />
                )}
              </ScrollArea>
            </Card>
            <MaterialTable items={associatedOrderMaterialList ?? []} />
            <Text mt={16} fw="bold">
              ARs para associar
            </Text>
            <Group>
              <TextInput
                label="Produto"
                placeholder="nome do produto"
                mb={16}
                type="text"
                {...formMaterialFilter.getInputProps('product')}
              />
              <Button
                onClick={getOrderMaterial}
                mb={-8}
                p={4}
                loading={listOrderMaterialLoader}
                color="blue"
                type="button"
              >
                <IconSearch />
              </Button>
            </Group>
            <DataTable
              recordsPerPageLabel="Itens por página"
              recordsPerPageOptions={[10, 50, 100]}
              onRecordsPerPageChange={(recordRange) =>
                handleOrderMaterialPageLimit(recordRange)
              }
              onRowClick={(row) => {
                [MaterialStatus.APPROVED, MaterialStatus.ASSOCIATED].includes(
                  row.status,
                ) &&
                  formAssociate.setValues({
                    orderId: String(selectedOrder?.id),
                    materialId: String(row.id),
                  });
              }}
              rowStyle={(row) =>
                formAssociate.values.materialId === String(row.id)
                  ? { backgroundColor: '#228be640' }
                  : {}
              }
              fetching={false}
              height={350}
              noRecordsText="Sem Ar's"
              withBorder
              borderRadius="sm"
              striped
              highlightOnHover
              page={page.current}
              totalRecords={listOrderMaterialResponse?.meta.totalItems}
              recordsPerPage={
                listOrderMaterialResponse?.meta.itemsPerPage ?? limit.current
              }
              onPageChange={(newPage) => handleOrderMaterialPaginate(newPage)}
              records={listOrderMaterialResponse?.items}
              columns={[
                {
                  accessor: 'code',
                  title: 'Código',
                },
                {
                  accessor: 'username',
                  title: 'Criado por',
                },
                {
                  accessor: '',
                  title: 'Produto',
                  render: ({ product, productColor, productMaterial }) =>
                    `${product}, ${productColor}, ${productMaterial}`,
                },
                {
                  accessor: 'quantity',
                  title: 'Qtde',
                  width: 80,
                },
                {
                  accessor: 'quantityVolume',
                  title: 'Volumes',
                },
                {
                  accessor: 'status',
                  title: 'Status',
                  render: ({ status }) => (
                    <Badge color={orderMaterialStatusColor[status]}>
                      {orderMaterialStatusHumanized[status]}
                    </Badge>
                  ),
                },
              ]}
            />
            <Flex justify="flex-end" mb={48} mt={16}>
              <Button
                onClick={() => {
                  handleMaterialAssociate(formAssociate.values);
                }}
                disabled={!formAssociate.values.materialId}
                loading={materialAssociateLoader}
                color="blue"
                type="button"
              >
                Associar
              </Button>
            </Flex>
          </>
        )}
        <Flex justify="flex-end">
          <Button
            // disabled={
            //   formStatusChange.values.status ===
            //     OrderStatus.RELEASED_PRODUCTION &&
            //   associatedOrderMaterialList?.length === 0
            // }
            color="ltpBlue.9"
            type="submit"
          >
            Salvar
          </Button>
        </Flex>
      </form>
    </Modal>
  );
}
