import {
  Accordion,
  Alert,
  Badge,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  Menu,
  Modal,
  Paper,
  ScrollArea,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
  Textarea,
  ThemeIcon,
  Tooltip,
} from '@mantine/core';
import { UseFormReturnType, useForm } from '@mantine/form';
import {
  IconAlertCircle,
  IconDotsVertical,
  IconDownload,
  IconEye,
  IconFileCertificate,
  IconRefreshDot,
  IconTruckOff,
} from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useEffect, useMemo, useRef, useState } from 'react';

import { CustomDatePicker } from '../../components/CustomDatePicker';
import { CustomDateRangePicker } from '../../components/CustomDateRangePicker';
import { CustomLoader } from '../../components/CustomLoader';
import { ExportableModal } from '../../components/ExportableModal';
import { NfOrderItem } from '../../components/NfOrderItem';
import { NfPostalItem } from '../../components/NfPostalItem';
import { NfReceiptItem } from '../../components/NfReceiptItem';
import { OrderDetails } from '../../components/OrderDetails';
import { Page } from '../../components/Page';
import { TagInput } from '../../components/TagInput';
import { useGetCompanyDocuments } from '../../data/hooks/company-documents';
import { useInvoiceOrderListExportable } from '../../data/hooks/exportables';
import { useGetNfProviders } from '../../data/hooks/financial';
import { useGetReceiptsByOrder } from '../../data/hooks/order-receipts';
import { useGetPostalsByOrder } from '../../data/hooks/postals';
import {
  getFilterCompaniesListRequest,
  getFilterTeamsListRequest,
} from '../../data/services/filters';
import {
  emmitNFSeRequest,
  getOrderInvoicesRequest,
  setOrderInvoicedRequest,
} from '../../data/services/financial';
import { useFileDownload } from '../../hooks/use-file-download';
import { FinancialStatus, Order } from '../../models/order';
import { TeamType } from '../../models/team';
import { formatLocale } from '../../providers/dayjs-plugins';
import {
  errorNotification,
  successNotification,
} from '../../providers/mantine-notifications';
import { orderStatusColor, orderStatusHumanized } from '../../utils/constants';
import {
  financialStatusColor,
  financialStatusTypeHumanized,
  orderFinancialStatusList,
  orderPaymentTypeHumanized,
  orderPaymentTypeList,
  priceTypeHumanized,
} from '../../utils/constants/order';
import {
  OrderPricesData,
  formatBRL,
  getNFSEDiscrimination,
  getOrderPrices,
  realToUSCash,
  translateServerHttpErrors,
} from '../../utils/helpers';
import { PaginatedResponse } from '../../utils/types';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
} from '../../utils/types/data/services/filters';
import {
  MissingOrders,
  OrderPostal,
} from '../../utils/types/data/services/postals';

export type PageModalState =
  | 'view-nfse'
  | 'create-nfse'
  | 'payment'
  | 'create-multiple-nfse'
  | 'change-prices'
  | 'order-details'
  | 'exportable-invoice'
  | null;

export type FormFilterType = {
  status?: string | null;
  company?: string | null;
  os?: string[];
  team?: string | null;
  limit: number;
  page: number;
  paymentStatus?: string;
  paymentDeadline?: string;
  paymentType?: string;
  financialStatus?: string;
  financialNote?: string;
  createdAt?: string;
  receiptCode?: string;
  showRework?: boolean;
};

function RenderInvoiceFormFields({ form }: { form: UseFormReturnType<any> }) {
  return (
    <Grid.Col span={2}>
      <Textarea
        maxLength={255}
        autosize
        label="Obs Fiscais"
        placeholder="observações fiscais"
        name="financialNote"
        {...form.getInputProps('financialNote')}
      />
    </Grid.Col>
  );
}

function AlternativePriceList({
  prices,
  postalsTotalPrice,
}: {
  prices: OrderPricesData;
  postalsTotalPrice?: number;
}) {
  return (
    <Flex direction="column" mt={10}>
      <Text fw="bold">Valores do Pedido</Text>
      <Table highlightOnHover withBorder striped>
        <thead>
          <tr>
            <th>Nome</th>
            <th>Preço</th>
          </tr>
        </thead>
        <tbody>
          {prices &&
            Object.entries(prices).map(
              ([key, value]) =>
                priceTypeHumanized[key] && (
                  <tr key={`${key}-${value}`}>
                    <td>{priceTypeHumanized[key]}</td>
                    <td>{value ?? '--'}</td>
                  </tr>
                ),
            )}
          <tr>
            <td>Correio:</td>
            <td>
              {postalsTotalPrice
                ? formatBRL(postalsTotalPrice?.toFixed(2))
                : '--'}
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td>{prices.totalPrice ?? '--'}</td>
          </tr>
        </tbody>
      </Table>
    </Flex>
  );
}

export function FinancialInvoice() {
  const [companyList, setCompanyList] = useState<
    GetFilterCompaniesListResponse[]
  >([]);
  const [selectedOrdersToNf, setSelectedOrdersToNf] = useState<Order[]>([]);
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [pageModal, setPageModal] = useState<PageModalState>(null);
  const [pageLoading, setPageLoading] = useState<boolean>(false);
  const [postalIds, setPostalIds] = useState<number[]>([]);
  const [needNFSe, setNeedNFSe] = useState(false);
  const currentPage = useRef(1);
  const currentPageLimit = useRef(10);
  const [invoiceList, setInvoiceList] = useState<PaginatedResponse<Order>>();
  const [teamList, setTeamList] = useState<GetFilterTeamsListResponse[]>([]);
  const [missingOrders, setMissingOrders] = useState<MissingOrders[]>([]);
  const [orderPricesData, setOrderPricesData] = useState<OrderPricesData>();
  const {
    fetch: getCompanyDocumentsFetcher,
    reponseData: getCompanyDocumentsData,
    loading: getCompanyDocumentsLoader,
  } = useGetCompanyDocuments();
  const {
    fetch: getPostalsByOrderFetcher,
    loading: getPostalsByOrderLoader,
    response: getPostalsByOrderData,
  } = useGetPostalsByOrder();
  const { fetch: getNfProvidersFetcher, reponse: getNfProvidersResponse } =
    useGetNfProviders();
  const {
    fetch: invoiceOrderListExportFetcher,
    loading: invoiceOrderListExportLoader,
  } = useInvoiceOrderListExportable();
  const {
    fetch: getReceiptByOrderFetcher,
    loading: getReceiptByOrderLoader,
    response: getReceiptByOrderResponse,
  } = useGetReceiptsByOrder();
  const { download } = useFileDownload();

  const formFilter = useForm<FormFilterType>({
    initialValues: {
      limit: 10,
      page: 1,
      company: null,
      os: [],
      paymentDeadline: undefined,
      paymentStatus: undefined,
      paymentType: '',
      createdAt: undefined,
      team: '',
      financialNote: '',
      financialStatus: '',
      receiptCode: '',
      status: '',
      showRework: false,
    },
  });

  const formEmitNFSe = useForm({
    initialValues: {
      discrimination: '',
      taxesPercentage: '',
      serviceCode: '06912',
      orderPrice: '',
      financialNote: '',
      paymentDeadline: new Date(),
      companyDocumentId: null,
      emiterCNPJ: '',
    },
  });

  const orderWithPostal = useMemo(() => {
    const ids: number[] = [];
    let hasWithoutPostal = false;

    getPostalsByOrderData?.forEach((postalItem) => {
      postalItem.orders.forEach((orderItem) => {
        ids.push(orderItem.orderId);
      });
    });

    selectedOrdersToNf.forEach((orderItem) => {
      if (orderItem.needsPostal && !ids.includes(orderItem.id)) {
        hasWithoutPostal = true;
      }
    });

    return { ids, hasWithoutPostal };
  }, [getPostalsByOrderData]);

  const reworkOrders = useMemo(() => {
    let hasReworkOrder = false;

    selectedOrdersToNf.forEach((orderItem) => {
      if (orderItem.rework) {
        hasReworkOrder = true;
      }
    });

    return { hasReworkOrder };
  }, [selectedOrdersToNf.length]);

  function handleShowEmitMultipleNFSeModal() {
    if (
      selectedOrdersToNf.length &&
      selectedOrdersToNf[0].company.id !==
        selectedOrdersToNf[selectedOrdersToNf.length - 1].company.id
    ) {
      errorNotification({
        title: 'Não é possivel selecionar pedidos de clientes diferentes.',
        message: 'revise os dados',
      });

      return;
    }

    getCompanyDocs(selectedOrdersToNf[0].company.id);
    getPostals();
    getReceipts();
    setPageModal('create-multiple-nfse');
  }

  async function getPostals() {
    await getPostalsByOrderFetcher(selectedOrdersToNf.map((item) => item.id));
  }

  function verifyMissingOrdersOnPostal() {
    const selectedOrderOs: Set<string> = new Set(
      selectedOrdersToNf.flatMap((order) => order.os),
    );

    /* 
      DEBITO TECNICO
      código complexo com muitos loops encadeados melhorar futuramente 
    */
    if (getPostalsByOrderData) {
      getPostalsByOrderData.forEach((item) => {
        const foundMissingOrders = item.orders.filter(
          (order) => !selectedOrderOs.has(order.os),
        );
        if (foundMissingOrders.length > 0) {
          foundMissingOrders.forEach((order) => {
            if (
              !missingOrders.some((missingOrder) =>
                missingOrder.os.includes(order.os),
              )
            ) {
              setMissingOrders([
                ...missingOrders,
                {
                  postal: item.postal.code,
                  os: [order.os],
                },
              ]);
            }
          });
        }
      });
    }
  }

  function handleCalculatePostalTotalPrice() {
    if (getPostalsByOrderData) {
      return getPostalsByOrderData.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.postal.totalPrice),
        0.0,
      );
    }
  }

  function handleSubmit() {
    currentPage.current = 1;
    currentPageLimit.current = 10;
    getOrderInvoicesPaginated();
  }

  function handlePaginate(page: number) {
    currentPage.current = page;
    getOrderInvoicesPaginated();
  }

  function handleChangePageLimit(limit: number) {
    currentPageLimit.current = limit;
    getOrderInvoicesPaginated();
  }

  function handleClearFilter() {
    setSelectedOrdersToNf([]);
    formFilter.reset();
    getOrderInvoicesPaginated(true);
  }

  function handleSelectOrdersToNf(selOrder: Order[]) {
    if (!selOrder.length) {
      setSelectedOrdersToNf([]);
    }

    if (
      selectedOrdersToNf.length &&
      selectedOrdersToNf[0].company.id !==
        selOrder[selOrder.length - 1].company.id
    ) {
      errorNotification({
        title: 'Não é possivel selecionar pedidos de clientes diferentes.',
        message: 'revise os dados',
      });

      return;
    }
    setSelectedOrdersToNf(selOrder);
  }

  function setOrderToViewDetails(selOrder: Order) {
    setSelectedOrder(selOrder);
    setPageModal(`order-details`);
  }

  function renderMissingPostalsAlert(missingOrds: MissingOrders[]) {
    return (
      missingOrds.length >= 1 && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Atenção"
          color="red"
          mb={16}
        >
          Expanda o item de correio com o indicador e verifique se todos os
          pedidos estão selecionados.
        </Alert>
      )
    );
  }

  function renderOrderWithoutPostalAlert(render: boolean) {
    return (
      render && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Atenção"
          color="red"
          mb={16}
        >
          Um ou mais pedidos selecionados para faturar precisam de correio mas
          não foi enviado, comunique o vendedor.
        </Alert>
      )
    );
  }

  function renderReworkOrderAlert(render: boolean) {
    return (
      render && (
        <Alert
          icon={<IconAlertCircle size={16} />}
          title="Atenção"
          color="yellow"
          mb={16}
        >
          Um ou mais pedidos selecionados para faturar são retrabalho, favor
          desconsiderar cobranças.
        </Alert>
      )
    );
  }

  function renderPostalItems(
    data: OrderPostal[] | undefined,
    missingOrds: MissingOrders[],
  ) {
    return data?.length ? (
      <Accordion
        variant="separated"
        mt={16}
        styles={{
          item: {
            background: '#f2cc0c16',
            '&[data-active]': {
              background: '#f2cc0c16',
            },
          },
        }}
      >
        <NfPostalItem postals={data} missingOrders={missingOrds} />
      </Accordion>
    ) : (
      <Text fw="bold" ta="center" align="center" mt={16}>
        <Flex justify="center" align="center">
          <IconTruckOff /> Sem correios encontrados
        </Flex>
      </Text>
    );
  }

  function handleCloseInvoiceModal() {
    setSelectedOrdersToNf([]);
    setNeedNFSe(false);
    setPageModal(null);
  }

  async function getVendorTeams() {
    try {
      setPageLoading(true);
      const response = await getFilterTeamsListRequest({
        type: TeamType.VENDOR,
      });
      setTeamList(response.data);
      setPageLoading(false);
    } catch (error) {
      setPageLoading(true);
      errorNotification({
        title: 'Erro ao buscar equipes.',
        message: 'tente novamente',
      });
    }
  }

  async function getCompanies() {
    try {
      const response = await getFilterCompaniesListRequest({});
      setCompanyList(response);
    } catch (error) {
      errorNotification({
        title: 'Erro ao buscar clientes',
        message: 'tente novamente!',
      });
    }
  }

  async function getOrderInvoicesPaginated(isReset = false) {
    const validatedFilter: any = {};

    Object.entries(formFilter.values).forEach(([key, val]) => {
      if (val !== '' && val !== null && val !== undefined) {
        validatedFilter[key] = val;
      }
    });

    try {
      setPageLoading(true);
      if (isReset) {
        const result = await getOrderInvoicesRequest({
          page: 1,
          limit: 10,
        });
        setInvoiceList(result);
      } else {
        const result = await getOrderInvoicesRequest({
          ...validatedFilter,
          page: currentPage.current,
          limit: currentPageLimit.current,
          os: validatedFilter.os.length
            ? validatedFilter.os.join(',')
            : undefined,
        });
        setInvoiceList(result);
      }
      setPageLoading(false);
    } catch (error) {
      setPageLoading(false);
    }
  }

  async function handleEmmitMultipleNFSe(values: typeof formEmitNFSe.values) {
    if (selectedOrdersToNf.length) {
      if (missingOrders.length >= 1) {
        missingOrders.forEach((item) => {
          errorNotification({
            title: 'Erro ao emitir nota.',
            message: 'Verifique o correio: ' + item.postal,
          });
        });

        return;
      }

      if (needNFSe) {
        try {
          setPageLoading(true);
          await emmitNFSeRequest({
            ...values,
            taxesPercentage: Number(values.taxesPercentage),
            orderPrice: Number(realToUSCash(values.orderPrice)),
            orders: selectedOrdersToNf.map((orderItem) => orderItem.id),
            companyDocumentId: Number(values.companyDocumentId),
            postals: postalIds.length ? postalIds : undefined,
          });

          setPageLoading(false);
          successNotification({
            title: 'Nota em processamento!',
            message: 'Em breve você receberá uma confimação.',
          });
          handleCloseInvoiceModal();
          getOrderInvoicesPaginated();
        } catch (error: any) {
          setPageLoading(false);
          errorNotification({
            title: 'Erro ao emitir nota.',
            message: translateServerHttpErrors(error, 'Tente novamente'),
          });
        }
      } else {
        try {
          setPageLoading(true);
          await setOrderInvoicedRequest({
            financialNote: values.financialNote,
            paymentDeadline: values.paymentDeadline,
            orderIds: selectedOrdersToNf.map((orderItem) => orderItem.id),
            postals: postalIds.length ? postalIds : undefined,
          });
          setPageLoading(false);
          handleCloseInvoiceModal();
          getOrderInvoicesPaginated();
        } catch (error: any) {
          setPageLoading(false);
          errorNotification({
            title: 'Erro ao faturar pedido',
            message: translateServerHttpErrors(error, 'Tente novamente'),
          });
        }
      }
    }
  }

  async function getCompanyDocs(companyId: number) {
    await getCompanyDocumentsFetcher({ companyId });
  }

  async function handleRequestInvoiceOrderListExport() {
    if (selectedOrdersToNf.length) {
      invoiceOrderListExportFetcher({
        query: {
          os: selectedOrdersToNf.map((item) => item.os).join(','),
        },
        onSuccess: (data) => {
          setPageModal('exportable-invoice');
          download(data, 'pedidos-faturamento.xlsx');
        },
      });
    }
  }

  async function getReceipts() {
    await getReceiptByOrderFetcher({
      query: {
        orderIds: selectedOrdersToNf.map((orderItem) => orderItem.id).join(','),
      },
    });
  }

  useEffect(() => {
    verifyMissingOrdersOnPostal();
  }, [getPostalsByOrderData?.at(0)?.orders]);

  useEffect(() => {
    let totalPrice = 0;
    let discriminationArr: string[] = [];
    const postalIds: number[] = [];

    for (const iterator of selectedOrdersToNf) {
      const orderPrice = getOrderPrices(iterator);
      const discriminationGenArr = getNFSEDiscrimination(iterator, orderPrice);
      discriminationGenArr.splice(discriminationGenArr.length - 1, 1);

      if (!discriminationArr.length) {
        discriminationArr = discriminationGenArr;
      } else {
        discriminationGenArr.splice(0, 1);
        discriminationArr = [...discriminationArr, ...discriminationGenArr];
      }

      totalPrice += Number(realToUSCash(orderPrice.totalPrice));

      formEmitNFSe.setValues({
        discrimination: getNFSEDiscrimination(iterator, orderPrice).join('\n'),
        financialNote: iterator.financialNote,
      });
    }

    if (getPostalsByOrderData?.length) {
      const postalTotalPrice = handleCalculatePostalTotalPrice() ?? 0;
      totalPrice += postalTotalPrice;
      discriminationArr.push(
        `\n${getPostalsByOrderData.length} Correios ${formatBRL(
          postalTotalPrice.toFixed(2),
        )}`,
      );

      getPostalsByOrderData.forEach((item) => {
        if (!postalIds.some((postItem) => postItem === item.postal.id)) {
          postalIds.push(item.postal.id);
        }
      });

      setPostalIds(postalIds);
    }

    discriminationArr.push(
      `\nCARGA TRIBUTÁRIA CONFORME LEI 12.471/2012 ${formatBRL(
        totalPrice.toFixed(2),
      )}`,
    );
    formEmitNFSe.setFieldValue('orderPrice', formatBRL(totalPrice.toFixed(2)));
    formEmitNFSe.setFieldValue('discrimination', discriminationArr.join('\n'));
  }, [selectedOrdersToNf.length, getPostalsByOrderData?.length]);

  useEffect(() => {
    getCompanies();
    getOrderInvoicesPaginated();
    getVendorTeams();
    getNfProvidersFetcher();
  }, []);

  useEffect(() => {
    const pricesData = {
      samplePrice: 0,
      photolitoPrice: 0,
      manipulationPrice: 0,
      vectorizationPrice: 0,
      clichePrice: 0,
      totalPrice: 0,
      unit: 0,
      price: 0,
      changeColorPriceUnit: 0,
      changeColorTotalPrice: 0,
      disccount: 0,
      originalPrice: 0,
    };

    if (selectedOrdersToNf.length) {
      selectedOrdersToNf.forEach((item) => {
        const data = getOrderPrices(item);

        pricesData.photolitoPrice += Number(
          realToUSCash(data.photolitoPrice ?? ''),
        );
        pricesData.manipulationPrice += Number(
          realToUSCash(data.manipulationPrice ?? ''),
        );
        pricesData.vectorizationPrice += Number(
          realToUSCash(data.vectorizationPrice ?? ''),
        );
        pricesData.clichePrice += Number(realToUSCash(data.clichePrice ?? ''));
        pricesData.totalPrice += Number(realToUSCash(data.totalPrice ?? ''));
        pricesData.unit += Number(realToUSCash(data.unit ?? ''));
        pricesData.price += Number(realToUSCash(data.price ?? ''));
        pricesData.changeColorTotalPrice += Number(
          realToUSCash(data.changeColorTotalPrice ?? ''),
        );
        pricesData.samplePrice += Number(realToUSCash(data.samplePrice ?? ''));
        pricesData.disccount += Number(realToUSCash(data.discountPrice ?? ''));
      });

      const postalTotalPrice = handleCalculatePostalTotalPrice() ?? 0;

      pricesData.totalPrice += postalTotalPrice;

      setOrderPricesData({
        samplePrice: formatBRL(pricesData.samplePrice.toFixed(2)),
        photolitoPrice: formatBRL(pricesData.photolitoPrice.toFixed(2)),
        manipulationPrice: formatBRL(pricesData.manipulationPrice.toFixed(2)),
        vectorizationPrice: formatBRL(pricesData.vectorizationPrice.toFixed(2)),
        clichePrice: formatBRL(pricesData.clichePrice.toFixed(2)),
        totalPrice: formatBRL(pricesData.totalPrice.toFixed(2)),
        unit: formatBRL(pricesData.unit.toFixed(2)),
        price: formatBRL(pricesData.price.toFixed(2)),
        changeColorTotalPrice: formatBRL(
          pricesData.changeColorTotalPrice.toFixed(2),
        ),
        changeColorPriceUnit: formatBRL(
          pricesData.changeColorPriceUnit.toFixed(2),
        ),
        discountPrice: formatBRL(pricesData.disccount.toFixed(2)),
        originalPrice: formatBRL(pricesData.originalPrice.toFixed(2)),
      });
    }
  }, [selectedOrdersToNf.length, getPostalsByOrderData?.length]);

  return (
    <Page title="Faturamento">
      <CustomLoader
        loading={
          pageLoading ||
          getCompanyDocumentsLoader ||
          getPostalsByOrderLoader ||
          getReceiptByOrderLoader
        }
      />
      <Flex direction="column" w="99%">
        <Group position="right">
          <Button
            disabled={!selectedOrdersToNf.length}
            variant="outline"
            color="blue"
            rightIcon={<IconFileCertificate size={14} />}
            onClick={handleShowEmitMultipleNFSeModal}
          >
            Faturar Multiplos
          </Button>
        </Group>
        <form onSubmit={formFilter.onSubmit(() => handleSubmit())}>
          <Paper p={16} mb={16} mt={16} withBorder>
            <Flex wrap="wrap">
              <TagInput
                label="OS"
                placeholder="digite OS separadas por virgula"
                handleChange={(val) => formFilter.setFieldValue('os', val)}
                data={formFilter.values.os}
                mb={16}
                mr={8}
                w={250}
                {...formFilter.getInputProps('os')}
              />
              <Select
                clearable
                searchable
                name="team"
                label="Equipe"
                placeholder="selecione a equipe"
                data={teamList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('team')}
              />
              <Select
                clearable
                searchable
                name="company"
                label="Clientes"
                placeholder="selecione o cliente"
                data={companyList?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('company')}
              />
              <Select
                required
                name="paymentType"
                withAsterisk
                label="Forma de pagamento"
                placeholder="selecione uma forma de pagamento"
                data={orderPaymentTypeList}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('paymentType')}
              />
              <Select
                clearable
                name="financialStatus"
                label="Status Fiscal"
                placeholder="selecione o status de fiscal"
                data={orderFinancialStatusList.filter(
                  (item) => item.value !== FinancialStatus.INVOICED,
                )}
                mb={16}
                mr={8}
                {...formFilter.getInputProps('financialStatus')}
              />
              <TextInput
                label="Obs Fiscal"
                placeholder="digite algo"
                mb={16}
                mr={8}
                type="text"
                {...formFilter.getInputProps('financialNote')}
              />
              <TextInput
                label="Nº recibo"
                placeholder="digite algo"
                mb={16}
                mr={8}
                type="text"
                {...formFilter.getInputProps('receiptCode')}
              />
              <CustomDatePicker
                label="Prazo de Pagamento"
                placeholder="selecione uma data"
                name="paymentDeadline"
                mb={16}
                mr={8}
                {...formFilter.getInputProps('paymentDeadline')}
              />
              <CustomDateRangePicker
                allowSingleDateInRange
                label="Criado em"
                placeholder="selecione um intervalo"
                mr={8}
                mb={16}
                {...formFilter.getInputProps('createdAt')}
              />
            </Flex>
            <Group>
              <Button
                mt={25}
                color="ltpBlue.9"
                type="button"
                variant="outline"
                onClick={handleClearFilter}
              >
                Limpar
              </Button>
              <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
                Filtrar
              </Button>
              <Checkbox
                mt={30}
                label="Mostrar retrabalhos"
                checked={formFilter.values.showRework}
                {...formFilter.getInputProps('showRework')}
              />
            </Group>
          </Paper>
        </form>
        <DataTable
          recordsPerPageLabel="Itens por página"
          recordsPerPageOptions={[10, 50, 100, 500]}
          onRecordsPerPageChange={(recordRange) =>
            handleChangePageLimit(recordRange)
          }
          onSelectedRecordsChange={(selOrder) =>
            handleSelectOrdersToNf(selOrder)
          }
          selectedRecords={selectedOrdersToNf}
          minHeight={500}
          noRecordsText="Sem pedidos"
          withBorder
          borderRadius="sm"
          striped
          highlightOnHover
          page={currentPage.current}
          totalRecords={invoiceList?.meta?.totalItems}
          recordsPerPage={currentPageLimit.current}
          onPageChange={handlePaginate}
          records={invoiceList?.items}
          rowStyle={(record) =>
            record.rework ? { backgroundColor: 'rgba(255, 17, 0, 0.027)' } : {}
          }
          columns={[
            {
              accessor: 'rework',
              title: '',
              render: ({ rework }) =>
                rework ? (
                  <Tooltip color="grape" label="retrabalho">
                    <ThemeIcon color="grape" variant="light">
                      <IconRefreshDot size={24} />
                    </ThemeIcon>
                  </Tooltip>
                ) : (
                  ''
                ),
            },
            {
              accessor: 'os',
              title: 'OS',
            },
            {
              accessor: 'company.name',
              title: 'Cliente',
            },
            {
              accessor: 'team.name',
              title: 'Equipe',
            },
            {
              accessor: 'status',
              title: 'Status',
              render: ({ status }) => (
                <Badge color={orderStatusColor[status]} mb={6}>
                  {orderStatusHumanized[status]}
                </Badge>
              ),
            },
            {
              accessor: 'needsPostal',
              title: 'Correio',
              render: ({ needsPostal }) => (
                <Badge color={needsPostal ? 'green' : 'red'} mb={6}>
                  {needsPostal ? 'sim' : 'não'}
                </Badge>
              ),
            },
            {
              accessor: 'quantity',
              title: 'Qtde',
              width: 60,
            },
            {
              accessor: 'product',
              title: 'Produto',
            },
            {
              accessor: 'financialStatus',
              title: 'Status Fiscal',
              render: ({ financialStatus }) =>
                financialStatus && (
                  <Badge color={financialStatusColor[financialStatus]} mb={6}>
                    {financialStatusTypeHumanized[financialStatus]}
                  </Badge>
                ),
            },
            {
              accessor: 'issueDate',
              title: 'Criado em',
              render: ({ createdAt }) =>
                createdAt && formatLocale(createdAt, 'DD/MM/YY'),
              width: 110,
            },
            {
              accessor: 'totalprice',
              title: 'Preço total',
              render: (order) =>
                `${getOrderPrices(order).totalPrice} (${
                  getOrderPrices(order).unit
                })`,
              width: 110,
            },
            {
              accessor: 'paymentType',
              title: 'Forma de Pagamento',
              render: ({ paymentType }) =>
                paymentType && orderPaymentTypeHumanized[paymentType],
              width: 110,
            },
            {
              accessor: 'financialNote',
              title: 'Obs Fiscal',
              width: 220,
            },
            {
              accessor: 'receiptCode',
              title: 'N° Recibo',
              width: 220,
            },
            {
              accessor: 'job',
              title: 'JOB',
            },
            {
              accessor: 'financial-menu',
              title: '',
              render: (orderItem) => (
                <Menu>
                  <Menu.Target>
                    <Button color="blue" variant="subtle" w={40} p={0}>
                      <IconDotsVertical />
                    </Button>
                  </Menu.Target>
                  <Menu.Dropdown style={{ position: 'absolute' }}>
                    <Menu.Item
                      onClick={() => setOrderToViewDetails(orderItem)}
                      icon={<IconEye size={14} />}
                    >
                      Ver
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              ),
            },
          ]}
        />
      </Flex>
      <Modal
        size={700}
        opened={pageModal === 'create-multiple-nfse'}
        onClose={() => handleCloseInvoiceModal()}
        title="Faturamento"
      >
        <Group mb={16} position="right">
          <Button
            disabled={
              missingOrders.length >= 1 || orderWithPostal.hasWithoutPostal
            }
            variant="subtle"
            color="orange"
            leftIcon={<IconDownload />}
            onClick={handleRequestInvoiceOrderListExport}
          >
            Exportar
          </Button>
        </Group>
        {renderReworkOrderAlert(reworkOrders.hasReworkOrder)}
        {renderOrderWithoutPostalAlert(orderWithPostal.hasWithoutPostal)}
        {renderMissingPostalsAlert(missingOrders)}
        <Accordion
          variant="separated"
          styles={{
            item: {
              '&[data-active]': {
                background: '#f7f7f7',
              },
            },
          }}
        >
          <NfOrderItem
            order={selectedOrdersToNf}
            postalOrderIds={orderWithPostal.ids}
          />
        </Accordion>
        {getPostalsByOrderLoader && (
          <Skeleton height={55} radius="md" mt={16} />
        )}
        {renderPostalItems(getPostalsByOrderData, missingOrders)}
        <NfReceiptItem data={getReceiptByOrderResponse ?? []} />
        {orderPricesData && (
          <AlternativePriceList
            prices={orderPricesData}
            postalsTotalPrice={handleCalculatePostalTotalPrice()}
          />
        )}
        <Text mt={16} color="dark">
          imprimir documentos ?
        </Text>
        <Group>
          <Button color="green" onClick={() => setNeedNFSe(true)}>
            Sim
          </Button>
          <Button color="red" onClick={() => setNeedNFSe(false)}>
            Não
          </Button>
        </Group>
        {needNFSe ? (
          <form onSubmit={formEmitNFSe.onSubmit(handleEmmitMultipleNFSe)}>
            <Grid gutter="xs" columns={2} mt={8}>
              <Grid.Col span={2}>
                <Select
                  withAsterisk
                  required
                  clearable
                  searchable
                  name="emiterCNPJ"
                  label="Empresa Prestadora"
                  placeholder="selecione o CNPJ do prestador"
                  data={getNfProvidersResponse?.map((item) => ({
                    label: `${item.name}`,
                    value: item.value,
                  }))}
                  mb={16}
                  mr={8}
                  {...formEmitNFSe.getInputProps('emiterCNPJ')}
                />
                <Divider />
              </Grid.Col>
              <Grid.Col span={2}>
                <Select
                  withAsterisk
                  required
                  clearable
                  searchable
                  name="company"
                  label="CNPJ do Tomador"
                  placeholder="selecione o CNPJ para faturar"
                  data={getCompanyDocumentsData?.map((item) => ({
                    label: `${item.nomeFantasia} (${item.document})`,
                    value: item.id,
                  }))}
                  mb={16}
                  mr={8}
                  {...formEmitNFSe.getInputProps('companyDocumentId')}
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Text fw="bold" color="gray">
                  Campos Fiscais
                </Text>
              </Grid.Col>
              <Grid.Col span={1}>
                <TextInput
                  required
                  withAsterisk
                  label="Percentual Carga Tributária"
                  placeholder="ex: 7.50"
                  mb={16}
                  type="number"
                  name="taxesPercentage"
                  {...formEmitNFSe.getInputProps('taxesPercentage')}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <TextInput
                  required
                  withAsterisk
                  label="Código do Serviço"
                  placeholder="ex: 01565"
                  mb={16}
                  type="text"
                  name="serviceCode"
                  {...formEmitNFSe.getInputProps('serviceCode')}
                />
              </Grid.Col>
              <Grid.Col span={1}>
                <TextInput
                  required
                  withAsterisk
                  label="Valor Total do Serviço"
                  placeholder="ex: R$152,00"
                  mb={16}
                  type="text"
                  name="orderPrice"
                  value={formEmitNFSe.values.orderPrice}
                  onChange={(e) =>
                    formEmitNFSe.setFieldValue(
                      'orderPrice',
                      formatBRL(e.target.value),
                    )
                  }
                />
              </Grid.Col>
              <Grid.Col span={2}>
                <Textarea
                  required
                  withAsterisk
                  autosize
                  label="Discriminação"
                  placeholder="discriminação do serviço"
                  name="discrimination"
                  value={formEmitNFSe.values.discrimination}
                  onChange={(e) =>
                    formEmitNFSe.setFieldValue('discrimination', e.target.value)
                  }
                />
              </Grid.Col>
              <RenderInvoiceFormFields form={formEmitNFSe} />
            </Grid>
            <Flex justify="flex-end" mt={16}>
              <Button
                disabled={
                  missingOrders.length >= 1 || orderWithPostal.hasWithoutPostal
                }
                color="dark"
                type="submit"
              >
                Salvar
              </Button>
            </Flex>
          </form>
        ) : (
          <form onSubmit={formEmitNFSe.onSubmit(handleEmmitMultipleNFSe)}>
            <Grid gutter="xs" columns={2} mt={8} mb={16}>
              <RenderInvoiceFormFields form={formEmitNFSe} />
            </Grid>
            <Group position="right">
              <Button
                color="dark"
                type="submit"
                disabled={
                  missingOrders.length >= 1 || orderWithPostal.hasWithoutPostal
                }
              >
                Salvar
              </Button>
            </Group>
          </form>
        )}
      </Modal>
      <Modal
        opened={pageModal === 'order-details'}
        onClose={() => setPageModal(null)}
        title={
          <Text fw="bold">
            Detalhes do Pedido{' '}
            {selectedOrder?.rework && (
              <ThemeIcon color="grape" variant="light">
                <IconRefreshDot size={24} />
              </ThemeIcon>
            )}
          </Text>
        }
        size="lg"
      >
        <ScrollArea h={600}>
          {selectedOrder && (
            <OrderDetails
              sections={[
                'basic',
                'prices',
                'service-details',
                'historic',
                'dates',
                'order-receipt-files',
              ]}
              orderId={selectedOrder?.id}
            />
          )}
        </ScrollArea>
      </Modal>
      <ExportableModal
        title="Exportável de Faturamento"
        loading={invoiceOrderListExportLoader}
        opened={pageModal === 'exportable-invoice'}
        onClose={() => setPageModal(null)}
      />
    </Page>
  );
}
