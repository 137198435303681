import {
  ActionIcon,
  Button,
  Collapse,
  Flex,
  Menu,
  Select,
  Text,
  TextInput,
} from '@mantine/core';
import { UseFormReturnType } from '@mantine/form';
import { IconCheck, IconDotsVertical, IconEdit, IconPlus } from '@tabler/icons';
import { DataTable } from 'mantine-datatable';
import { useState } from 'react';
import { TeamOptionPercent, TeamServicePercent } from '../../models/team';
import { FormAddPercentage } from '../../pages/TeamEdit';

interface AddTeamConfigPercentageItemProps {
  title: string;
  itemList: any[];
  form: UseFormReturnType<any>;
  handleSubmit(form: FormAddPercentage): void;
  loading: boolean;
  canAdd?: boolean;
}

interface TeamServicePercentageList {
  form: UseFormReturnType<FormAddPercentage>;
  itemList: TeamServicePercent[];
  loading: boolean;
  handleSubmitChangePercentage: (form: FormAddPercentage) => void;
}

interface TeamOptionPercentageList {
  form: UseFormReturnType<FormAddPercentage>;
  itemList: TeamOptionPercent[];
  loading: boolean;
  handleSubmitChangePercentage: (form: FormAddPercentage) => void;
}

export function AddTeamConfigPercentageItem({
  title,
  itemList,
  form,
  handleSubmit,
  loading,
  canAdd,
}: AddTeamConfigPercentageItemProps) {
  const [addPercentageCollapse, setAddPercentageCollapse] =
    useState<boolean>(false);
  return (
    <>
      <Flex justify={'space-between'} align={'center'}>
        <Text size={14} color="dark.6" fw={'bold'}>
          {title}
        </Text>
        {canAdd ? (
          <Button
            variant="light"
            onClick={() => {
              form.reset();
              setAddPercentageCollapse((o) => !o);
            }}
            compact
          >
            <IconPlus />
          </Button>
        ) : (
          ''
        )}
      </Flex>

      <Collapse in={addPercentageCollapse} mt={16}>
        <Flex direction={'column'} align={'center'}>
          <Flex gap={10} align={'center'} justify={'center'} mb={'md'}>
            <Select
              data={itemList?.map((item) => ({
                label: item.name,
                value: `${item.name},${String(item.id)}`,
              }))}
              placeholder="selecione o serviço"
              {...form.getInputProps('item')}
            />
            <TextInput
              placeholder="% porcentagem"
              type="number"
              name="percentage"
              {...form.getInputProps('percentage')}
            />
          </Flex>
          <Button
            maw={'120px'}
            onClick={() => {
              setAddPercentageCollapse(false);
              handleSubmit(form.values);
            }}
            variant="light"
            h={30}
            loading={loading}
          >
            Adicionar
          </Button>
        </Flex>
      </Collapse>
    </>
  );
}

export function TeamServicePercentageList({
  form,
  itemList,
  loading,
  handleSubmitChangePercentage,
}: TeamServicePercentageList) {
  const [selectedToEdit, setSelectedToEdit] = useState<TeamServicePercent>();

  function handleSetPercentageToEdit(item: TeamServicePercent) {
    setSelectedToEdit(item);
    form.setValues({
      item: String(item.serviceId),
      percentage: item.percentage,
    });
  }

  return (
    <DataTable
      mt={'sm'}
      records={itemList}
      fetching={loading}
      loaderVariant="oval"
      loaderBackgroundBlur={2}
      noRecordsText="Sem itens"
      minHeight={150}
      columns={[
        {
          accessor: 'service',
          title: 'Nome',
          width: '50%',
        },
        {
          accessor: 'percentage',
          title: '% porcentagem',
          width: '50%',
          render: ({ serviceId, percentage }) =>
            selectedToEdit?.serviceId === serviceId ? (
              <TextInput
                required
                withAsterisk
                placeholder="% porcentagem"
                type="text"
                name="percentage"
                {...form.getInputProps('percentage')}
              />
            ) : (
              percentage
            ),
        },
        {
          accessor: '',
          width: '10%',
          render: (item) =>
            selectedToEdit?.serviceId === item.serviceId ? (
              <ActionIcon
                variant="subtle"
                size={'md'}
                color={'green'}
                onClick={() => {
                  setSelectedToEdit(undefined);
                  handleSubmitChangePercentage(form.values);
                }}
              >
                <IconCheck />
              </ActionIcon>
            ) : (
              <Menu position="left">
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => handleSetPercentageToEdit(item)}
                    icon={<IconEdit size={14} />}
                  >
                    Editar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
        },
      ]}
    />
  );
}

export function TeamOptionPercentageList({
  form,
  itemList,
  loading,
  handleSubmitChangePercentage,
}: TeamOptionPercentageList) {
  const [selectedToEdit, setSelectedToEdit] = useState<TeamOptionPercent>();

  function handleSetPercentageToEdit(item: TeamOptionPercent) {
    setSelectedToEdit(item);
    form.setValues({
      item: String(item.optionId),
      percentage: item.percentage,
    });
  }

  return (
    <DataTable
      mt={'sm'}
      records={itemList}
      fetching={loading}
      loaderVariant="oval"
      loaderBackgroundBlur={2}
      noRecordsText="Sem itens"
      minHeight={150}
      columns={[
        {
          accessor: 'option',
          title: 'Nome',
          width: '50%',
        },
        {
          accessor: 'percentage',
          title: '% porcentagem',
          width: '50%',
          render: ({ optionId, percentage }) =>
            selectedToEdit?.optionId === optionId ? (
              <TextInput
                required
                withAsterisk
                placeholder="% porcentagem"
                type="text"
                name="percentage"
                {...form.getInputProps('percentage')}
              />
            ) : (
              percentage
            ),
        },
        {
          accessor: '',
          width: '10%',
          render: (item) =>
            selectedToEdit?.optionId === item.optionId ? (
              <ActionIcon
                variant="subtle"
                size={'md'}
                color={'green'}
                onClick={() => {
                  setSelectedToEdit(undefined);
                  handleSubmitChangePercentage(form.values);
                }}
              >
                <IconCheck />
              </ActionIcon>
            ) : (
              <Menu position="left">
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    onClick={() => handleSetPercentageToEdit(item)}
                    icon={<IconEdit size={14} />}
                  >
                    Editar
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
        },
      ]}
    />
  );
}
