import { useFetch } from '../../../hooks/use-fetch';
import {
  ActivityLayout,
  ActivityLayoutByOrder,
  ActivityLayoutHistoryResponse,
} from '../../../models/activity-layout';
import { ActivityLayoutFile } from '../../../models/activity-layout-file';
import { errorNotification } from '../../../providers/mantine-notifications';
import { translateServerHttpErrors } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';
import {
  UseAddActivityLayoutFileProps,
  UseClientApproveActivityLayoutProps,
  UseCreateActivityLayoutProps,
  UseGetActivityLayoutByOrderProps,
  UseGetActivityLayoutFileProps,
  UseGetActivityLayoutFilesProps,
  UseGetActivityLayoutHistoryProps,
  UseGetActivityLayoutProps,
  UseGetActivityLayoutToApproveProps,
  UseRequestClientApprovalActivityLayoutProps,
  UseVendorApproveActivityLayoutProps,
} from '../../../utils/types/data/hooks/activity-layouts';
import { ActivityLayoutsToApproveResponse } from '../../../utils/types/data/services/activity-layouts';
import {
  addActivityLayoutFilesRequest,
  clientApproveActivityLayoutRequest,
  createActivityLayoutRequest,
  getActivityLayoutFilesRequest,
  getActivityLayoutsByOrderRequest,
  getActivityLayoutsFilesByOrderHistoryRequest,
  getActivityLayoutsHistoryRequest,
  getActivityLayoutsRequest,
  getActivityLayoutToApproveRequest,
  getActivityPausedRequest,
  requestClientApprovalActivityLayoutRequest,
  vendorApproveActivityLayoutRequest,
} from '../../services/activity-layouts';

export function useCreateActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({ activityId, onSuccess }: UseCreateActivityLayoutProps) =>
    fetchData({
      fetcher: () => createActivityLayoutRequest(activityId),
      onError: () => {
        errorNotification({
          title: 'Erro ao criar layout.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useVendorApproveActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({
    data,
    id,
    onSuccess,
  }: UseVendorApproveActivityLayoutProps) =>
    fetchData({
      fetcher: () => vendorApproveActivityLayoutRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao aprovar layout.',
          message: translateServerHttpErrors(error.message, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useRequestClientApprovalActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({
    id,
    onSuccess,
  }: UseRequestClientApprovalActivityLayoutProps) =>
    fetchData({
      fetcher: () => requestClientApprovalActivityLayoutRequest(id),
      onError: () => {
        errorNotification({
          title: 'Erro ao solicitar aprovação layout.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useClientApproveActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout>();

  const fetch = ({
    data,
    id,
    onSuccess,
  }: UseClientApproveActivityLayoutProps) =>
    fetchData({
      fetcher: () => clientApproveActivityLayoutRequest(id, data),
      onError: (error: any) => {
        errorNotification({
          title: 'Erro ao aprovar layout.',
          message: translateServerHttpErrors(error, 'tente novamente.'),
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayout() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayout[]>();

  const fetch = ({ activityId }: UseGetActivityLayoutProps) =>
    fetchData({
      fetcher: () => getActivityLayoutsRequest(activityId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useAddActivityLayoutFile() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<void>();

  const fetch = ({ id, data, onSuccess }: UseAddActivityLayoutFileProps) =>
    fetchData({
      fetcher: () => addActivityLayoutFilesRequest(id, data),
      onError: () => {
        errorNotification({
          title: 'Erro ao adicionar aquivos.',
          message: 'tente novamente.',
        });
      },
      onSuccess,
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutFile() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayoutFile[]>();

  const fetch = ({ query }: UseGetActivityLayoutFileProps) =>
    fetchData({
      fetcher: () => getActivityLayoutFilesRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutToApprove() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<ActivityLayoutsToApproveResponse>>();

  const fetch = ({ query }: UseGetActivityLayoutToApproveProps) =>
    fetchData({
      fetcher: () => getActivityLayoutToApproveRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutByOrder() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayoutByOrder>();

  const fetch = ({ orderId }: UseGetActivityLayoutByOrderProps) =>
    fetchData({
      fetcher: () => getActivityLayoutsByOrderRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar layouts.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutFilesHistoric() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayoutFile[]>();

  const fetch = ({ layoutId }: UseGetActivityLayoutFilesProps) =>
    fetchData({
      fetcher: () => getActivityLayoutsFilesByOrderHistoryRequest(layoutId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar arquivos do histórico.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityLayoutHistory() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<ActivityLayoutHistoryResponse[]>();

  const fetch = ({ orderId }: UseGetActivityLayoutHistoryProps) =>
    fetchData({
      fetcher: () => getActivityLayoutsHistoryRequest(orderId),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar histórico layouts.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}

export function useGetActivityPaused() {
  const {
    fetchData,
    state: { data: reponseData, error, loading },
  } = useFetch<PaginatedResponse<ActivityLayoutsToApproveResponse>>();

  const fetch = ({ query }: UseGetActivityLayoutToApproveProps) =>
    fetchData({
      fetcher: () => getActivityPausedRequest(query),
      onError: () => {
        errorNotification({
          title: 'Erro ao buscar atividades pausadas.',
          message: 'tente novamente.',
        });
      },
    });

  return {
    fetch,
    reponseData,
    error,
    loading,
  };
}
