import { AxiosResponse } from 'axios';
import {
  CompanyBalance,
  CompanyBalanceCredit,
} from '../../models/company-balance';
import { api } from '../../providers/base-api';
import { PaginatedResponse } from '../../utils/types';
import {
  ApprovalCompanyBalanceRequestData,
  CreateCompanyBalanceRequestData,
} from '../../utils/types/data/services/company-balance';

export type GetCompanyBalancePaginatedQuery = {
  page: number;
  limit: number;
  company?: number;
  order?: number;
  team?: string;
};

export async function createCompanyBalanceRequest(
  companyId: number,
  data: CreateCompanyBalanceRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`company-balances/${companyId}`, data);

  return response;
}

export async function approvalCompanyBalanceRequest(
  id: number,
  data: ApprovalCompanyBalanceRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.put<void>(`company-balances/${id}/approval`, data);

  return response;
}

export async function getCompanyCreditRequest(
  companyId: number,
): Promise<AxiosResponse<CompanyBalanceCredit>> {
  const response = await api.get<CompanyBalanceCredit>(
    `companies/${companyId}/credit`,
  );

  return response;
}

export async function getCompanyBalancePaginatedRequest(
  query: GetCompanyBalancePaginatedQuery,
): Promise<AxiosResponse<PaginatedResponse<CompanyBalance>>> {
  const response = await api.get<PaginatedResponse<CompanyBalance>>(
    `company-balances`,
    {
      params: query,
    },
  );

  return response;
}
