import { AxiosResponse } from 'axios';
import { Ticket } from '../../models/ticket';
import { api } from '../../providers/base-api';
import {
  CreateTicketRequestData,
  RateTicketData,
  TicketPaginatedParams,
  TicketPaginatedResponse,
  UpdateTicketStatusRequestData,
} from '../../utils/types/data/services/tickets';

export type GetTicketsPaginatedQuery = {
  page: number;
  limit: number;
};

export type CreateTicketData = {
  assignedUser?: string;
  needClean: boolean;
  cleanProcess?: string;
  needBuy: boolean;
  buyQuantity?: number;
  buyDescription?: string;
  obs?: string;
};

export type UpdateTicketData = {
  assignedUser?: string;
  obs?: string;
};

export async function paginatedTicketsRequest(
  params: TicketPaginatedParams,
): Promise<TicketPaginatedResponse> {
  const response = await api.get<TicketPaginatedResponse>('tickets', {
    params,
  });

  return response.data;
}

export async function createTicketResquest(
  orderId: number,
  payload: CreateTicketRequestData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`tickets/${orderId}`, payload);

  return response;
}

export async function updateTicketResquest(
  id: number,
  payload: UpdateTicketData,
): Promise<Ticket> {
  const response = await api.put<Ticket>(`tickets/${id}`, payload);

  return response.data;
}

export async function getTicketResquest(
  id: number,
): Promise<AxiosResponse<Ticket>> {
  const response = await api.get<Ticket>(`tickets/${id}`);

  return response;
}

export async function updateTicketStatusRequest(
  id: number,
  data: UpdateTicketStatusRequestData,
): Promise<Ticket> {
  const response = await api.put<Ticket>(`tickets/${id}/status`, data);

  return response.data;
}

export async function finishTicketRequest(id: number): Promise<Ticket> {
  const response = await api.put<Ticket>(`tickets/${id}/finish`);

  return response.data;
}

export async function getTicketByHashCode(
  hash: string,
): Promise<AxiosResponse<Ticket>> {
  const response = await api.get<Ticket>(`tickets/rating/${hash}`);

  return response;
}

export async function rateTicketRequest(
  id: number,
  data: RateTicketData,
): Promise<AxiosResponse<void>> {
  const response = await api.post<void>(`tickets/rating/${id}`, data);

  return response;
}
