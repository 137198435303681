import {
  ActionIcon,
  Badge,
  Button,
  Card,
  Container,
  CopyButton,
  Divider,
  Flex,
  Grid,
  Rating,
  Text,
  Tooltip,
} from '@mantine/core';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  useGetByCloneOrder,
  useGetByReworkOrder,
} from '../../data/hooks/orders';
import { useGetTicket } from '../../data/hooks/tickets';
import { TicketContactType } from '../../models/ticket';
import { UserRole } from '../../models/user';
import { RootState } from '../../providers/store';
import {
  ticketContactTypeHumanized,
  ticketStatusColor,
  ticketStatusHumanized,
} from '../../utils/constants';
import { formatPhone } from '../../utils/formater';
import { booleanToStringConverter, formatDateTime } from '../../utils/helpers';
import { CustomLoader } from '../CustomLoader';
import { OrderParent } from '../OrderParent';
import { IconCheck, IconCopy } from '@tabler/icons';

interface TicketDetailsProps {
  ticketId: number;
  originOrder: number;
  reworkOriginOrder: number;
}

export function TicketDetails({
  ticketId,
  originOrder,
  reworkOriginOrder,
}: TicketDetailsProps) {
  const { user } = useSelector((state: RootState) => state.auth);
  const {
    fetch: getTicketFetcher,
    response: getTicketData,
    loading: getTicketLoader,
  } = useGetTicket();

  const {
    fetch: getByCloneFetcher,
    response: getByCloneData,
    loading: getByCloneLoader,
  } = useGetByCloneOrder();

  const {
    fetch: getByReworkFetcher,
    response: getByReworkData,
    loading: getByReworkLoader,
  } = useGetByReworkOrder();

  async function getTicket(id: number) {
    getTicketFetcher({
      ticketId: id,
    });

    if (originOrder) {
      await getByCloneFetcher({
        id: originOrder,
      });
    }

    if (reworkOriginOrder) {
      await getByReworkFetcher({
        id: reworkOriginOrder,
      });
    }
  }

  function handleCopyLink() {
    return (
      process.env.REACT_APP_HOST + '/sac/rate/' + getTicketData?.ratingCode
    );
  }

  useEffect(() => {
    getTicket(ticketId);
  }, [ticketId]);

  return (
    <Container>
      <CustomLoader loading={getTicketLoader} />
      <Flex align="center" justify="center">
        <Badge
          color={getTicketData && ticketStatusColor[getTicketData.status]}
          mb={6}
        >
          {getTicketData && ticketStatusHumanized[getTicketData.status]}
        </Badge>
      </Flex>
      <Grid m={8} gutter="xs" columns={2}>
        <Grid.Col mt={8} mb={8} span={2}>
          <Text color="gray">Info do Ticket</Text>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Código</Text>
            <Text color="gray">{getTicketData?.code}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Responsável</Text>
            <Text color="gray">
              {getTicketData?.assignedUser ? getTicketData.assignedUser : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Criado por</Text>
            <Text color="gray">{`${getTicketData?.createdBy}`}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Criado em</Text>
            <Text color="gray">
              {getTicketData?.createdAt
                ? formatDateTime(getTicketData?.createdAt)
                : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Finalizado em</Text>
            <Text color="gray">
              {getTicketData?.finishedAt
                ? formatDateTime(getTicketData?.finishedAt)
                : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex direction="column">
            <Text fw="bold">Observação</Text>
            <Text color="gray">
              {getTicketData?.obs ? getTicketData.obs : '--'}
            </Text>
          </Flex>
        </Grid.Col>

        {getTicketData?.ratingCode && (
          <>
            <Grid.Col span={2}>
              <Divider my="sm" variant="dashed" />
            </Grid.Col>
            <Grid.Col mt={8} mb={8} span={2}>
              <Flex gap={'md'}>
                <Text color="gray">Avaliação</Text>
                {user?.role === UserRole.COMPANY ? (
                  <Button
                    variant="outline"
                    onClick={() =>
                      window.open(
                        process.env.REACT_APP_HOST +
                          '/sac/rate/' +
                          getTicketData?.ratingCode,
                        '_blank',
                      )
                    }
                  >
                    Avaliar Ticket
                  </Button>
                ) : (
                  <CopyButton value={handleCopyLink() ?? '--'} timeout={2000}>
                    {({ copied, copy }) => (
                      <Tooltip
                        label={
                          copied ? `Link Copiado` : `Copiar Link de avaliação`
                        }
                        withArrow
                        position="right"
                      >
                        <ActionIcon
                          color={copied ? 'teal' : 'blue'}
                          onClick={copy}
                          disabled={!!getTicketData.ratingDate}
                        >
                          {copied ? (
                            <IconCheck size={20} />
                          ) : (
                            <IconCopy size={20} />
                          )}
                        </ActionIcon>
                      </Tooltip>
                    )}
                  </CopyButton>
                )}
              </Flex>
            </Grid.Col>

            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'xs'}>
                <Text fw="bold">Equipe SAC</Text>
                <Rating size="md" readOnly value={getTicketData.sacRating} />
              </Flex>
            </Grid.Col>

            <Grid.Col span={1}>
              <Flex direction={'column'} gap={'xs'}>
                <Text fw="bold">Equipe de Vendas</Text>
                <Rating size="md" readOnly value={getTicketData.vendorRating} />
              </Flex>
            </Grid.Col>

            <Grid.Col span={2} mt={8}>
              <Flex direction={'column'}>
                <Text fw="bold">Feedback</Text>
                <Text color="gray">{getTicketData.ratingNote}</Text>
              </Flex>
            </Grid.Col>
          </>
        )}

        <Grid.Col span={2}>
          <Divider my="sm" variant="dashed" />
        </Grid.Col>

        <Grid.Col mt={8} mb={8} span={2}>
          <Text color="gray">Info de contato</Text>
        </Grid.Col>

        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Nome contato</Text>
            <Text color="gray">
              {getTicketData?.contactName ? getTicketData.contactName : '--'}
            </Text>
          </Flex>
        </Grid.Col>

        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Tipo contato</Text>
            <Text color="gray">
              {getTicketData?.contactType
                ? ticketContactTypeHumanized[getTicketData.contactType]
                : '--'}
            </Text>
          </Flex>
        </Grid.Col>

        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Contato</Text>
            <Text color="gray">
              {[TicketContactType.PHONE, TicketContactType.WHATSAPP].includes(
                getTicketData?.contactType as TicketContactType,
              )
                ? formatPhone(getTicketData?.contact ?? '')
                : getTicketData?.contact}
            </Text>
          </Flex>
        </Grid.Col>

        <Grid.Col span={2}>
          <Divider my="sm" variant="dashed" />
        </Grid.Col>

        <Grid.Col mt={8} mb={8} span={2}>
          <Text color="gray">Processo</Text>
        </Grid.Col>

        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Compra</Text>
            <Text color="gray">
              {booleanToStringConverter(!!getTicketData?.needBuy)}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Limpeza</Text>
            <Text color="gray">
              {booleanToStringConverter(!!getTicketData?.needClean)}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Qtde. Compra</Text>
            <Text color="gray">
              {getTicketData?.buyQuantity ? getTicketData.buyQuantity : '0'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Criado por</Text>
            <Text color="gray">{getTicketData?.createdBy}</Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Criado em</Text>
            <Text color="gray">
              {getTicketData?.createdAt
                ? formatDateTime(getTicketData?.createdAt)
                : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Finalizado em</Text>
            <Text color="gray">
              {getTicketData?.finishedAt
                ? formatDateTime(getTicketData?.finishedAt)
                : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Obs</Text>
            <Text color="gray">
              {getTicketData?.obs ? getTicketData.obs : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={1}>
          <Flex direction="column">
            <Text fw="bold">Processo de limpeza</Text>
            <Text color="gray">
              {getTicketData?.cleanProcess ? getTicketData.cleanProcess : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={2}>
          <Flex direction="column">
            <Text fw="bold">Descrição de compra</Text>
            <Text color="gray">
              {getTicketData?.buyDescription
                ? getTicketData.buyDescription
                : '--'}
            </Text>
          </Flex>
        </Grid.Col>
        <Grid.Col span={2}>
          <Divider my="sm" variant="dashed" />
        </Grid.Col>
        <Grid.Col mt={8} mb={8} span={2}>
          <Text color="gray">Info do Pedido</Text>
        </Grid.Col>
        {user?.role !== UserRole.COMPANY && getByCloneData && (
          <Grid.Col>
            <OrderParent order={getByCloneData} loading={getByCloneLoader} />
          </Grid.Col>
        )}
        {user?.role !== UserRole.COMPANY && getByReworkData && (
          <Grid.Col>
            <OrderParent order={getByReworkData} loading={getByReworkLoader} />
          </Grid.Col>
        )}
        <Grid.Col>
          <Card shadow="xs" mb={8}>
            <Flex justify="space-between">
              <Flex direction="column">
                <Text fw="bold" color="gray" size={12}>
                  OS
                </Text>
                <Text>{getTicketData?.orderOs}</Text>
              </Flex>
              <Flex direction="column">
                <Text fw="bold" color="gray" size={12}>
                  Produto
                </Text>
                <Text>
                  {`${getTicketData?.orderProduct}, ${getTicketData?.orderProductColor}, ${getTicketData?.orderProductMaterial}`}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fw="bold" color="gray" size={12}>
                  Serviço
                </Text>
                <Text>{getTicketData?.orderServiceName}</Text>
              </Flex>
            </Flex>
          </Card>
        </Grid.Col>
      </Grid>
    </Container>
  );
}
