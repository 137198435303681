import {
  Badge,
  Button,
  Flex,
  Menu,
  Paper,
  Select,
  TextInput,
} from '@mantine/core';
import { statusList } from '../../../utils/constants';
import {
  orderFinancialStatusList,
  orderPaymentStatusList,
  orderPaymentTypeHumanized,
  paymentStatusColor,
  paymentStatusTypeHumanized,
} from '../../../utils/constants/order';
import { CustomDateRangePicker } from '../../../components/CustomDateRangePicker';
import { DataTable } from 'mantine-datatable';
import { formatBRL } from '../../../utils/helpers';
import { formatLocale } from '../../../providers/dayjs-plugins';
import {
  IconCertificate,
  IconCertificate2,
  IconCheck,
  IconDotsVertical,
  IconMoneybag,
  IconTrash,
} from '@tabler/icons';
import { OrderPaymentCustomResponse } from '../../../models/order-payment';
import {
  GetFilterCompaniesListResponse,
  GetFilterTeamsListResponse,
} from '../../../utils/types/data/services/filters';
import { PageMeta } from '../../../utils/types';
import { UseFormReturnType } from '@mantine/form';
import { User } from '../../../models/user';

interface FinancialPaymentPageProps {
  items: OrderPaymentCustomResponse[] | undefined;
  teamList: GetFilterTeamsListResponse[];
  companyList: GetFilterCompaniesListResponse[];
  handlePaginate(page: number): void;
  handleSubmit(): void;
  currentPage: number;
  meta?: PageMeta | null;
  formFilter: UseFormReturnType<any>;
  handleClear: () => void;
  handleOrderPayment: (paymentItem: any) => void;
  handleClearFilter: () => void;
  user: User | null;
  handleOrderConfirmPayment: (paymentItem: OrderPaymentCustomResponse) => void;
  handleSetAsPaid: (id: number) => void;
  showNFSeDetails: (paymentItem: OrderPaymentCustomResponse) => void;
  showNFSeModal: (paymentItem: OrderPaymentCustomResponse) => void;
  confirmPaymentToCancel: (paymentItem: OrderPaymentCustomResponse) => void;
}

export function FinancialPaymentPage({
  items,
  teamList,
  companyList,
  handlePaginate,
  handleSubmit,
  currentPage,
  meta,
  formFilter,
  handleOrderPayment,
  handleClearFilter,
  user,
  handleOrderConfirmPayment,
  handleSetAsPaid,
  showNFSeDetails,
  showNFSeModal,
  confirmPaymentToCancel,
}: FinancialPaymentPageProps) {
  return (
    <Flex direction="column" w="99%">
      <form onSubmit={formFilter.onSubmit(() => handleSubmit())}>
        <Paper p={16} mb={16} mt={16} withBorder>
          <Flex wrap="wrap">
            <TextInput
              label="OS"
              placeholder="numero da OS"
              mb={16}
              mr={8}
              type="text"
              name="os"
              {...formFilter.getInputProps('os')}
            />
            <Select
              clearable
              searchable
              name="team"
              label="Equipe"
              placeholder="selecione a equipe"
              data={teamList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('team')}
            />
            <Select
              clearable
              searchable
              name="company"
              label="Clientes"
              placeholder="selecione o cliente"
              data={companyList?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('company')}
            />
            <Select
              name="status"
              label="Status"
              placeholder="selecione o status do pedido"
              data={statusList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('status')}
            />
            <Select
              clearable
              name="paymentStatus"
              label="Status de Pagamento"
              placeholder="selecione o status de pagamento"
              data={orderPaymentStatusList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('paymentStatus')}
            />
            <TextInput
              label="Obs de Pagamento"
              placeholder="digite algo"
              mb={16}
              mr={8}
              type="text"
              {...formFilter.getInputProps('paymentNote')}
            />
            <Select
              clearable
              name="financialStatus"
              label="Status Fiscal"
              placeholder="selecione o status de fiscal"
              data={orderFinancialStatusList}
              mb={16}
              mr={8}
              {...formFilter.getInputProps('financialStatus')}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Prazo de Pagamento"
              placeholder="selecione um intervalo"
              mr={8}
              {...formFilter.getInputProps('paymentDeadline')}
            />
            <CustomDateRangePicker
              allowSingleDateInRange
              label="Pago em"
              placeholder="selecione um intervalo"
              mr={8}
              {...formFilter.getInputProps('paidAt')}
            />
            <TextInput
              label="Nº Boleto"
              placeholder="numero de Boleto"
              mb={16}
              mr={8}
              type="text"
              {...formFilter.getInputProps('billetNumber')}
            />
          </Flex>
          <Button
            mt={25}
            color="ltpBlue.9"
            type="button"
            variant="outline"
            onClick={handleClearFilter}
          >
            Limpar
          </Button>
          <Button mt={25} ml={16} color="ltpBlue.9" type="submit">
            Filtrar
          </Button>
        </Paper>
      </form>
      <DataTable
        minHeight={500}
        noRecordsText="Sem pedidos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage}
        totalRecords={meta?.totalItems}
        recordsPerPage={10}
        onPageChange={handlePaginate}
        records={items}
        columns={[
          {
            accessor: 'company_name',
            title: 'Cliente',
          },
          {
            accessor: 'team_name',
            title: 'Equipe',
          },
          {
            accessor: 'nfNumber',
            title: 'NF',
            render: ({ nfNumber, serialNumber }) => nfNumber ?? serialNumber,
          },
          {
            accessor: 'paymentStatus',
            title: 'Status de Pagamento',
            render: ({ paymentStatus }) =>
              paymentStatus && (
                <Badge color={paymentStatusColor[paymentStatus]} mb={6}>
                  {paymentStatusTypeHumanized[paymentStatus]}
                </Badge>
              ),
            width: 110,
          },
          {
            accessor: 'totalprice',
            title: 'Preço total',
            render: ({ totalPrice }) => `${formatBRL(totalPrice.toFixed(2))}`,
            width: 110,
          },

          {
            accessor: 'taxValue',
            title: 'Taxa',
            render: ({ taxValue }) => `${formatBRL(taxValue.toFixed(2))}`,
            width: 110,
          },
          {
            accessor: 'totalPriceTax',
            title: 'Preço total + Taxa',
            render: ({ totalPriceTax }) =>
              `${formatBRL(totalPriceTax.toFixed(2))}`,
            width: 150,
          },
          {
            accessor: 'paymentValue',
            title: 'Valor pago',
            render: ({ paymentValue }) =>
              `${formatBRL(paymentValue.toFixed(2))}`,
            width: 110,
          },
          {
            accessor: 'payment_type',
            title: 'Forma de Pagamento',
            render: ({ payment_type }) =>
              payment_type && orderPaymentTypeHumanized[payment_type],
            width: 110,
          },
          {
            accessor: 'issueDate',
            title: 'Data de Emissão',
            render: ({ issueDate }) =>
              issueDate && formatLocale(issueDate, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'paymentDeadline',
            title: 'Prazo Pagamento',
            render: ({ paymentDeadline }) =>
              paymentDeadline && formatLocale(paymentDeadline, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'paidAt',
            title: 'Pago em',
            render: ({ paidAt }) => paidAt && formatLocale(paidAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'billetNumber',
            title: 'Numero do Boleto',
          },
          {
            accessor: 'paymentNote',
            title: 'Obs de Pagamento',
            width: 200,
          },
          {
            accessor: 'financial-menu',
            title: '',
            render: (paymentItem) => (
              <Menu>
                <Menu.Target>
                  <Button color="blue" variant="subtle" w={40} p={0}>
                    <IconDotsVertical />
                  </Button>
                </Menu.Target>
                <Menu.Dropdown style={{ position: 'absolute' }}>
                  <Menu.Item
                    disabled={user?.isMain}
                    onClick={() => handleOrderPayment(paymentItem)}
                    icon={<IconMoneybag size={14} />}
                  >
                    Pagamento
                  </Menu.Item>
                  <Menu.Item
                    disabled={!user?.isMain}
                    onClick={() => handleOrderConfirmPayment(paymentItem)}
                    icon={<IconMoneybag size={14} />}
                  >
                    Conf. Pagamento
                  </Menu.Item>
                  <Menu.Item
                    disabled={!user?.isMain}
                    onClick={() => handleSetAsPaid(paymentItem.id)}
                    icon={<IconCheck size={14} />}
                  >
                    Setar como pago
                  </Menu.Item>
                  <Menu.Item
                    disabled={!paymentItem.nfReference}
                    onClick={() => showNFSeDetails(paymentItem)}
                    icon={<IconCertificate2 size={14} />}
                  >
                    Detalhes Fiscais
                  </Menu.Item>
                  <Menu.Item
                    disabled={!!paymentItem.nfReference}
                    onClick={() => showNFSeModal(paymentItem)}
                    icon={<IconCertificate size={14} />}
                  >
                    Emitir NFSe
                  </Menu.Item>
                  <Menu.Item
                    disabled={!user?.isMain}
                    onClick={() => confirmPaymentToCancel(paymentItem)}
                    icon={<IconTrash size={14} />}
                  >
                    Cancelar Faturamento
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ),
          },
        ]}
      />
    </Flex>
  );
}
