import { Badge, Group, Text } from '@mantine/core';
import { DataTable } from 'mantine-datatable';
import React from 'react';
import { OrderPaymentCustomResponse } from '../../../models/order-payment';
import { formatLocale } from '../../../providers/dayjs-plugins';
import {
  orderPaymentTypeHumanized,
  paymentStatusColor,
  paymentStatusTypeHumanized,
} from '../../../utils/constants/order';
import { formatBRL } from '../../../utils/helpers';
import { PaginatedResponse } from '../../../utils/types';

interface FinancialPaymentReportListProps {
  paymentList: PaginatedResponse<OrderPaymentCustomResponse> | undefined;
  handlePaginate(page: number): void;
  currentPage: React.MutableRefObject<number>;
  currentPageLimit: React.MutableRefObject<number>;
  handleChangePageLimit: (recordRange: number) => void;
}

export function FinancialPaymentReportList({
  currentPage,
  handlePaginate,
  paymentList,
  currentPageLimit,
  handleChangePageLimit,
}: FinancialPaymentReportListProps) {
  return (
    <>
      <DataTable
        recordsPerPageLabel="Itens por página"
        recordsPerPageOptions={[10, 50, 100, 500]}
        onRecordsPerPageChange={(recordRange) =>
          handleChangePageLimit(recordRange)
        }
        height={440}
        noRecordsText="Sem pagamentos"
        withBorder
        borderRadius="sm"
        striped
        highlightOnHover
        page={currentPage.current}
        totalRecords={paymentList?.meta?.totalItems}
        recordsPerPage={currentPageLimit.current}
        onPageChange={handlePaginate}
        records={paymentList?.items}
        columns={[
          {
            accessor: 'company_name',
            title: 'Cliente',
          },
          {
            accessor: 'team_name',
            title: 'Equipe',
          },
          {
            accessor: 'nfNumber',
            title: 'NF',
          },
          {
            accessor: 'paymentStatus',
            title: 'Status de Pagamento',
            render: ({ paymentStatus }) =>
              paymentStatus && (
                <Badge color={paymentStatusColor[paymentStatus]} mb={6}>
                  {paymentStatusTypeHumanized[paymentStatus]}
                </Badge>
              ),
            width: 110,
          },
          {
            accessor: 'totalprice',
            title: 'Preço total',
            render: ({ totalPrice }) => `${formatBRL(totalPrice.toFixed(2))}`,
            width: 110,
          },
          {
            accessor: 'paymentValue',
            title: 'Valor pago',
            render: ({ paymentValue }) =>
              `${formatBRL(paymentValue.toFixed(2))}`,
            width: 110,
          },
          {
            accessor: 'taxValue',
            title: 'Taxa',
            render: ({ taxValue }) => `${formatBRL(taxValue.toFixed(2))}`,
            width: 110,
          },
          {
            accessor: 'payment_type',
            title: 'Forma de Pagamento',
            render: ({ payment_type }) =>
              payment_type && orderPaymentTypeHumanized[payment_type],
            width: 110,
          },
          {
            accessor: 'issueDate',
            title: 'Data de Emissão',
            render: ({ issueDate }) =>
              issueDate && formatLocale(issueDate, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'paymentDeadline',
            title: 'Prazo Pagamento',
            render: ({ paymentDeadline }) =>
              paymentDeadline && formatLocale(paymentDeadline, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'paidAt',
            title: 'Pago em',
            render: ({ paidAt }) => paidAt && formatLocale(paidAt, 'DD/MM/YY'),
            width: 110,
          },
          {
            accessor: 'billetNumber',
            title: 'Numero do Boleto',
          },
          {
            accessor: 'paymentNote',
            title: 'Obs de Pagamento',
            width: 200,
          },
        ]}
      />
      <Group position="right">
        <Text>
          preço total:{' '}
          {formatBRL(Number(paymentList?.meta?.custom?.total).toFixed(2))}
        </Text>
        <Text>
          valor pago:{' '}
          {formatBRL(Number(paymentList?.meta?.custom?.totalPaid).toFixed(2))}
        </Text>
      </Group>
    </>
  );
}
