import { AxiosResponse } from 'axios';
import {
  AddTeamOptionPercent,
  AddTeamServicePercent,
  Team,
  TeamOptionPercent,
  TeamServicePercent,
} from '../../models/team';
import { api } from '../../providers/base-api';
import { CreateTeamData } from '../../utils/types/data/services/teams';

export async function getTeamsRequest(): Promise<Team[]> {
  const response = await api.get<Team[]>(`teams`);

  return response.data;
}

export async function showTeamRequest(
  id: number,
): Promise<AxiosResponse<Team>> {
  const response = await api.get<Team>(`teams/${id}`);

  return response;
}

export async function createTeamsRequest(
  data: CreateTeamData,
): Promise<AxiosResponse<Team>> {
  const response = await api.post<Team>(`teams`, data);

  return response;
}

export async function updateTeamsRequest(
  id: number,
  data: CreateTeamData,
): Promise<AxiosResponse<Team>> {
  const response = await api.put<Team>(`teams/${id}`, data);

  return response;
}

export async function getTeamServicePercents(params: {
  team?: number;
  service?: number;
}): Promise<AxiosResponse<TeamServicePercent[]>> {
  const response = await api.get(`team-service-percents`, {
    params,
  });

  return response;
}

export async function addTeamServicePercentage(
  id: number,
  percents: AddTeamServicePercent[],
): Promise<AxiosResponse<void>> {
  const response = await api.post(`team-service-percents/${id}`, {
    list: percents,
  });

  return response;
}

export async function getTeamOptionPercents(params: {
  team?: number;
}): Promise<AxiosResponse<TeamOptionPercent[]>> {
  const response = await api.get(`team-option-percents`, {
    params,
  });

  return response;
}

export async function addTeamOptionPercentage(
  id: number,
  percents: AddTeamOptionPercent[],
): Promise<AxiosResponse<void>> {
  const response = await api.post(`team-option-percents/${id}`, {
    list: percents,
  });

  return response;
}
