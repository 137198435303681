import { Flex, Tabs } from '@mantine/core';
import { useState } from 'react';

import { IconAssembly, IconBox } from '@tabler/icons';
import { Page } from '../../components/Page';
import { PackingReportList } from './sub-components/packing-report-list';
import { ProductionReportList } from './sub-components/production-report-list';

export function ProductionReport() {
  const [, setActiveTab] = useState<string | null>('production');

  return (
    <Page title="Relatório de Produção">
      <Flex direction="column" w="99%">
        <Tabs
          defaultValue="production"
          variant="outline"
          radius="md"
          onTabChange={setActiveTab}
        >
          <Tabs.List>
            <Tabs.Tab value="production" icon={<IconAssembly size={14} />}>
              Produção
            </Tabs.Tab>
            <Tabs.Tab value="pack/unpack" icon={<IconBox size={14} />}>
              Embalagem/Desembalagem
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="production">
            <ProductionReportList />
          </Tabs.Panel>
          <Tabs.Panel value="pack/unpack">
            <PackingReportList />
          </Tabs.Panel>
        </Tabs>
      </Flex>
    </Page>
  );
}
